import React from 'react';
import {Skeleton} from "@mui/material";

export const TableLoader = () => {
    return (
        <>
            <div className="border-top my-3 py-3">
                <div className="d-flex w-100 justify-content-start mb-2">
                    {Array.from({length: 5}).map((_, index) => {
                        return (
                            <Skeleton key={index} className={"me-2"} variant="text" width={"20%"} height={15} />
                        )
                    })}
                </div>

                {Array.from({length: 6}).map((_, index) => {
                    return <Skeleton key={index} className={"rounded-2 mb-1"} variant="rectangular" width={"100%"} height={30} />
                })}
            </div>

            <div className="d-flex w-100 mb-3 align-items-center justify-content-between">
                <Skeleton variant="text" width={"20%"} height={15} />
                <Skeleton className={"rounded-2"} variant="rectangular" width={80} height={30} />
            </div>
        </>
    );
}