import {PageHeader} from "../../components/shared/PageHeader";
import {ComplianceSchema} from "./schemas/ComplianceSchema";
import {Field, Formik} from "formik";
import {XTextField} from "../../components/shared/forms/XTextField";
import React, {useState} from "react";
import {buildUrl} from "../../../utils/queryBuilder";
import {remoteRoutes} from "../../../data/constants";
import {post} from "../../../utils/ajax";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../data/toastDefaults";
import CodeEditor from '@uiw/react-textarea-code-editor'

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {okaidia} from 'react-syntax-highlighter/dist/esm/styles/prism';

export const CheckCompliance = () => {

    const [json, setJson] = useState<any>(undefined)
    const [submitting, setSubmitting] = useState<boolean>(false)


    const handleSubmit = () => {

        setSubmitting(true)
        const url = buildUrl(remoteRoutes.complianceEngine, '/compliance')
        const data: any = JSON.parse(json)

        post(url, data, (response) => {
            toast.success("Your batch has been submitted for compliance verification.", {
                ...overrideToastDefaults, style: {
                    borderRadius: '50px',
                    padding: '14px 20px',
                    width: '600px'
                }
            })
            setJson(undefined)

        }, (error) => {
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {
            setSubmitting(false)
        })
    }

    const formatObject = (event: any) => {
        const json = event.target.value

        const formatted = JSON.stringify(json, null, 4)
        setJson(JSON.parse(formatted))
    }

    return (
        <>
            <h4 className="mt-2 mb-4">Verify Batch Compliance</h4>
            <div className="row">
                <div className="col-lg-12">

                    <textarea
                        onChange={(event: any) => setJson(event.target.value)}
                        value={json}
                        className="form-control"
                        style={{
                            minHeight: 700,
                            maxHeight: 700
                        }}>
                    </textarea>

                    <div className="mt-3 d-flex justify-content-end">

                        {submitting && (
                            <button className='btn btn-dark' disabled>
                                <span className=''>Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            </button>
                        )}

                        {!submitting && (
                            <button
                                onClick={handleSubmit}
                                className={`btn ${json === undefined ? 'btn-gray disabled' : 'btn-primary'}`}>
                                Submit
                            </button>
                        )}
                    </div>

                </div>
                {/*<div className="col-lg-6">*/}
                {/*    <SyntaxHighlighter showLineNumbers={true} language="json" style={okaidia}>*/}
                {/*        {json}*/}
                {/*    </SyntaxHighlighter>*/}
                {/*</div>*/}

            </div>

        </>
    );
}