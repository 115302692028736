import React, { useEffect, useState } from "react";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import TableWrapper from "../../components/shared/TableWrapper";
import XPagination from "../../components/shared/XPagination";
import { IButtonProps } from "../../components/shared/PageHeader";
import { DownloadBatchReport } from "./modals/DownloadBatchReport";
import useFetchBatches from "../../hooks/useFetchBatches";
import { useAuth } from "../auth";
import { useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { printNaturalDateShort } from "../../../utils/dateHelpers";

export const columns: ITableColumn[] = [
    {
        label: "Date of Purchase",
        id: "date",
    },
    { label: "Product", id: "productName" },
    { label: "Variety", id: "varietyName" },
    { label: "Quantity (kg)", id: "quantity", textAlign: 'text-end', isNumberFormat: true, },
    { label: "Unit Price (UGX)", id: "unitPrice", textAlign: 'text-end', isNumberFormat: true, },
    { label: "Total Amount (UGX)", id: "totalPrice", textAlign: 'text-end', isNumberFormat: true, },
    { label: "Plot Code", id: "plotCode" },
    { label: "Deforestation Status", id: "status", textAlign: 'text-center', isNumberFormat: true, },
]

const SearchBatch = () => {
    const { currentUser } = useAuth()

    const { data, loading }: any = useSelector((state: IState) => state.batches)

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [showTable, setShowTable] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [showActionButtons, setShowActionButtons] = useState<boolean>(false)
    const [selected, setSelected] = useState<any[]>([])
    const { fetchBatches, pagination } = useFetchBatches()

    const handleSearchEnter = (event: any) => {
        if (event.keyCode === 13) {
            if (searchTerm && searchTerm.trim() !== '') {
                fetchBatches(pagination.currentPage, pagination.pageSize, { hubId: currentUser?.hubId, searchTerm });
                setShowTable(true);
            }
        }
    };

    const handleSearchButtonClick = () => {
        if (searchTerm && searchTerm.trim() !== '') {
            fetchBatches(pagination.currentPage, pagination.pageSize, { hubId: currentUser?.hubId, searchTerm });
            setShowTable(true);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.trim() === '') {
        } else {
            setSearchTerm(value);
        }
    };


    useEffect(() => {
        if (searchTerm !== undefined) {
            fetchBatches(pagination.currentPage, pagination.pageSize, { hubId: currentUser?.hubId, searchTerm });
        }
    }, [pagination.currentPage, searchTerm]);

    const actionButtons: IButtonProps[] = [
        {
            label: `Download`,
            cssClass: "btn btn-primary ms-2",
            dataTarget: "#download-batch-report",
            dataToggle: "modal"
        }
    ]

    const handleRowClick = (row: any) => {
        setSelected((prevSelected) => {
            const newSelected = [...prevSelected];
            const index = newSelected.findIndex((selectedRow) => selectedRow.id === row.id);
            if (index > -1) {
                newSelected.splice(index, 1);
            } else {
                newSelected.push(row);
            }
            return newSelected;
        });
    };

    const handleSelectAll = (rows: any[]) => {
        setSelected((prevSelected) => {
            if (prevSelected.length === rows.length) {
                return [];
            } else {
                return rows;
            }
        });
    };

    useEffect(() => {
        setShowActionButtons(selected && selected.length > 0)
    }, [selected])

    const formattedDate = data.map((row: { date: string }) => ({
        ...row,
        date: printNaturalDateShort(row.date)
    }))

    return (
        <div className={`search-page ${showTable ? "top-aligned" : "center-aligned"}`}>
            <div className="mb-2 align-items-center">
                <h6>{!showTable ? <center>Search for a Batch</center> : <>Search for a Batch</>}</h6>
                <div className="input-group search-bar">
                    <div className="input-group-text bg-white border-end-0" id="btnGroupAddon">
                        <FontAwesomeIcon icon={faSearch} color="#ABA5A5" />
                    </div>
                    <input
                        type="text"
                        className="form-control border-start-0"
                        placeholder="Batch ID"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyUp={handleSearchEnter}
                    />
                    <button
                        className="input-group-text bg-orange"
                        id="btnGroupAddon"
                        onClick={handleSearchButtonClick}
                    >Search</button>
                </div>
            </div>

            {showTable && (
                <>
                    <TableWrapper>
                        <div className="d-flex w-100 align-items-center justify-content-between">
                            <div>
                                <b>Purchases for Batch {searchTerm}</b>
                            </div>
                            <div className="action-buttons">
                                {showActionButtons &&
                                    actionButtons.map((button, index) => {
                                        return (<button
                                            data-bs-toggle={button.dataToggle}
                                            data-bs-target={button.dataTarget}
                                            className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                            key={index}
                                            onClick={button.onClick}>
                                            {button.processing ? 'Please wait...' : button.label}
                                        </button>)
                                    })
                                }
                            </div>
                        </div>
                        <XTable
                            selected={selected.map(row => row.id)}
                            onSelectAll={(rows) => handleSelectAll(rows)}
                            onRowClick={(row) => handleRowClick(row)}
                            onCheckRow={(row) => handleRowClick(row)}
                            data={formattedDate}
                            columns={columns}
                            loading={loading}
                        />
                        <XPagination
                            currentPage={page}
                            pagination={pagination}
                            dataLength={data.length}
                            setPage={(page) => setPage(page)} />
                    </TableWrapper>
                    <DownloadBatchReport purchases={selected} />
                </>
            )}
        </div>
    );
};

export default SearchBatch;
