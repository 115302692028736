import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import ConfirmInputDeliveryForm from "./modals/ConfirmInputDeliveryForm";
import EditInputsMovementForm from "./modals/EditInputsMovementForm";
import DeleteInputMovementForm from "./modals/DeleteInputMovementForm";
import { printMovementStatus } from "../../../utils/enumHelpers";
import {useSelector} from "react-redux";
import {IState} from "../../../data/types";
import {useEffect, useState} from "react";
import {IInputMovement} from "./interfaces/IInputMovement";
import {IInputDistribution} from "./interfaces/IInputDistribution";
import {printNaturalDateShort} from "../../../utils/dateHelpers";

type Props = {
  close: () => void;
  show: boolean;
  selectedRow: any;
  selectedInputRowId: string | null;
};

const InputMovementDetails = ({close, show, selectedRow, selectedInputRowId,}: Props) => {
  const inputDetails: IInputMovement | undefined = useSelector((state: IState) =>
      state.inputs.data.find((input) => input.id === selectedInputRowId)
  )

  const [localInput, setLocalInput] = useState(inputDetails);

  useEffect(() => {
    setLocalInput(inputDetails);
  }, [inputDetails]);

  const actionButtons: IHeaderButtonProps[] = [
    {
      label: "Delete",
      cssClass: "btn btn-outline-secondary",
      dataTarget: "#delete-input-movement",
      dataToggle: "modal",
    },
    {
      label: "Edit",
      cssClass: "btn btn-primary",
      dataTarget: "#edit-input-movement",
      dataToggle: "modal",
    },
  ];

  const status = printMovementStatus(selectedRow?.movementStatus);
  return (
    <>
      <XSidebarWrapper close={close} show={show} width={35}>
        <div className="d-flex justify-content-start w-100 align-items-center">
          <div className="py-3 px-3 rounded-1 bg-primary">
            <h2>
              {localInput?.inputName &&
                  localInput?.inputName[0].toUpperCase()}
            </h2>
          </div>
          <div className="px-3">
            <h5>{localInput?.inputName}</h5>
            <div className="small">
              {localInput?.quantity?.toLocaleString() +
                " " +
                  localInput?.unitName}
            </div>
            <div className="fs-6">{status}</div>
          </div>
          <div className={"w-75 text-end"}>
            {localInput?.movementStatus === 1 && (
              <>
                <button
                  className={`btn btn-outline-dark`}
                  data-bs-toggle={"modal"}
                  data-bs-target={"#confirm-input-delivery"}
                >
                  Confirm Delivery
                </button>
              </>
            )}
          </div>
        </div>
        <hr />
        <div className="p-3 bg-gray rounded-3 mb-3">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">Movement Number</strong>
              <br />
              <span>{localInput?.movementNumber || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">Date</strong>
              <br />
              <span>{localInput?.formattedDate}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">Source/Origin</strong>
              <br />
              <span>{localInput?.sourceCollectionCentreName || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">Destination</strong>
              <br />
              <span>{localInput?.destinationCollectionCentreName || "-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">Agent</strong>
              <br />
              <span>{localInput?.sourceAgentName || "-"}</span>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="d-flex justify-content-end gap-3 w-50">
            {actionButtons.map((button, index) => {
              return (
                <button
                  key={index}
                  className={button.cssClass}
                  data-bs-toggle={button.dataToggle}
                  data-bs-target={button.dataTarget}
                  onClick={() => button.onClick}
                >
                  {button.label}
                </button>
              );
            })}
          </div>
        </div>
      </XSidebarWrapper>
      <ConfirmInputDeliveryForm selectedRow={selectedRow} />
      <DeleteInputMovementForm item={selectedRow} />
      <EditInputsMovementForm
        selectedRow={selectedRow}
        selectedInputDetailsId={selectedInputRowId}
      />
    </>
  );
};

export default InputMovementDetails;
