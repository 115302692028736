import React from 'react';
import {Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton} from "@mui/material";

export const MainMenuLoader = () => {
    return (
        <>
            <List className="mt-3" disablePadding>
                {Array.from({length: 5}).map((_, index) => {
                    return (
                        <ListItem key={index} disablePadding>
                            <ListItemText className="ms-3 me-3">
                                <Skeleton variant="text" width={"100%"} height={45}/>
                            </ListItemText>
                        </ListItem>
                    )
                })}
            </List>

            <Divider className="mt-auto"/>
            <List>
                <ListItem disablePadding>
                    <ListItemText className="ms-3 me-3">
                        <Skeleton variant="text" width={"100%"} height={45}/>
                    </ListItemText>
                </ListItem>
            </List>
        </>

    );
}