import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {AuthProvider, setupAxios} from "./app/modules/auth";
import axios from "axios";

import {SymosI18nProvider} from "./_theme/i18n/Symosi18n";
import {Provider} from "react-redux";
import {store} from "./data/redux/store";
import WebSocketProvider from './data/WebSockets/WebSocket'
import {Toaster} from "react-hot-toast";
import {AppRoutes} from "./app/routing/AppRoutes";

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'

import './_theme/css/symos.css'
import IdleTimerWrapper from "./app/components/shared/IdleTimerWrapper";

setupAxios(axios)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <SymosI18nProvider>
            <AuthProvider>
                <WebSocketProvider>
                    <AppRoutes/>
                    <Toaster/>
                    <IdleTimerWrapper/>
                </WebSocketProvider>
            </AuthProvider>
        </SymosI18nProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
