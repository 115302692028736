import {Formik} from "formik";
import toast from "react-hot-toast";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {del} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {useNavigate} from "react-router-dom";
import {ISystemProduct} from "../../products/interfaces/ISystemProduct";

interface Props {
    item: ISystemProduct
}

const DeleteSystemProduct = ({item}: Props) => {
    const navigate = useNavigate();
    const handleSubmit = () => {

        const url = buildUrl(remoteRoutes.onboardingService, `/products/${item.id}`)

        del(url, (response) => {

            CloseModal("delete-product")
            navigate('/settings')
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {

            console.log(error)

            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={item}
            enableReinitialize={true}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="delete-system-product"
                    title="Delete Product"
                    size="md"
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <p>Are you sure you want to delete this product? This action cannot be undone.</p>
                </ModalWrapper>
            }}

        </Formik>
    );
}

export default DeleteSystemProduct;