import { Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";

type Props = {
  selectedRoleDelete: any;
};

const DeleteRoleForm = ({ selectedRoleDelete }: Props) => {
  const handleSubmit = () => {};
  return (
    <Formik initialValues={selectedRoleDelete} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting }) => {
        return (
          <ModalWrapper
            id={"delete-role"}
            title={"Delete"}
            size="md"
            isDeleteAction={true}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <p>
              Are you sure you want to delete this role? This action cannot be
              undone.
            </p>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default DeleteRoleForm;
