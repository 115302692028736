import {Outlet, Route, Routes} from "react-router-dom";
import {CheckCompliance} from "./CheckCompliance";
import {FrontLayout} from "../../../_theme/layout/FrontLayout";
import {ComplianceReport} from "./ComplianceReport";

const CompliancePage = () => {
    return (
        <Routes>
            <Route element={<FrontLayout/>}>
                <Route index element={<CheckCompliance/>}/>
                <Route path='check' element={<CheckCompliance/>}/>
                <Route path='report/:batchId' element={<ComplianceReport/>}/>
            </Route>
        </Routes>
    )
}

export default CompliancePage