import { faList, faMapPin } from "@fortawesome/free-solid-svg-icons";
import {IMenuItem} from "../../../utils/MainMenu";

interface MainMenuProps {
    activePage: string;
}

export const ProfilesInnerMenu = ({activePage}: MainMenuProps): IMenuItem[] => [
    {
        label: "List View",
        path: '/farmers',
        isActive: activePage.toLowerCase() === '/farmers',
        icon: faList
    },
    {
        label: "Map View",
        path: '/farmers/map',
        isActive: activePage.toLowerCase() === '/farmers/map',
        icon: faMapPin
    }
]