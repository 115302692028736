import {PageHeader} from "../../components/shared/PageHeader";
import {ComplianceSchema} from "./schemas/ComplianceSchema";
import {Field, Formik} from "formik";
import {XTextField} from "../../components/shared/forms/XTextField";
import React, {useEffect, useState} from "react";
import {buildUrl} from "../../../utils/queryBuilder";
import {remoteRoutes} from "../../../data/constants";
import {get, post} from "../../../utils/ajax";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../data/toastDefaults";
import CodeEditor from '@uiw/react-textarea-code-editor'

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {okaidia} from 'react-syntax-highlighter/dist/esm/styles/prism';
import {IApiResponse} from "../../interfaces/IApiResponse";
import {FARMER_PROFILES_CONSTANTS} from "../../../data/redux/farmers/profilesReducer";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {ICompliance} from "./interfaces/ICompliance";
import {COMPLIANCE_CONSTANTS, IComplianceReportState} from "../../../data/redux/compliance/complianceReducer";
import {IState} from "../../../data/types";
import Map from "../../components/shared/Map";
import {printDateShort} from "../../../utils/dateHelpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faCheckCircle,
    faChevronCircleLeft, faChevronCircleRight,
    faChevronLeft,
    faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import {SecurityCodeModal} from "./modals/SecurityCodeModal";
import {CloseModal} from "../../../_theme/helpers/components/modalHelpers";
import moment from "moment";

export const ComplianceReport = () => {

    const dispatch = useDispatch()

    const urlParams = useParams()
    const batchId = urlParams.batchId

    const [complianceData, setComplianceData] = useState<ICompliance | undefined>(undefined)
    const [polygons, setPolygons] = useState<any>([])
    const [compliantPlots, setCompliantPlots] = useState<number | undefined>(0)
    const [nonCompliantPlots, setNonCompliantPlots] = useState<number | undefined>(0)

    const [showError, setShowError] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)

    const handleSubmit = (values: any) => {

        dispatch({
            type: COMPLIANCE_CONSTANTS.START_FETCH,
            payload: true
        })

        if (remoteRoutes.complianceEngine) {

            const {securityCode} = values
            let params: any = {id: batchId, securityCode}
            const url = buildUrl(remoteRoutes.complianceEngine, '/compliance', params)

            get(url,
                (response) => {

                    if (response.length === 0) {
                        setShowError(true)
                    } else {
                        toast.success(toastMessages.default.success, overrideToastDefaults);

                        const complianceData = response[0] as ICompliance

                        setComplianceData(complianceData)

                        const polygonArray = complianceData?.batch.plots?.map(plot =>
                            plot.coordinates?.map((coord: any) => ({
                                lat: coord.latitude,
                                lng: coord.longitude
                            }))
                        );

                        setPolygons(polygonArray)

                        setCompliantPlots(complianceData?.batch?.plots?.filter(f => f.deforestationStatus === 'false').length)
                        setNonCompliantPlots(complianceData?.batch?.plots?.filter(f => f.deforestationStatus === 'true').length)

                        setShowReport(true)
                        CloseModal('security-code')

                    }
                },
                (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults)
                    setShowError(true)
                },
                () => dispatch({type: COMPLIANCE_CONSTANTS.STOP_FETCH}))
        }
    }

    useEffect(() => {
        setLoading(false)
    })

    return (
        <>
            {loading &&
                <div className="d-flex w-100 vh-100 align-items-center justify-content-center text-center">
                        <span
                            className='spinner-border spinner-border-sm align-middle me-3'></span>
                    Please wait...
                </div>}

            {!loading && <SecurityCodeModal showError={showError} handleSubmit={handleSubmit}/>}

            {showReport && <>
                <div style={{margin: '0 -15px'}} className="bg-primary p-2 ps-3">
                    <img style={{width: 50, height: 'auto'}} src="/symos_logo_transparent.png"/>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-2">
                    <div className="">
                        <strong>EUDR Compliance Report</strong>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                        <div className="d-inline-flex bg-primary rounded-5 text-white py-2 px-4">
                            {nonCompliantPlots === 0 ? 'Compliant' : "Non Compliant"}
                        </div>
                    </div>
                </div>

                <div className="card bg-primary border-0 shadow-sm my-3">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <strong>Details</strong>
                            <div className="d-inline-flex bg-orange rounded-5 text-white py-2 px-3">
                                Batch #: {complianceData?.batch.batchNumber}
                            </div>
                        </div>

                        <hr style={{margin: '15px -15px'}}/>

                        <div className="d-flex justify-content-between align-items-center">
                            {page === 1 && <span className="fw-bolder">Product details</span>}
                            {page === 2 && <span>Operator details</span>}
                            {page === 3 && <span>Buyer details</span>}
                            <div className="d-inline-flex">
                                {page > 1 &&
                                    <FontAwesomeIcon
                                        onClick={() => setPage(page - 1)}
                                        size="2x"
                                        icon={faChevronCircleLeft}/>}

                                {page < 3 &&
                                    <FontAwesomeIcon
                                        onClick={() => setPage(page + 1)}
                                        size="2x"
                                        icon={faChevronCircleRight}
                                        className="ms-2"/>}
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            {page === 1 && <div className="row mt-3">
                                <div className="col-6 mb-3">
                                    <strong>Name</strong><br/>
                                    {complianceData?.batch.product}
                                </div>
                                <div className="col-6 mb-3">
                                    <strong>Quantity</strong><br/>
                                    {complianceData?.batch.quantity}
                                </div>
                                <div className="col-12 mb-3">
                                    <strong>Brand</strong><br/>
                                    {complianceData?.batch.brand}
                                </div>
                                <div className="col-12 mb-3">
                                    <strong>Scientific Name</strong><br/>
                                    {complianceData?.batch.scientificName}
                                </div>
                            </div>}

                            {page === 2 && <div className="row mt-3">
                                <div className="col-12 mb-3">
                                    <strong>Name</strong><br/>
                                    {complianceData?.operatorDetails?.name}
                                </div>
                                <div className="col-12 mb-3">
                                    <strong>Email</strong><br/>
                                    {complianceData?.operatorDetails?.email}
                                </div>
                                <div className="col-12 mb-3">
                                    <strong>EORI Number</strong><br/>
                                    {complianceData?.operatorDetails?.eoriNumber}
                                </div>
                                <div className="col-12 mb-3">
                                    <strong>Address</strong><br/>
                                    {complianceData?.operatorDetails?.address}
                                </div>
                            </div>}

                            {page === 3 && <div className="row mt-3">
                                <div className="col-12 mb-3">
                                    <strong>Name</strong><br/>
                                    {complianceData?.buyerDetails?.name}
                                </div>
                                <div className="col-12 mb-3">
                                    <strong>Email</strong><br/>
                                    {complianceData?.buyerDetails?.email}
                                </div>
                                <div className="col-12 mb-3">
                                    <strong>EORI Number</strong><br/>
                                    {complianceData?.buyerDetails?.eoriNumber}
                                </div>
                                <div className="col-12 mb-3">
                                    <strong>Address</strong><br/>
                                    {complianceData?.buyerDetails?.address}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>

                <Map width={'100%'} height={'75vh'} zoom={8} polygons={polygons}/>

                <h6 className="mt-5">BATCH PLOTS</h6>
                {complianceData?.batch?.plots?.map((plot, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                border: 'solid 1px #F1F1F1',
                                borderLeft: 6,
                                borderLeftStyle: "solid",
                                borderLeftColor: plot.deforestationStatus === 'true' ? 'red' : '#1C5838'
                            }} className="mb-3 p-2 bg-white rounded-3 d-flex justify-content-between">
                            <div>
                                <span className="fw-bolder">{plot.farmerCode}</span><br/>
                                <span>Plot #: {plot.plotNumber}</span>
                            </div>
                            <div className="text-end">
                                <span>Coordinates</span><br/>
                                {plot.centroid.latitude}&deg; N {plot.centroid.longitude}&deg; E
                            </div>
                        </div>
                    )
                })}

                <p className="small mt-5">
                    <em>ASIGMA is licensed by NITA-U.<br/>License Number: NITA/CERT/023-896.</em>
                </p>

                <div className="text-center bg-primary mt-2 py-2" style={{margin: '0 -15px'}}>
                    <span>&copy; {moment().year()} All rights reserved</span><br/>
                    <span><a className="text-decoration-underline" href="https://symos.io">Symos</a> is a product of <a href="https://asigmagroup.com">ASIGMA</a> </span><br/>
                </div>
            </>}
        </>
    );
}