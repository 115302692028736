import React from 'react';

export const TableWrapper = ({children} : any) => {
    return (
        <div className="card p-3 pb-0 border-0 rounded-3 shadow-sm">
            {children}
        </div>
    );
}

export default TableWrapper;