import React, { useCallback, useEffect, useState } from "react";
import { Box } from '@mui/material';
import { BarChart } from "@mui/x-charts";
import { getAll } from "../../../../utils/ajax";
import { useAuth } from "../../auth";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { Loading } from "../../../components/shared/Loading";

interface MonthlyVolume {
    month: string;
    volume: number;
}

interface DatasetItem {
    [key: string]: any;
    month: string;
    inflowVolume: number;
    outflowVolume: number;
}

interface Props {
    productId: string | null | undefined
    collectionCentreId: any | null | undefined
    varietyId: string | null | undefined
    startDate: string | null | undefined
    endDate: string | null | undefined
}

const CustomLegend = () => {
    const graphLegendData = [
        {
            label: "Purchases",
            color: "#DF901F",
        },
        {
            label: "Sales",
            color: "#1C5838",
        }
    ];

    return (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1} paddingBottom={2}>
            {graphLegendData.map((item, index) => (
                <Box key={index} display="flex" alignItems="center">
                    <Box className="custom-graph-legend"
                         sx={{backgroundColor: item.color, width: 8, height: 8, borderRadius: '50%'}}
                    />
                    <span style={{ color: '#848484', marginLeft: 4 }}>
                        {item.label}
                    </span>
                </Box>
            ))}
        </Box>
    );
};

const valueFormatter = (value: number | null) => {
    if (value === null) return '';

    if (value >= 1_000) {
        return `${Number((value / 1_000).toFixed(1)).toLocaleString()} mt`;
    } else {
        return `${value} kg`;
    }
};

export const InventoryVersusSalesGraph: React.FC<Props> = ({
                                                               productId,
                                                               collectionCentreId,
                                                               varietyId,
                                                               startDate,
                                                               endDate
                                                           }) => {
    const {currentUser} = useAuth();

    const [dataset, setDataset] = useState<DatasetItem[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [noData, setNoData] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const handleFetchData = () => {
        if (remoteRoutes.inventoryService) {

            const hubId = currentUser?.hubId;
            let params: any = {hubId};

            if (productId) params.productId = productId;

            if (collectionCentreId) params.collectionCentreId = collectionCentreId;

            if (varietyId) {
                params.varietyId = varietyId
            }

            if (startDate) {
                params.startDate = startDate
            }

            if (endDate) {
                params.endDate = endDate
            }
            const inflowUrl = buildUrl(remoteRoutes.inventoryService, '/dashboard/inflow/volumes', params);
            const outflowUrl = buildUrl(remoteRoutes.inventoryService, '/dashboard/outflow/volumes', params);

            setLoading(true);

            Promise.all([getAll(inflowUrl), getAll(outflowUrl)])
                .then(([inflowResponse, outflowResponse]) => {
                    if (inflowResponse?.volumes?.length === 0 && outflowResponse?.volumes?.length === 0) {
                        setNoData(true);
                        setLoading(false);
                        return;
                    }

                    const inflowData: DatasetItem[] = inflowResponse?.volumes?.map((item: MonthlyVolume) => ({
                        month: item.month,
                        inflowVolume: item.volume,
                        outflowVolume: 0,
                    }));

                    const outflowData: DatasetItem[] = outflowResponse?.volumes?.map((item: MonthlyVolume) => ({
                        month: item.month,
                        outflowVolume: item.volume,
                    }));

                    const mergedData = inflowData.map(inflowItem => {
                        const outflowItem = outflowData.find(outflow => outflow.month === inflowItem.month);
                        return {
                            ...inflowItem,
                            outflowVolume: outflowItem ? outflowItem.outflowVolume : 0,
                        };
                    });

                    setDataset(mergedData);
                    setNoData(false);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('API fetch error:', error);
                    setError('No data to display');
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        handleFetchData()
    }, [productId, collectionCentreId, varietyId, startDate, endDate]);

    return (
        <>
            {loading ? <Loading/> :
                error ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        sx={{fontSize: '16px', textAlign: 'center'}}
                    >
                        {error}
                    </Box>
                ) :
                    noData ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            sx={{fontSize: '16px', textAlign: 'center'}}
                        >
                            No data to display
                        </Box>
                    ) :
                        <Box>
                            <BarChart
                                dataset={dataset}
                                borderRadius={6}
                                xAxis={[{ scaleType: 'band', dataKey: 'month', disableTicks: true }]}
                                series={[
                                    {
                                        dataKey: 'inflowVolume',
                                        label: 'Purchases',
                                        color: '#DF901F',
                                        valueFormatter: valueFormatter,
                                        highlightScope: { highlight: "item" },
                                    },
                                    {
                                        dataKey: 'outflowVolume',
                                        label: 'Sales',
                                        color: '#1C5838',
                                        valueFormatter: valueFormatter,
                                        highlightScope: { highlight: "item" },
                                    },
                                ]}
                                yAxis={[{
                                    disableTicks: true,
                                    valueFormatter: valueFormatter,
                                }]}
                                height={350}
                                margin={{
                                    right: 20,
                                    left: 60
                                }}
                                grid={{ horizontal: true }}
                                sx={{
                                    '& .MuiChartsLegend-root': {
                                        display: 'none',
                                    },
                                    '& .MuiChartsGrid-root line': {
                                        stroke: '#EAEAEA',
                                        strokeDasharray: '4 4',
                                    },
                                    '& .MuiChartsAxis-root .MuiChartsAxis-line': {
                                        display: 'none',
                                    },
                                    '& .MuiChartsAxis-root .MuiChartsAxis-tickLabel': {
                                        fill: '#A0A0A0',
                                        fontsize: 14,
                                        fontWeight: 400,
                                    },
                                }}
                            />
                            <CustomLegend />
                        </Box>
            }
        </>
    );
};
