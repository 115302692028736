import React from 'react';
import {Skeleton} from "@mui/material";

export const SummaryCardLoader = () => {
    return (
        <div className="card shadow-sm bg-white rounded-3 border-0 w-100">
            <div className="card-body">
                <div className="d-flex align-items-center w-100 justify-content-between">
                    <div className="w-75">
                        <Skeleton variant="text" width={"50%"} height={15} />
                        <Skeleton variant="text" width={"80%"} height={50} />
                        <Skeleton variant="text" width={"25%"} height={15} />
                    </div>
                    <div>
                        <Skeleton variant="circular" width={70} height={70}  />
                    </div>
                </div>
            </div>
        </div>
    );
}