import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IsAgent, IsGlobalAdmin, IsHubAdmin } from "../app/hooks/roleChecker";
import { authService } from "../data/oidc/AuthService";

export interface ISideMenuItem {
  isActive: boolean;
  path: string;
  label: string;
  icon?: IconProp | undefined;
}

interface SideMenuProps {
  activeItem: string;
}

export const SideMenuItems = ({
  activeItem,
}: SideMenuProps): ISideMenuItem[] => {
  const items = [
    {
      isActive: activeItem
        .toLowerCase()
        .split("/")
        .includes("roles-permissions"),
      path: "/settings/roles-permissions",
      label: "Roles and Permissions",
      icon: undefined,
    },
    {
      isActive: activeItem
        .toLowerCase()
        .includes("/settings/collection-centers"),
      path: "/settings/collection-centers",
      label: "Collection Centers",
      icon: undefined,
    },
    {
      isActive: activeItem.toLowerCase().includes("/settings/contact-people"),
      path: "/settings/contact-people",
      label: "Contact People",
      icon: undefined,
    },
    {
      isActive: activeItem.toLowerCase().includes("/settings/cost-centers"),
      path: "/settings/cost-centers",
      label: "Cost Centers",
      icon: undefined,
    },
    {
      isActive: activeItem.toLowerCase().includes("settings/products"),
      path: "/settings/products",
      label: "Hub Products",
      icon: undefined,
    },
    {
      isActive: activeItem.toLowerCase().split("/").includes("inputs"),
      path: "/settings/inputs",
      label: "Inputs",
      icon: undefined,
    },
    {
      isActive: activeItem.toLowerCase().includes("/settings/processes"),
      path: "/settings/processes",
      label: "Processes",
      icon: undefined,
    },
    // {
    //     isActive: activeItem.toLowerCase().includes('/settings/hubs'),
    //     path: '/settings/hubs',
    //     label: 'Hubs',
    //     icon: undefined,
    // },
    {
      isActive: activeItem.toLowerCase().includes("system/products"),
      path: "/settings/system/products",
      label: "System Products",
      icon: undefined,
    },
    {
      isActive: activeItem.toLowerCase().includes("system/units"),
      path: "/settings/system/units",
      label: "Units",
      icon: undefined,
    },
    // {
    //     isActive: activeItem.toLowerCase().includes('/settings/user-management'),
    //     path: '/settings/user-management',
    //     label: 'User Management',
    //     icon: undefined,
    // },
  ];

  const GlobalAdminView = ["System Products", "Units", "Roles and Permissions"];
  const HubAdminView = [
    "Collection Centers",
    "Contact People",
    "Cost Centers",
    "Hub Products",
    "Inputs",
    "Processes",
  ];

  const roles = authService.getRoles();
  const isGlobalAdmin = IsGlobalAdmin(roles);
  const isHubAdmin = IsHubAdmin(roles);

  return items.filter((item) => {
    if (isGlobalAdmin && GlobalAdminView.includes(item.label)) {
      return true;
    }
    return isHubAdmin && HubAdminView.includes(item.label);
  });
};
