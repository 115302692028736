import {IOption} from "../app/components/shared/forms/XAutoComplete";

export const districts: IOption[] = [
    {label: "Abim", id: "Abim"},
    {label: "Adjumani", id: "Adjumani"},
    {label: "Agago", id: "Agago"},
    {label: "Alebtong", id: "Alebtong"},
    {label: "Amolatar", id: "Amolatar"},
    {label: "Amudat", id: "Amudat"},
    {label: "Amuria", id: "Amuria"},
    {label: "Amuru", id: "Amuru"},
    {label: "Apac", id: "Apac"},
    {label: "Arua", id: "Arua"},
    {label: "Budaka", id: "Budaka"},
    {label: "Bududa", id: "Bududa"},
    {label: "Bugiri", id: "Bugiri"},
    {label: "Bugweri", id: "Bugweri"},
    {label: "Buhweju", id: "Buhweju"},
    {label: "Buikwe", id: "Buikwe"},
    {label: "Bukedea", id: "Bukedea"},
    {label: "Bukomansimbi", id: "Bukomansimbi"},
    {label: "Bukwo", id: "Bukwo"},
    {label: "Bulambuli", id: "Bulambuli"},
    {label: "Buliisa", id: "Buliisa"},
    {label: "Bundibugyo", id: "Bundibugyo"},
    {label: "Bunyangabu", id: "Bunyangabu"},
    {label: "Bushenyi", id: "Bushenyi"},
    {label: "Busia", id: "Busia"},
    {label: "Butaleja", id: "Butaleja"},
    {label: "Butambala", id: "Butambala"},
    {label: "Butebo", id: "Butebo"},
    {label: "Buvuma", id: "Buvuma"},
    {label: "Buyende", id: "Buyende"},
    {label: "Dokolo", id: "Dokolo"},
    {label: "Gomba", id: "Gomba"},
    {label: "Gulu", id: "Gulu"},
    {label: "Hoima", id: "Hoima"},
    {label: "Ibanda", id: "Ibanda"},
    {label: "Iganga", id: "Iganga"},
    {label: "Isingiro", id: "Isingiro"},
    {label: "Jinja", id: "Jinja"},
    {label: "Kaabong", id: "Kaabong"},
    {label: "Kabale", id: "Kabale"},
    {label: "Kabarole", id: "Kabarole"},
    {label: "Kaberamaido", id: "Kaberamaido"},
    {label: "Kagadi", id: "Kagadi"},
    {label: "Kakumiro", id: "Kakumiro"},
    {label: "Kalaki", id: "Kalaki"},
    {label: "Kalangala", id: "Kalangala"},
    {label: "Kaliro", id: "Kaliro"},
    {label: "Kalungu", id: "Kalungu"},
    {label: "Kampala", id: "Kampala"},
    {label: "Kamuli", id: "Kamuli"},
    {label: "Kamwenge", id: "Kamwenge"},
    {label: "Kanungu", id: "Kanungu"},
    {label: "Kapchorwa", id: "Kapchorwa"},
    {label: "Kapelebyong", id: "Kapelebyong"},
    {label: "Karenga", id: "Karenga"},
    {label: "Kasese", id: "Kasese"},
    {label: "Kassanda", id: "Kassanda"},
    {label: "Katakwi", id: "Katakwi"},
    {label: "Kayunga", id: "Kayunga"},
    {label: "Kazo", id: "Kazo"},
    {label: "Kibaale", id: "Kibaale"},
    {label: "Kiboga", id: "Kiboga"},
    {label: "Kibuku", id: "Kibuku"},
    {label: "Kikuube", id: "Kikuube"},
    {label: "Kiruhura", id: "Kiruhura"},
    {label: "Kiryandongo", id: "Kiryandongo"},
    {label: "Kisoro", id: "Kisoro"},
    {label: "Kitagwenda", id: "Kitagwenda"},
    {label: "Kitgum", id: "Kitgum"},
    {label: "Koboko", id: "Koboko"},
    {label: "Kole", id: "Kole"},
    {label: "Kotido", id: "Kotido"},
    {label: "Kumi", id: "Kumi"},
    {label: "Kwania", id: "Kwania"},
    {label: "Kween", id: "Kween"},
    {label: "Kyankwanzi", id: "Kyankwanzi"},
    {label: "Kyegegwa", id: "Kyegegwa"},
    {label: "Kyenjojo", id: "Kyenjojo"},
    {label: "Kyotera", id: "Kyotera"},
    {label: "Lamwo", id: "Lamwo"},
    {label: "Lira", id: "Lira"},
    {label: "Luuka", id: "Luuka"},
    {label: "Luwero", id: "Luwero"},
    {label: "Lwengo", id: "Lwengo"},
    {label: "Lyantonde", id: "Lyantonde"},
    {label: "Madi Okollo", id: "Madi Okollo"},
    {label: "Manafwa", id: "Manafwa"},
    {label: "Maracha", id: "Maracha"},
    {label: "Masaka", id: "Masaka"},
    {label: "Masindi", id: "Masindi"},
    {label: "Mayuge", id: "Mayuge"},
    {label: "Mbale", id: "Mbale"},
    {label: "Mbarara", id: "Mbarara"},
    {label: "Mitooma", id: "Mitooma"},
    {label: "Mityana", id: "Mityana"},
    {label: "Moroto", id: "Moroto"},
    {label: "Moyo", id: "Moyo"},
    {label: "Mpigi", id: "Mpigi"},
    {label: "Mubende", id: "Mubende"},
    {label: "Mukono", id: "Mukono"},
    {label: "Nabilatuk", id: "Nabilatuk"},
    {label: "Nakapiripirit", id: "Nakapiripirit"},
    {label: "Nakaseke", id: "Nakaseke"},
    {label: "Nakasongola", id: "Nakasongola"},
    {label: "Namayingo", id: "Namayingo"},
    {label: "Namisindwa", id: "Namisindwa"},
    {label: "Namutumba", id: "Namutumba"},
    {label: "Napak", id: "Napak"},
    {label: "Nebbi", id: "Nebbi"},
    {label: "Ngora", id: "Ngora"},
    {label: "Ntoroko", id: "Ntoroko"},
    {label: "Ntungamo", id: "Ntungamo"},
    {label: "Nwoya", id: "Nwoya"},
    {label: "Obongi", id: "Obongi"},
    {label: "Omoro", id: "Omoro"},
    {label: "Otuke", id: "Otuke"},
    {label: "Oyam", id: "Oyam"},
    {label: "Pader", id: "Pader"},
    {label: "Pakwach", id: "Pakwach"},
    {label: "Pallisa", id: "Pallisa"},
    {label: "Rakai", id: "Rakai"},
    {label: "Rubanda", id: "Rubanda"},
    {label: "Rubirizi", id: "Rubirizi"},
    {label: "Rukiga", id: "Rukiga"},
    {label: "Rukungiri", id: "Rukungiri"},
    {label: "Rwampara", id: "Rwampara"},
    {label: "Serere", id: "Serere"},
    {label: "Sheema", id: "Sheema"},
    {label: "Sironko", id: "Sironko"},
    {label: "Soroti", id: "Soroti"},
    {label: "Ssembabule", id: "Ssembabule"},
    {label: "Tororo", id: "Tororo"},
    {label: "Wakiso", id: "Wakiso"},
    {label: "Yumbe", id: "Yumbe"},
    {label: "Zombo", id: "Zombo"}
]
