import React, {useEffect, useRef, useState} from 'react';
import {GoogleMap, MarkerF, PolygonF, useJsApiLoader} from "@react-google-maps/api";
import {env, remoteRoutes} from "../../../data/constants";
import {Skeleton} from "@mui/material";
import toast from "react-hot-toast";
import {overrideToastDefaults} from "../../../data/toastDefaults";
import {get} from "../../../utils/ajax";
import {PlotDetails} from "../../modules/profiling/partials/_plotDetails";

interface IProps {
    width: string;
    height: string;
    zoom: number;
    polygons: Array<Array<{ lat: number, lng: number }>>;
}

const libraries: any = ['geometry'];

const Map = ({width, height, zoom, polygons}: IProps) => {

    const mapRef = useRef<any>(null);
    const markersRef = useRef<any[]>([]);

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: env.googleMapsApiKey || '',
        libraries: libraries
    });

    const [selectedPlot, setSelectedPlot] = useState<any>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    const isAValidPolygon = (polygon: Array<{ lat: number, lng: number }>) => {
        // Check if there are at least 4 points (for a valid polygon)
        if (polygon.length < 4) {
            return false;
        }

        // Check if all points except the last one (which should match the first) are distinct
        const distinctPoints = new Set(polygon.slice(0, -1).map(point => `${point.lat},${point.lng}`));
        if (distinctPoints.size < 3) {
            return false;
        }

        // If the polygon is already closed, ensure the first and last points are the same
        const firstPoint = polygon[0];
        const lastPoint = polygon[polygon.length - 1];
        if (firstPoint.lat !== lastPoint.lat || firstPoint.lng !== lastPoint.lng) {
            return false;
        }
    }

    const processPolygons = (polygonsArray: Array<Array<{ lat: number, lng: number }>>) => {

        const polygonsWithUniqueCoords = removeDuplicateCoordinates(polygonsArray)

        return polygonsWithUniqueCoords.map((polygon) => {
            // const uniqueCoordinates: Array<{ lat: number, lng: number }> = [];
            // coords.forEach((coord, index) => {
            //     const prevCoord = uniqueCoordinates[uniqueCoordinates.length - 1];
            //     const isDuplicate = prevCoord &&
            //         prevCoord.lat.toFixed(7) === coord.lat.toFixed(7) &&
            //         prevCoord.lng.toFixed(7) === coord.lng.toFixed(7);
            //
            //     if (!isDuplicate || index === coords.length - 1) {
            //         uniqueCoordinates.push(coord);
            //     }
            // });

            // Ensure the polygon is closed
            // const firstCoord = uniqueCoordinates[0];
            // const lastCoord = uniqueCoordinates[uniqueCoordinates.length - 1];
            //
            // if (firstCoord.lat !== lastCoord.lat || firstCoord.lng !== lastCoord.lng) {
            //     uniqueCoordinates.push(firstCoord);
            // }

            return {
                isValid: isAValidPolygon(polygon),
                center: calculateCentroid(polygon),
                polygon: polygon
            };
        });
    };

    const calculateCentroid = (polygon: Array<{ lat: number, lng: number }>) => {
        let centroid = {lat: 0, lng: 0};
        let signedArea = 0;
        let x0, y0, x1, y1, a;  // Temporary variables for calculations

        // Iterate over each edge of the polygon
        for (let i = 0; i < polygon.length - 1; i++) {
            x0 = polygon[i].lng;
            y0 = polygon[i].lat;
            x1 = polygon[i + 1].lng;
            y1 = polygon[i + 1].lat;

            // Calculate the signed area of the triangle formed by the points
            a = x0 * y1 - x1 * y0;
            signedArea += a;

            // Accumulate the centroid coordinates
            centroid.lng += (x0 + x1) * a;
            centroid.lat += (y0 + y1) * a;
        }

        signedArea *= 0.5;
        centroid.lng /= (6 * signedArea);
        centroid.lat /= (6 * signedArea);

        return centroid;
    };

    const removeDuplicateCoordinates = (polygons: any[]) => {
        return polygons.map((polygon: any) => {
            const uniqueCoords: any[] = [];
            const coordsSet = new Set();

            polygon.forEach((coord: any) => {
                const key = `${coord.lat},${coord.lng}`; // Create a unique key for each coordinate
                if (!coordsSet.has(key)) { // Check if the coordinate is already added
                    coordsSet.add(key); // Add to set if it's unique
                    uniqueCoords.push(coord); // Add to result array
                }
            });

            // Check if the polygon is valid
            if (uniqueCoords.length < 3) {
                // Return only the first coordinate if the polygon is invalid
                return uniqueCoords.length > 0 ? [uniqueCoords[0]] : [];
            }

            return uniqueCoords;
        })
    }
    const closedPolygons = processPolygons(polygons);

    const calculateCenterOfMap = (polygons: Array<Array<{ lat: number, lng: number }>>) => {
        let totalLat = 0;
        let totalLng = 0;
        let totalPoints = 0;

        if (polygons.length === 0) {
            return {lat: 0.3476, lng: 32.5825};
        }

        polygons.forEach((polygon) => {
            polygon.forEach((point) => {
                totalLat += point.lat;
                totalLng += point.lng;
                totalPoints++;
            });
        });

        return {
            lat: totalLat / totalPoints,
            lng: totalLng / totalPoints
        };
    };

    useEffect(() => {
        const fetchForestCover = () => {
            get(`${remoteRoutes.forestCheckerService}/get-forest-cover`, (response) => {
                const {urlTemplate} = response;
                const map = mapRef.current

                if (map) {
                    const overlay = new window.google.maps.ImageMapType({
                        getTileUrl: (coord, zoom) =>
                            urlTemplate.replace('{x}', coord.x).replace('{y}', coord.y).replace('{z}', zoom),
                        tileSize: new window.google.maps.Size(256, 256),
                    });
                    map.overlayMapTypes.push(overlay);
                }
            }, () => {
                toast.error('Error while fetching forest cover', overrideToastDefaults);
            });
        };

        fetchForestCover();

    }, [mapRef]);

    if (!isLoaded) {
        return <Skeleton width={'100%'} height={200} variant={'rectangular'}/>;
    }

    let mapCenter = calculateCenterOfMap(closedPolygons.map(q => q.polygon));
    // mapCenter = { lat: 0.3303438, lng: 32.587215 }

    const MapOptions = {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
        scrollwheel: true,
        zoom: zoom || 14,
        mapTypeId: google.maps.MapTypeId.TERRAIN
    };

    const handlePlotClick = (plot: any) => {
        setDrawerVisible(true);
        setSelectedPlot(plot);
    };

    return (
        <GoogleMap
            mapContainerStyle={{width, height, borderRadius: 10}}
            center={mapCenter}
            options={MapOptions}
            onLoad={(map) => {
                mapRef.current = map
            }}
        >
            {closedPolygons.map((path, index) => {
                // const rectangleCoordinates = [
                //     { lat: 0.3307488, lng: 32.586405, position: 1 },
                //     { lat: 0.3307488, lng: 32.588025, position: 2 },
                //     { lat: 0.3299388, lng: 32.588025, position: 3 },
                //     { lat: 0.3299388, lng: 32.586405, position: 4 },
                //     { lat: 0.3307488, lng: 32.586405, position: 5 } // Closing the polygon
                // ];

                return (
                    <PolygonF
                        key={index}
                        paths={
                            path.polygon
                                .sort((a: any, b: any) => a.position - b.position)
                                .map(coord => ({lat: coord.lat, lng: coord.lng}))
                        }
                        onClick={() => setDrawerVisible(true)}
                        options={{
                            fillColor: 'yellow',
                            fillOpacity: 0.8,
                            strokeColor: 'red',
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            geodesic: false,
                            zIndex: 1,
                        }}
                    />
                )

            })}

            {closedPolygons.map((data, index) => (
                <MarkerF
                    key={index}
                    position={data.isValid ? data.center : data.polygon[0]}
                    onClick={() => handlePlotClick(index)}
                />
            ))}

            {selectedPlot !== null && (
                <PlotDetails
                    close={() => setDrawerVisible(false)}
                    show={drawerVisible}
                    plot={selectedPlot}
                />
            )}
        </GoogleMap>
    );
};

export default Map;