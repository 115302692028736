import React, {useEffect, useRef, useState} from "react";
import {InnerMenu} from "../../components/shared/InnerMenu";
import {PageHeaderLoader} from "../../components/shared/loaders/PageHeaderLoader";
import {PageHeader} from "../../components/shared/PageHeader";
import TableWrapper from "../../components/shared/TableWrapper";
import XPagination from "../../components/shared/XPagination";
import XTable, {
    IRowAction,
    ITableColumn,
} from "../../components/shared/XTable";
import {Modules} from "../../interfaces/Enums";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {remoteRoutes} from "../../../data/constants";
import {
    IInputsDistributionState,
    INPUTS_DISTRIBUTION_CONSTANTS,
} from "../../../data/redux/inputs/inputsReducer";
import {
    toastMessages,
    overrideToastDefaults,
} from "../../../data/toastDefaults";
import {IState} from "../../../data/types";
import {
    IApiResponse,
    IPaginationMetadata,
} from "../../interfaces/IApiResponse";
import {IInputDistribution} from "./interfaces/IInputDistribution";
import {get} from "../../../utils/ajax";
import {buildUrl} from "../../../utils/queryBuilder";
import {useAuth} from "../auth";
import InputStockDetails from "./InputStockDetails";
import {IBreadcrumb} from "../../components/shared/Breadcrumbs";
import {faCheck, faSearch, faTruck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {authService} from "../../../data/oidc/AuthService";
import {IHub} from "../settings/interfaces/IHub";
import MoveInputsForm from "./modals/MoveInputsForm";
import SellInputForm from "./modals/SellInputForm";
import debounce from "lodash.debounce";

const columns: ITableColumn[] = [
    {
        label: "Collection Center",
        id: "collectionCentreName",
        textAlign: "text-start",
    },
    {label: "Input Name", id: "inputName", textAlign: "text-start"},
    {
        label: "Quantity",
        id: "quantity",
        textAlign: "text-end",
        isNumberFormat: true,
    },
    {label: "Unit", id: "unit", textAlign: "text-start"},
];

type Props = {
    selectedRow: any;
};

const InputStockLedger = () => {
    document.title = "Inputs > Stock";

    const dispatch = useDispatch();
    const {currentUser} = useAuth();
    const [selectedRow, setSelectedRow] = useState<IInputDistribution | null>(
        null,
    );
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<any[]>([]);
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [orignialData, setOriginalData] = useState<IInputDistribution[]>([]);
    const [filteredData, setFilteredData] = useState<IInputDistribution[]>([]);
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 15,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0,
    });

    const {data, loading}: IInputsDistributionState = useSelector(
        (state: IState) => state.inputs,
    );

    const setLoading = (state: boolean) => {
        dispatch({
            type: INPUTS_DISTRIBUTION_CONSTANTS.LOADING_DATA,
            payload: state,
        });
    };

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize, searchTerm);
    }, [page, searchTerm]);

    const handleFetchData = (page: number, pageSize: number, searchTerm?: string) => {
        const hub: IHub | undefined = authService.getHub();

        if (remoteRoutes.profiles) {
            const HubId = currentUser?.hubId;
            const parentHubId = hub?.parentHubId;

            const CollectionCentreIds = hub?.collectionCentres?.map(
                (centre) => centre.id,
            );

            let params: any = {CollectionCentreIds, page, pageSize};

            // if (parentHubId === null) {
            //     params = {HubId, page, pageSize};
            // } else {
            //     params = {CollectionCentreIds, page, pageSize};
            // }

            if (searchTerm) {
                params.searchTerm = searchTerm;
            }

            const url = buildUrl(remoteRoutes.inputsService, "/ledger/centre", params);

            get(
                url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;
                    setOriginalData(data);
                    setFilteredData(data);
                    setPagination(paginationMetadata);

                    dispatch({
                        type: INPUTS_DISTRIBUTION_CONSTANTS.FETCH_DATA,
                        payload: data,
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: INPUTS_DISTRIBUTION_CONSTANTS.STOP_FETCH});
                    setLoading(false);
                },
            );
        }
    };

    const handleSearch = (event: any) => {
        if (event.target.value === "") {
            setSearchTerm(undefined);
        }

        if (event.keyCode === 13) {
            const term = searchInputRef.current?.value || "";
            setSearchTerm(term);
            setPage(1);
        }
    };

    const debouncedSearch = useRef(
        debounce((value: string) => {
            setSearchTerm(value);
            setPage(1);
            handleFetchData(page, pagination.pageSize, value);
        }, 800),
    ).current;

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, []);

    const handleSearchChange = (event: any) => {
        const value = event.target.value;
        debouncedSearch(value);
    };

    const handleRowClick = (item: any) => {
        setDrawerVisible(true);
        setSelectedRow(item);
    };

    const handleCheckRow = (item: any) => {
        setSelected([item]);
    };

    useEffect(() => {
        setBreadcrumbs([
            {label: "Inputs", url: undefined},
            {label: "Stock", url: undefined},
        ]);
    }, []);

    const rowActions: IRowAction[] = [
        {
            label: "Move Inputs",
            onClick: (item: any) => {
                setSelectedRow(item);
            },
            icon: faTruck,
            dataTarget: "#move-inputs",
            dataToggle: "modal",
        },
        {
            label: "Sell Inputs",
            onClick: (item: any) => {
                setSelectedRow(item);
            },
            icon: faCheck,
            dataTarget: "#sell-input",
            dataToggle: "modal",
        },
    ];

    return (
        <>
            {loading && <PageHeaderLoader/>}
            {!loading && (
                <>
                    <PageHeader breadcrumbs={breadcrumbs}>
                        <InnerMenu module={Modules.Inputs}/>
                    </PageHeader>
                </>
            )}

            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-25">
                        <input
                            type="text"
                            ref={searchInputRef}
                            // onKeyUp={(event) => handleSearch(event)}
                            onChange={handleSearchChange}
                            className="form-control"
                            placeholder="Type to search..."
                        />
                        <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch}/>
            </span>
                    </div>
                </div>
                <XTable
                    checked={false}
                    // onRowClick={(item) => handleRowClick(item)}
                    onCheckRow={(item) => handleCheckRow(item)}
                    data={filteredData.length > 0 ? filteredData : []}
                    loading={loading}
                    columns={columns}
                    rowActions={rowActions}
                />
                <XPagination
                    currentPage={page}
                    pagination={pagination}
                    dataLength={filteredData.length}
                    setPage={setPage}
                />
            </TableWrapper>

            <SellInputForm selectedRow={selectedRow}/>
            <MoveInputsForm selectedRow={selectedRow}/>
            <InputStockDetails
                close={() => setDrawerVisible(false)}
                show={drawerVisible}
                selectedRow={selectedRow}
            />
        </>
    );
};

export default InputStockLedger;