import {IDownloadFile} from "../../../utils/types";
import {printTime} from "../../../utils/dateHelpers";

export const DOWNLOADS_CONSTANTS = {
    SCHEDULED: "ADD_DOWNLOAD",
    READY: "READY_DOWNLOAD",
    FINISHED: "FINISHED_DOWNLOAD",
    REMOVE: "REMOVE_DOWNLOAD"
}

export interface IDownloadState {
    loading?: boolean
    scheduled: IDownloadFile[]
    ready: IDownloadFile[]
}

const initialState: IDownloadState = {
    loading: false,
    scheduled: [],
    ready: []
}

export default function reducer(state = initialState, action: any): IDownloadState {
    switch (action.type) {

        case DOWNLOADS_CONSTANTS.SCHEDULED: {
            const toAdd: IDownloadFile = action.payload
            return {...state, scheduled: [toAdd, ...state.scheduled]}
        }

        case DOWNLOADS_CONSTANTS.FINISHED: {
            const flagAsDownloaded: string = action.payload
            let updatedList = state?.scheduled?.map((item:any) => {
                if (item.RequestId === flagAsDownloaded) {
                    item.Status = 'File Downloaded'
                    item.createdAt = printTime(new Date())
                }
                return item
            })

            return {...state, scheduled: [...updatedList]}
        }

        case DOWNLOADS_CONSTANTS.READY: {
            const toAdd: IDownloadFile = action.payload
            return {...state, ready: [toAdd, ...state.ready]}
        }

        case DOWNLOADS_CONSTANTS.REMOVE: {
            const toRemove: IDownloadFile = action.payload
            return {...state, ...toRemove}
        }

        default: {
            return state
        }
    }
}