import {
    faLayerGroup,
    faPeopleGroup,
    faShoppingCart,
    faTruck,
    faTableColumns
} from "@fortawesome/free-solid-svg-icons";
import { IMenuItem } from "../../../utils/MainMenu";

interface MainMenuProps {
    activePage: string;
}

export const InventoryInnerMenu = ({activePage}: MainMenuProps): IMenuItem[] => {
    const menuItems: IMenuItem[] = [
        {
            label: "Overview",
            path: '/inventory/dashboard',
            isActive: activePage.toLowerCase().split("/").includes('dashboard'),
            icon: faTableColumns
        },
        {
            label: "Farmer Purchases",
            path: '/inventory/stock',
            isActive: activePage.toLowerCase() === '/inventory' || activePage.toLowerCase().split("/").includes('stock'),
            icon: faShoppingCart
        },
        {
            label: "Batches",
            path: '/inventory/batches',
            isActive: activePage.toLowerCase().split("/").includes('batches'),
            icon: faLayerGroup
        },
        {
            label: "Movements",
            path: '/inventory/movements',
            isActive: activePage.toLowerCase().split("/").includes('movements'),
            icon: faTruck
        },
        {
            label: "Buyers",
            path: '/inventory/buyers',
            isActive: activePage.toLowerCase().split("/").includes('buyers'),
            icon: faPeopleGroup
        },
    ];

    return process.env.REACT_APP_HIDE_OVERVIEW === 'true'
        ? menuItems.filter(item => item.label !== "Overview")
        : menuItems;
};
