import React, {useEffect} from 'react';
import {UserManager} from 'oidc-client';
import {useNavigate} from 'react-router-dom';

const SignoutCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const userManager = new UserManager({});
        userManager.signoutRedirectCallback().then(() => {
            navigate('/auth');
        }).catch(error => {
            console.error(error);
        });
    }, [navigate]);

    return <div>Signing out...</div>;
}

export default SignoutCallback;