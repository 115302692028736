import { SettingsPageHeader } from "../../../components/shared/SettingsPageHeader";
import React, { useCallback, useEffect, useState } from "react";
import TableWrapper from "../../../components/shared/TableWrapper";
import { ISettingsState } from "../../../../data/redux/settings/settingsReducer";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../data/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faSearch,
  faTrashCanArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  IRowAction,
  ITableColumn,
  XTable,
} from "../../../components/shared/XTable";
import DeleteRoleForm from "./modals/DeleteRoleForm";
import { IPaginationMetadata } from "../../../interfaces/IApiResponse";
import XPagination from "../../../components/shared/XPagination";
import RolesAndPermissionsDetails from "./modals/RolesAndPermissionsDetails";
import { IButtonProps } from "../../../components/shared/PageHeader";
import RolesAndPermissionForm from "./modals/RolesAndPermissionForm";
import { ICollectionCentre, IRole } from "../interfaces/IHub";
import debounce from "lodash.debounce";

type IProps = {
  title?: string;
};

const columns: ITableColumn[] = [
  {
    label: "Role Name",
    id: "roleName",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "Description",
    id: "roleDescription",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];

const dummyData = [
  {
    id: 1,
    roleName: "Super Admin",
    roleDescription:
      "Full access to all system features, settings, and user management.",
  },
  {
    id: 2,
    roleName: "Admin",
    roleDescription:
      "Manages users and operations within a specific product or area.",
  },
  {
    id: 3,
    roleName: "Collection Centre Manager",
    roleDescription:
      "Oversees inventory, staff, and logistics at a collection center.",
  },
  {
    id: 4,
    roleName: "Hub Manager",
    roleDescription:
      "Manages operations, logistics, and staff at a distribution hub",
  },
  {
    id: 5,
    roleName: "Agent",
    roleDescription:
      "Handles customer interactions or performs field operations for day-to-day tasks.",
  },
];
const RolesAndPermissionsPage = ({ title }: IProps) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedRoleDetails, setSelectedRoleDetails] = useState<IRole | null>(
    null,
  );

  const [selectedRoleDelete, setSelectedRoleDelete] = useState(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [tableData, setTableData] = useState(dummyData);

  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const actionButtons: IButtonProps[] = [
    {
      label: "Create New Role",
      cssClass: "btn btn-primary btn-sm ms-2",
      dataTarget: "#add-role",
      dataToggle: "modal",
    },
  ];
  const [page, setPage] = useState<number>(1);

  const [pagination, setPagination] = useState<IPaginationMetadata>({
    pageSize: 5,
    hasNextPage: false,
    currentPage: 1,
    hasPreviousPage: false,
    totalItems: dummyData.length,
    totalPages: 0,
  });

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  useEffect(() => {
    if (searchTerm) {
      const filteredData = dummyData.filter((item) =>
        item.roleName.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setTableData(filteredData);
    } else {
      setTableData(dummyData);
    }
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
  };

  const handleRowClick = (item: any) => {
    setSelectedRoleDetails(item);
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: IRole) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const { roles, loading }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );

  useEffect(() => {
    setTableData(dummyData);
  }, []);

  const rowActions: IRowAction[] = [
    {
      label: "View Details",
      onClick: (item: any) => handleRowClick(item),
      icon: faEyeSlash,
      dataTarget: "#view-role-details",
      dataToggle: "modal",
    },
    {
      label: "Delete",
      onClick: (item: any) => {
        setSelectedRoleDelete(item);
      },
      icon: faTrashCanArrowUp,
      dataTarget: "#delete-role",
      dataToggle: "modal",
    },
  ];

  return (
    <>
      <>
        {title && <SettingsPageHeader title={title} />}
        <TableWrapper>
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="input-group w-auto">
              <input
                type="text"
                // onKeyUp={(event) => handleSearch(event)}
                onChange={handleSearchChange}
                className="form-control"
                placeholder="Type to search..."
              />
              <span className="input-group-text" id="addon-wrapping">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>

            <div className={"d-flex"}>
              <div className="action-buttons">
                {showActionButtons &&
                  actionButtons.map((button, index) => {
                    return (
                      <button
                        data-bs-toggle={button.dataToggle}
                        data-bs-target={button.dataTarget}
                        className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                        key={index}
                        onClick={button.onClick}
                      >
                        {button.processing ? "Please wait..." : button.label}
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>

          <XTable
            selected={selected}
            onSelectAll={(rows) => handleSelectAll(rows)}
            onCheckRow={(row) => handleCheckRow(row)}
            columns={columns}
            loading={loading}
            data={tableData}
            rowActions={rowActions}
          />

          <XPagination
            currentPage={page}
            pagination={pagination}
            dataLength={tableData.length}
            setPage={(page) => setPage(page)}
          />
        </TableWrapper>
      </>
      <RolesAndPermissionForm />
      <RolesAndPermissionsDetails selectedRoleDetails={selectedRoleDetails} />

      <DeleteRoleForm selectedRoleDelete={selectedRoleDelete} />
    </>
  );
};

export default RolesAndPermissionsPage;
