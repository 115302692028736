import {Outlet, Route, Routes} from "react-router-dom";
import Hubs from "./Hubs";
import HubDetailsPage from "./HubDetailsPage";
import {IHub} from "../../settings/interfaces/IHub";
import {authService} from "../../../../data/oidc/AuthService";

const HubsPage = () => {

    const hub: IHub | any = authService.getHub()
    const mau: string | undefined = hub.minimumAggregationUnit ?? 'Hubs';

    return (
        <Routes>
            <Route element={<Outlet/>}>
                <Route index element={<Hubs title={mau}/>}/>
                <Route path=':hubId' element={<HubDetailsPage mau={mau}/>}/>
            </Route>
        </Routes>
    )
}

export default HubsPage