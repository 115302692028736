import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { INVENTORY_PURCHASES_CONSTANTS } from "../../data/redux/inventory/purchasesReducer";
import { overrideToastDefaults, toastMessages } from "../../data/toastDefaults";
import { IApiResponse, IPaginationMetadata } from "../interfaces/IApiResponse";
import { search } from "../../utils/ajax";
import { remoteRoutes } from "../../data/constants";
import { useAuth } from "../modules/auth";
import { useLocation } from 'react-router-dom';

const useFetchPurchases = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { currentUser, userHub } = useAuth()
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const fetchPurchases = useCallback((page: number, pageSize: number, searchParams: any) => {
        dispatch({
            type: INVENTORY_PURCHASES_CONSTANTS.LOADING_PURCHASES,
            payload: true
        });

        let url = '';

        if (location.pathname.includes('/batches') || location.pathname.includes('/movements')) {
            url = `${remoteRoutes.inventoryService}/batches/purchases`;
        } else {
            url = `${remoteRoutes.inventoryService}/purchases`;
        }

        const params: any = { page, pageSize, ...searchParams };

        search(url, params,
            (response: IApiResponse) => {
                const { data, paginationMetadata } = response;
                setPagination(paginationMetadata);

                dispatch({
                    type: INVENTORY_PURCHASES_CONSTANTS.FETCH_PURCHASES,
                    payload: data
                });

                toast.success(toastMessages.default.success, overrideToastDefaults);
            },
            (error) => {
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => {
                // Optional: any cleanup or final steps
            }
        );
    }, [currentUser, dispatch]);

    return {
        fetchPurchases,
        pagination
    };
}

export default useFetchPurchases;