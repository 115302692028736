import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState,} from 'react'
import {LayoutSplashScreen} from '../../../../_theme/layout/core'
import {AuthModel, IUserProfile} from './_models'
import * as authHelper from './AuthHelpers'

import {useDispatch} from "react-redux";
import {User} from "oidc-client";
import {authService} from "../../../../data/oidc/AuthService";
import {handleLogin, handleLogout, setRoles} from "../../../../data/redux/coreActions";

import toast from 'react-hot-toast';
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {ICategories, IHub} from "../../settings/interfaces/IHub";
import {get} from "../../../../utils/ajax";
import {AUTH_CATEGORIES_KEY, AUTH_HUB_KEY, AUTH_ID_KEY, remoteRoutes} from "../../../../data/constants";
import fetchHubDetails from "../../../hooks/fetchHubDetails";
import useFetchCategories from "../../../hooks/useFetchCategories";
import {string} from "yup";

type AuthContextProps = {
    auth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined) => void
    currentUser: IUserProfile | undefined
    setCurrentUser: Dispatch<SetStateAction<IUserProfile | undefined>>
    setUserHub: Dispatch<SetStateAction<IHub | undefined>>
    userHub: IHub | undefined
    userRoles: string[] | undefined
    setUserRoles: Dispatch<SetStateAction<string[] | undefined>>
    categories: ICategories | undefined
    setCategories: Dispatch<SetStateAction<ICategories | undefined>>
    refreshHubDetails: () => void
    logout: () => void
}

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => {
    },
    userHub: undefined,
    setUserHub: () => {
    },
    currentUser: undefined,
    setCurrentUser: () => {
    },
    refreshHubDetails: () => {
    },
    logout: () => {
    },
    userRoles: undefined,
    setUserRoles: () => {
    },
    categories: undefined,
    setCategories: () => {
    }
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
    return useContext(AuthContext)
}

const AuthProvider: FC<any> = ({children}) => {
    const dispatch = useDispatch();
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
    const [currentUser, setCurrentUser] = useState<IUserProfile | undefined>()
    const [userHub, setUserHub] = useState<IHub | undefined>()
    const [userRoles, setUserRoles] = useState<string[] | undefined>()
    const [categories, setCategories] = useState<ICategories>()

    const saveAuth = (auth: AuthModel | undefined) => {
        setAuth(auth)
        if (auth) {
            authHelper.setAuth(auth)
        } else {
            authHelper.removeAuth()
        }
    }

    const logout = async () => {
        saveAuth(undefined)
        setUserHub(undefined)
        setCurrentUser(undefined)
        setCategories(undefined)
        setUserRoles(undefined)
        dispatch(handleLogout());
        await authService.logout();
    }

    const refreshHubDetails = () => {
        if (currentUser?.hubId) {
            fetchHubDetails(currentUser?.hubId, setUserHub)
        }
    }

    return (
        <AuthContext.Provider
            value={{
                auth,
                userHub,
                setUserHub,
                saveAuth,
                currentUser,
                setCurrentUser,
                refreshHubDetails,
                userRoles,
                setUserRoles,
                categories,
                setCategories,
                logout
            }}>
            {children}
        </AuthContext.Provider>
    )
}

const AuthInit: FC<any> = ({children}) => {

    const dispatch = useDispatch();
    const {refreshHubDetails} = useAuth();
    const {auth, logout, setCurrentUser, setUserHub, setUserRoles, setCategories} = useAuth()
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    useEffect(() => {

        authService.getUser().then(async (user: User | null) => {
            if (user) {
                const profile: any = {...user.profile}
                localStorage.setItem(AUTH_ID_KEY, user.id_token)

                // Dispatch roles to Redux store
                const roles = Array.isArray(profile.role) ? profile.role : [profile.role];
                dispatch(setRoles(roles));


                const hasGlobalAdminRole = Array.isArray(profile.role)
                    ? profile.role.includes('Global Administrator')
                    : profile.role === 'Global Administrator'

                if (user.profile.hubId || hasGlobalAdminRole) {
                    dispatch(handleLogin({user: profile, token: user.access_token}));
                    setCurrentUser(profile)
                    setUserRoles(roles)

                    try {
                        // fetch categories
                        get(`${remoteRoutes.onboardingService}/hubs/settings`, (response) => {
                            localStorage.setItem(AUTH_CATEGORIES_KEY, JSON.stringify(response))
                            setCategories(response)
                        }, (error) => {
                            toast.error("Unable to fetch categories", overrideToastDefaults)
                        }, () => {

                        })
                        // useFetchCategories();
                    } catch (error) {
                        console.log(error)
                    }

                    if (user.profile.hubId) {
                        const hub: IHub | undefined = authService.getHub()
                        if (!hub) {
                            get(`${remoteRoutes.onboardingService}/hubs/${user.profile.hubId}`, (response) => {
                                localStorage.setItem(AUTH_HUB_KEY, JSON.stringify(response))
                                setUserHub(response)
                            }, (error) => {
                                toast.error("Unable to fetch hub details", overrideToastDefaults)
                            }, () => {

                            })
                        }
                    }
                    refreshHubDetails();
                    setShowSplashScreen(false)
                } else {
                    toast.error(toastMessages.Login.inValidAccount, overrideToastDefaults);
                    logout()
                }
            } else {
                // toast.error(toastMessages.Login.inValidAccount, overrideToastDefaults);
                // await authService.login()

                setShowSplashScreen(false)
            }
        })

    }, [])

    return showSplashScreen ? <LayoutSplashScreen/> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
