import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faShoppingCart, faTruck } from "@fortawesome/free-solid-svg-icons";

export const printBatchStatus = (value?: number | undefined) => {
    switch (value) {
        case 1: return <span className="batched-status">Batched</span>
        case 2: return <span className="intransit-status">In Transit</span>
        case 3: return <span className="delivered-status">Delivered</span>
        case 4: return <span className="processing-status">Processing</span>
        case 5: return <span className="processed-status">Processed</span>
        case 6: return <span className="exported-status">Exported</span>
        case 7:
            return <span className="badge rounded-pill bg-warning">Low Stock</span>
        case 8:
            return <span className="badge rounded-pill bg-success">In Stock</span>
        case 9:
            return <span className="badge rounded-pill bg-danger">Out of Stock</span>
        default:
            return <span className="batched-status">Batched</span>
    }
}

export const printMovementStatus = (value?: number | undefined) => {
    switch (value) {
        case 1: return <span className="badge rounded-pill bg-secondary-subtle">In Transit <FontAwesomeIcon icon={faTruck} /></span>
        case 2: return <span className="badge rounded-pill bg-info">Delivered</span>
        default:
            return <span className="badge rounded-pill bg-secondary">In Transit <FontAwesomeIcon
                icon={faTruck} /></span>
    }
}

export const printDeforestationStatus = (value?: number | undefined) => {
    switch (value) {
        case 1: return <span className="badge rounded-pill bg-success">Not Deforested</span>
        case 2: return <span className="badge rounded-pill bg-danger">Deforested</span>
        default: return <span className="badge rounded-pill bg-success">Not Deforested</span>
    }
}