import React, {useEffect, useState} from 'react'
import TableWrapper from "../../components/shared/TableWrapper";
import {IButtonProps} from "../../components/shared/PageHeader";
import XTable, { ITableColumn } from "../../components/shared/XTable";
import { useAuth } from "../auth";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../data/types";
import { CreateBatchForm } from "./modals/CreateBatchForm";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { IMenuItem } from "../../../utils/MainMenu";
import { InventoryInnerMenu } from "./InventoryInnerMenu";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Modules } from "../../interfaces/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { IIdNamePair } from "../settings/interfaces/IHub";
import { SellPurchasesForm } from "./modals/SellPurchasesForm";
import { DownloadCsvForm } from "./modals/DownloadCsvForm";
import { ImportPurchasesForm } from "./modals/ImportPurchasesForm";
import XPagination from "../../components/shared/XPagination";
import { printNaturalDateShort } from "../../../utils/dateHelpers";
import SelectProductForm from './modals/SelectProductForm';
import toast from 'react-hot-toast';
import { remoteRoutes } from '../../../data/constants';
import { INVENTORY_PURCHASES_CONSTANTS } from '../../../data/redux/inventory/purchasesReducer';
import { toastMessages, overrideToastDefaults } from '../../../data/toastDefaults';
import { IApiResponse, IPaginationMetadata } from '../../interfaces/IApiResponse';
import { buildUrl } from '../../../utils/queryBuilder';
import { get } from '../../../utils/ajax';

const calculateUnitPrice = (totalPrice: number, quantity: number): string => {
    if (!quantity || quantity === 0) return '0';

    const unitPrice = totalPrice / quantity;

    const formattedUnitPrice = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(unitPrice);

    return unitPrice === 0 ? '0' : formattedUnitPrice;
};

export const PurchaseColumns: ITableColumn[] = [
    {
        label: 'Date of Purchase',
        id: 'date',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start',
    },
    {
        label: 'Farmer',
        id: 'farmerName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Product',
        id: 'productName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Variety',
        id: 'varietyName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Quantity(kg)',
        id: 'quantity',
        link: undefined,
        isNumberFormat: true,
        isDate: false,
        textAlign: 'text-end',
    },
    {
        label: 'Unit Price (UGX)',
        id: 'unitPrice',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-end',
    },
    {
        label: 'Cost (UGX)',
        id: 'totalPrice',
        link: undefined,
        isNumberFormat: true,
        isDate: false,
        textAlign: 'text-end',
    },
    {
        label: 'Agent',
        id: 'agentName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
]

interface Props {
    showHeader?: boolean
}

export const Purchases = ({ showHeader = true }: Props) => {

    document.title = "Inventory > Purchases"

    const PurchaseStatus = {
        All: 'All' as const,
        Batched: true as const,
        Unbatched: false as const
    };

    type PurchaseStatusType = typeof PurchaseStatus[keyof typeof PurchaseStatus];
    const [activeSection, setActiveSection] = useState<PurchaseStatusType>(PurchaseStatus.All);

    const { currentUser } = useAuth()
    const location = useLocation()

    const [currentStatus, setCurrentStatus] = useState<boolean | null>(null);

    const urlParams = useParams()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();

    const currentPage = searchParams.get('page') || 1
    const { data, loading }: any = useSelector((state: IState) => state.purchases)
    const [selected, setSelected] = useState<any[]>([])

    const [page, setPage] = useState<number>(Number(currentPage))
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const [showActionButtons, setShowActionButtons] = useState<boolean>(false)
    const [batchedQuantity, setBatchedQuantity] = useState(0)

    const activePage = location.pathname.toLowerCase()
    const [subMenuItems, setSubMenuItems] = useState<IMenuItem[] | undefined>([]);

    const [selectedProduct, setSelectedProduct] = useState<IIdNamePair>({ id: '', name: '' });
    const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
    const [productId, setProductId] = useState<string | undefined>(undefined);
    const [varietyId, setVarietyId] = useState<string | undefined>(undefined);
    const [selectedVariety, setSelectedVariety] = useState<{ id: string, name: string } | undefined>(undefined);

    const actionButtons: IButtonProps[] = [
        {
            label: `Batch Purchase${selected?.length > 1 ? 's' : ''} (${Number(batchedQuantity).toLocaleString()} kg)`,
            cssClass: "btn btn-primary ms-2",
            dataTarget: "#create-batch",
            dataToggle: "modal"
        },
        // {
        //     label: `Sell batch`,
        //     cssClass: "btn btn-success ms-2",
        //     dataTarget: "#sell-purchases",
        //     dataToggle: "modal"
        // },
        // {
        //     label: `Download CSV`,
        //     cssClass: "btn btn-secondary ms-2",
        //     dataTarget: "#download-csv",
        //     dataToggle: "modal"
        // }
    ]

    useEffect(() => {
        const items = InventoryInnerMenu({ activePage })
        setSubMenuItems(items)
    }, [activePage])

    const handleRowClick = (row: any) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(row.id);
            if (index > -1) {
                newSelected.splice(index, 1);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity - row.quantity
                })
            } else {
                newSelected.push(row.id);
                setBatchedQuantity(prevQuantity => {
                    return prevQuantity + row.quantity
                })

                setSelectedProduct({ id: row.productId, name: row.productName })
                setSelectedVariety({ id: row.varietyId, name: row.varietyName });
            }

            return newSelected
        })
    };

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                setBatchedQuantity(0)
                return [];
            } else {
                const totalQuantity = rows.reduce((accumulator: number, item) => accumulator + item.quantity, 0)
                setBatchedQuantity(totalQuantity)
                return rows.map(row => row.id);
            }
        })
    };

    useEffect(() => {
        setShowActionButtons(selected && selected.length > 0)
    }, [selected])

    const handleSelectId = (id: string, isVariety: boolean) => {
        setSelectedId(id)
        if (isVariety) {
            setVarietyId(id);
            setProductId(undefined);
        } else {
            setProductId(id);
            setVarietyId(undefined);
        }
    };

    useEffect(() => {
        fetchPurchases(page, pagination.pageSize);
    }, [varietyId, productId, page, searchTerm, location.pathname, urlParams.farmerId, currentUser?.hubId, selectedId, currentStatus]);

    const setLoading = (status: boolean) => {
        dispatch({
            type: INVENTORY_PURCHASES_CONSTANTS.LOADING_PURCHASES,
            payload: status
        })
    }

    const handleSectionClick = (section: PurchaseStatusType) => {
        setActiveSection(section);
    };

    useEffect(() => {
        switch (activeSection) {
            case PurchaseStatus.Batched:
                setCurrentStatus(true);
                break;
            case PurchaseStatus.Unbatched:
                setCurrentStatus(false);
                break;
            default:
                setCurrentStatus(null);
        }
    }, [activeSection]);

    const fetchPurchases = (page: number, pageSize: number) => {
        dispatch({
            type: INVENTORY_PURCHASES_CONSTANTS.LOADING_PURCHASES,
            payload: true
        });

        let url = '';

        let params: any = {page, pageSize, hubId: currentUser?.hubId,};

        if (currentStatus === true) {
            params.isBatched = true;
        } else if (currentStatus === false) {
            params.isBatched = false;
        }

        const isFarmersPage = location.pathname.includes('farmers');

        if (isFarmersPage) {
            params.farmerId = urlParams.farmerId
        }

        if (varietyId) {
            params.varietyId = selectedId;
        } else if (productId) {
            params.productId = selectedId;
        }

        if (searchTerm) {
            params.searchTerm = searchTerm
        }

        if (location.pathname.includes('/batches')) {
            url = buildUrl(remoteRoutes.inventoryService, '/batches/purchases', params);
        } else {
            url = buildUrl(remoteRoutes.inventoryService, '/purchases', params);
        }

        get(url,
            (response: IApiResponse) => {
                const { data, paginationMetadata } = response;
                setPagination(paginationMetadata);

                dispatch({
                    type: INVENTORY_PURCHASES_CONSTANTS.FETCH_PURCHASES,
                    payload: data
                });

                toast.success(toastMessages.default.success, overrideToastDefaults);
            },
            (error) => {
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => {
                dispatch({
                    type: INVENTORY_PURCHASES_CONSTANTS.STOP_FETCH,
                });
                setLoading(false);
            }
        );
    };

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
        setPage(1)
    }

    const formattedData = data.map((row: { totalPrice: number; quantity: number; date: string, unitName: string }) => ({
        ...row,
        date: printNaturalDateShort(row.date),
        unitPrice: row.quantity ? calculateUnitPrice(row.totalPrice, row.quantity) : 'N/A',
    }));

    const sectionStyle = (section: any) => ({
        borderLeft: '1px solid #D9D9D9',
        borderRight: '1px solid #D9D9D9',
        backgroundColor: activeSection === section ? '#D9D9D9' : 'transparent',
        color: activeSection === section ? '#1F5A2D' : 'inherit',
        fontWeight: activeSection === section ? 'bold' : 'normal',
        cursor: 'pointer',
        width: 'auto',
    });

    return (
        <>
            {showHeader && (
                <>
                    <div className={"py-3"}>
                        <InnerMenu module={Modules.Inventory}/>
                    </div>

                    <div className='bg-white mb-3 row'
                         style={{width: '100%', height: 35, margin: '0', border: '1px solid #D9D9D9', padding: '0'}}>
                        <div
                            className="d-flex bg-grey align-items-center justify-content-center h-100"
                            style={{marginLeft: 20, ...sectionStyle('All')}}
                            onClick={() => handleSectionClick(PurchaseStatus.All)}>
                            All
                        </div>
                        <div
                            className="d-flex bg-grey align-items-center justify-content-center h-100"
                            style={sectionStyle(PurchaseStatus.Unbatched)}
                            onClick={() => handleSectionClick(PurchaseStatus.Unbatched)}
                        >
                            Unbatched
                        </div>
                        <div
                            className="d-flex bg-grey align-items-center justify-content-center h-100 px-2"
                            style={sectionStyle(PurchaseStatus.Batched)}
                            onClick={() => handleSectionClick(PurchaseStatus.Batched)}
                        >
                            Batched
                        </div>
                    </div>
                </>
            )}

            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-25">
                        <input type="text" onKeyUp={(event) => handleSearch(event)}
                               className="form-control"
                               placeholder="Type to search..."/>
                        <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch}/>
                        </span>
                    </div>

                    <div className="action-buttons">
                        {showHeader &&
                            <>
                                {/* <button type="button"
                                    data-bs-toggle={"modal"}
                                    data-bs-target={"#import-purchases"}
                                    className="btn btn-sm btn-outline-dark ms-2">
                                    <FontAwesomeIcon icon={faFileImport} />
                                    <span className="ms-2">Import</span>
                                </button> */}

                                {!showActionButtons && activeSection === PurchaseStatus.Unbatched &&
                                    (<>
                                        <button type="button"
                                            data-bs-toggle={"modal"}
                                            data-bs-target={"#select-product"}
                                            className="btn btn-primary btn-sm ms-2">
                                            <span className="ms-2">Create a Batch</span>
                                        </button>
                                    </>)
                                }
                            </>
                        }

                        {showHeader && showActionButtons &&
                            actionButtons.map((button, index) => {
                                return (<button
                                    data-bs-toggle={button.dataToggle}
                                    data-bs-target={button.dataTarget}
                                    className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={button.onClick}>
                                    {button.processing ? 'Please wait...' : button.label}
                                </button>)
                            })
                        }

                    </div>
                </div>

                <XTable
                    selected={selected}
                    checked={selectedId !== undefined && activeSection === PurchaseStatus.Unbatched}
                    onSelectAll={(rows) => handleSelectAll(rows)}
                    onRowClick={(row) => {
                        if (selectedId !== undefined && activeSection === PurchaseStatus.Unbatched) {
                            handleRowClick(row)
                        }
                    }}
                    onCheckRow={(row) => {
                        if (selectedId !== undefined && activeSection === PurchaseStatus.Unbatched) {
                            handleRowClick(row)
                        }
                    }}
                    data={formattedData}
                    columns={PurchaseColumns}
                    loading={loading}
                />
                <XPagination
                    dataLength={data.length}
                    pagination={pagination}
                    currentPage={page}
                    setPage={(page) => setPage(page)} />

            </TableWrapper>

            <SelectProductForm onSelectId={handleSelectId} />
            <SellPurchasesForm purchases={selected} />
            <CreateBatchForm quantity={batchedQuantity} purchaseIds={selected} product={selectedProduct} variety={selectedVariety}
            />
            <DownloadCsvForm purchases={selected} />

            <ImportPurchasesForm />
        </>
    )
}