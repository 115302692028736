import React, {useEffect, useState} from "react";
import {IContactPerson, IHub, IProduct} from "../../../settings/interfaces/IHub";
import {ContactPeople} from "../../hubs/partials/_contactPeople";

type Props = {
    hub?: IHub
}

const HubDetails = ({hub}: Props) => {

    const contactPersons: IContactPerson[] = hub?.contactPersons || [];
    const products: IProduct[] = hub?.products || [];

    const contactPerson = contactPersons?.length
        ? contactPersons[0] : null;

    return (
        <>
            <div className="card shadow-sm border-0 py-3 px-3">
                <div className="card shadow-sm border-0 py-3 px-3">
                    <div className="row">

                        <div className="col-lg-4 mb-4">
                            <strong className="small">Phone Number</strong><br/>
                            <span>{hub?.phoneNumber}</span>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <strong className="small">Email</strong><br/>
                            <span>{hub?.email}</span>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <strong className="small">MAU</strong><br/>
                            <span>{hub?.minimumAggregationUnit ?? '-'}</span>
                        </div>

                        <div className="col-lg-4 mb-4">
                            <strong className="small">District</strong><br/>
                            <span>{hub?.district}</span>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <strong className="small">Sub-county</strong><br/>
                            <span>{hub?.subCounty}</span>
                        </div>
                    </div>

                    {/*<hr/>*/}


                </div>

                <ContactPeople contactPersons={contactPersons}/>

            </div>


        </>
    );
}

export default HubDetails;