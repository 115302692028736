import React, {createContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {HubConnectionState} from '@microsoft/signalr';
import {IState} from '../types'
import {FARMER_PROFILES_CONSTANTS} from "../redux/farmers/profilesReducer";

const WebSocketContext = createContext(null)
export {WebSocketContext}

interface IProps {
    children: any
}

export default ({children}: IProps) => {
    const userProfile = useSelector((state: IState) => state.core);
    const [connection, setConnection]: any = useState();
    const [profilesConnection, setProfilesConnection]: any = useState();

    let webSocket: any;
    const dispatch = useDispatch();

    const joinProfilesGroup = async (groupName: any) => {
        try {
            await profilesConnection.invoke('AddToGroup', groupName);
        } catch (error) {
            console.error(error);
        }
    };

    const removeFromGroup = async (groupName: any) => {
        try {
            await connection.invoke('RemoveFromGroup', groupName);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // const CRMConnection = new HubConnectionBuilder()
        //     .withUrl(remoteRoutes.webSocketsCRM, {
        //         accessTokenFactory: () => (
        //             getToken()
        //         )
        //     } as IHttpConnectionOptions)
        //     .withAutomaticReconnect()
        //     .build();
        //     setCrmConnection(CRMConnection);

    }, []);

    const startProfilesConnection = async () => {
        try {
            await profilesConnection.start();

            if (profilesConnection && profilesConnection.state === HubConnectionState.Connected) {
                joinProfilesGroup(userProfile.user.tenantid)
                profilesConnection.on('ReceiveLatestProfiles', (message: any) => {
                    dispatch({
                        type: FARMER_PROFILES_CONSTANTS.FETCH_PROFILES,
                        payload: message
                    });
                });
            }

        } catch (err) {
            // if (kycConnection && kycConnection.state === HubConnectionState.Disconnected) {
            //     console.log(err);
            //     setTimeout(() => startKycConnection(), 5000);
            // }
        }
    }

    if (userProfile.user === null) {
        profilesConnection && profilesConnection.connection.stopConnection()
    }
    webSocket = {
        connection
    }
    userProfile.user && startProfilesConnection();
    profilesConnection && profilesConnection.onclose(userProfile.user && startProfilesConnection);


    return (
        <WebSocketContext.Provider value={webSocket}>
            {children}
        </WebSocketContext.Provider>
    )
}