import {useDispatch, useSelector} from "react-redux";
import {Field, Formik} from "formik";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {IHub} from "../../settings/interfaces/IHub";
import {authService} from "../../../../data/oidc/AuthService";
import {remoteRoutes} from "../../../../data/constants";
import {useAuth} from "../../auth";
import {IBuyer} from "../interfaces/IBuyer";
import {buildUrl} from "../../../../utils/queryBuilder";
import {post} from "../../../../utils/ajax";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {BUYERS_CONSTANTS} from "../../../../data/redux/inventory/buyersReducer";
import {CloseModal} from "../../../../_theme/helpers/components/modalHelpers";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {XAutoComplete} from "../../../components/shared/forms/XAutoComplete";
import useFetchHubs from "../../../hooks/useFetchHubs";
import {IState} from "../../../../data/types";
import moment from "moment";

type Props = {
    batches: any[]
}

const SellBatchSymosForm = ({batches}: Props) => {
    const dispatch = useDispatch();
    const {currentUser} = useAuth();
    const hub: IHub | any = authService.getHub()
    const [buyers, setBuyers] = useState<IBuyer[]>([]);

    const {fetchHubs, pagination} = useFetchHubs()
    const {data, loading}: any = useSelector((state: IState) => state.hubs)

    useEffect(() => {
        fetchHubs(pagination.currentPage, pagination.pageSize, {})
    }, [pagination.currentPage])

    const buyerOptions = buyers.map((buyer: IBuyer) => ({
        label: buyer.name,
        id: buyer.id ?? ''
    }));

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        buyer: ''
    }

    const handleSubmit = (values: any, {resetForm, setSubmitting}: any) => {

        const url = buildUrl(remoteRoutes.inventoryService, `/purchases/sale`);
        const data = {
            date: moment().toISOString(),
            hubId: currentUser?.hubId,
            batchIds: batches,
            buyerId: values.buyer.id,
            buyerName: values.buyer.label
        }

        post(url, data, () => {

            CloseModal("sell-batch-symos", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: BUYERS_CONSTANTS.POST_BUYER,
                payload: data
            });

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, () => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };


    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            // validationSchema={SellBatchSchema}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, isSubmitting, values, setFieldValue}) => (
                <ModalWrapper
                    id="sell-batch-symos"
                    title="Select Buyer"
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">

                        <div className="col-lg-12">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                label="Buyer"
                                options={data?.map((buyer: any, index: number) => ({
                                    label: buyer?.name,
                                    id: buyer?.id
                                }))}
                                name="buyer"
                            />
                        </div>

                    </div>
                </ModalWrapper>
            )}
        </Formik>
    );
};

export default SellBatchSymosForm;