import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from "@mui/material";
import {ErrorMessage} from "formik";
import {IOption} from "./XAutoComplete";


type Props = {
    disabled?: boolean
    row: boolean
    options: IOption[]
    field: any
    form: any
    label: string
}

export const XRadioGroup = ({ row, disabled, field, form, options, label } : Props) => {
    return (
        <FormControl className="mb-3 bg-gray p-2 w-100 rounded-2" component="fieldset" error={form.errors[field.name] && form.touched[field.name]}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                style={{marginLeft: 10}}
                row={row}
                {...field}
                name={field.name}
                value={field.value}
                onChange={(event) => form.setFieldValue(field.name, event.target.value)}
            >
                {options.map((option) => (
                    <FormControlLabel
                        disabled={option.disabled || disabled}
                        className="x-radio-group"
                        style={{borderRadius: 6, paddingRight: 15, border: 'solid 1px #dddddd'}}
                        key={option.id}
                        value={option.id}
                        control={<Radio className="p-2" />}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
            <FormHelperText>
                <ErrorMessage name={field.name} />
            </FormHelperText>
        </FormControl>
    );
};