import { Formik, Field } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { IInputDistribution } from "../interfaces/IInputDistribution";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ICollectionCentre,
  IHub,
  IInput,
  IUnit,
} from "../../settings/interfaces/IHub";
import {
  IOption,
  XAutoComplete,
} from "../../../components/shared/forms/XAutoComplete";
import { authService } from "../../../../data/oidc/AuthService";
import { InputAdornment } from "@mui/material";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get, put } from "../../../../utils/ajax";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import toast from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { InputStockSchema } from "../schemas/InputStockSchema";

type Props = {
  initialValues: IInputDistribution | null;
};

const EditInputStockForm = ({ initialValues }: Props) => {
  const dispatch = useDispatch();

  const [modalKey, setModalKey] = useState(Date.now());
  const [inputs, setInputs] = useState<any[] | undefined>(undefined);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [locations, setLocations] = useState<any[] | undefined>(undefined);
  const [minQuantity, setMinQuantity] = useState<number | null>(null);
  const [quantityFieldDisabled, setQuantityFieldDisabled] = useState(false);

  const options: IOption[] = units
    .map((u) => ({
      id: u.id ?? "",
      label: u.displayName ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const [inputStock, setInputStock] = useState<IInputDistribution>({
    ...initialValues,
  });

  useEffect(() => {
    handleFetchData();

    if (initialValues) {
      setInputStock({
        ...initialValues,
      });
    }

    const hub: IHub | undefined = authService.getHub();

    if (hub) {
      const { collectionCentres } = hub;
      const sortedCollectionCentres = collectionCentres
        ?.map((center: ICollectionCentre) => ({
          label: center.name,
          id: center.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setLocations(sortedCollectionCentres);

      const { inputs } = hub;
      const sortedInputs = inputs
        ?.map((input: IInput) => ({
          id: input.id,
          label: input.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setInputs(sortedInputs);
    }
  }, [initialValues]);

  const handleFetchData = () => {
    if (remoteRoutes.onboardingService) {
      const url = buildUrl(remoteRoutes.onboardingService, "/units");
      get(
        url,
        (response: IApiResponse) => {
          const { data } = response;
          setUnits(data);
        },
        async (error) => {
          setQuantityFieldDisabled(true);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
        },
      );
    }

    if (initialValues?.id) {
      const url = buildUrl(
        remoteRoutes.inputsService,
        `/input/purchases/${initialValues?.id}/editable/min`,
      );

      get(
        url,
        (response: number) => {
          if (response === 0) {
            setMinQuantity(null);
          } else {
            setMinQuantity(response);
          }
        },
        async (error) => {},
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
        },
      );
    }
  };

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const data: IInputDistribution = {
      ...values,
      date: values.date.toISOString(),
      inputName: values.inputName.label,
      inputId: values.inputName.id,
      unitName: values.unitName.label,
      unitId: values.unitName.id,
      collectionCentreName: values.collectionCentreName.label,
      collectionCentreId: values.collectionCentreName.id,
    };

    const url = buildUrl(
      remoteRoutes.inputsService,
      `/input/purchases/${values.id}`,
    );

    put(
      url,
      data,
      (response) => {
        CloseModal("edit-input-stock", () => {
          setSubmitting(false);
          resetForm();
        });

        dispatch({
          type: INPUTS_DISTRIBUTION_CONSTANTS.UPDATE_DATA,
          payload: data,
        });
        setModalKey(Date.now());
        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  return (
    <Formik
      initialValues={{
        ...inputStock,
        date: dayjs(inputStock?.date),
        inputName: inputStock.inputName
          ? {
              id: inputStock.inputId,
              label: inputStock.inputName,
            }
          : null,
        unitName: inputStock.unitName
          ? {
              id: inputStock.unitId,
              label: inputStock.unitName,
            }
          : null,
        collectionCentreName: inputStock.collectionCentreName
          ? {
              id: inputStock.collectionCentreId,
              label: inputStock.collectionCentreName,
            }
          : null,
        quantity: inputStock.quantity || 0,
      }}
      validate={(values) => {
        const errors: any = {};
        if (minQuantity !== null && values.quantity < minQuantity) {
          errors.quantity = `Edited amount cannot go below ${minQuantity}. This avoids negative stock`;
        }
        return errors;
      }}
      onSubmit={handleSubmit}
      validationSchema={InputStockSchema}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
        return (
          <ModalWrapper
            id="edit-input-stock"
            title="Edit Input Stock Record"
            size={"lg"}
            submitting={isSubmitting}
            key={modalKey}
            handleSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XDatePicker}
                  label="Date"
                  name="date"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={inputs || []}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  values={values.inputName}
                  label="Input Name"
                  name="inputName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled={quantityFieldDisabled || isSubmitting}
                  as={XTextField}
                  label="Quantity"
                  name="quantity"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={options || []}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  values={values.unitName}
                  label="Inputs Unit"
                  name="unitName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label="Unit Price"
                  name="unitCost"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">UGX</InputAdornment>
                    ),
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const unitCost = Number(e.target.value);
                    setFieldValue("unitCost", unitCost);
                    setFieldValue(
                      "totalCost",
                      Number(values.quantity) * unitCost,
                    );
                  }}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled
                  as={XTextField}
                  label="Total Amount Paid"
                  name="totalCost"
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">UGX</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-lg-12">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={locations || []}
                  value={values.collectionCentreName}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  label="Collection Center"
                  name="collectionCentreName"
                />
              </div>
            </div>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default EditInputStockForm;
