import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { IState } from "../../../../../data/types";
import { useAuth } from "../../../auth";
import { IApiResponse, IPaginationMetadata } from "../../../../interfaces/IApiResponse";
import { remoteRoutes } from "../../../../../data/constants";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { PRODUCTS_CONSTANTS } from "../../../../../data/redux/settings/productsReducer";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { ICostCentre, IIdNamePair, IProcess } from "../../interfaces/IHub";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { get, put } from "../../../../../utils/ajax";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { ProcessSchema } from "../interfaces/IProcessSchema";

interface Props {
    initialValues: IProcess
}

const EditProcessForm = ({ initialValues }: Props) => {
    const products = useSelector((state: IState) => state.products.data);
    const costCenters = useSelector((state: IState) => state.settings.costCenters);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCostCentres, setSelectedCostCentres] = useState([]);

    const dispatch = useDispatch();
    const auth = useAuth();
    const currentUser = auth.currentUser;

    const productOptions = initialValues.products?.map(product => ({
        id: product.id ?? '',
        name: product.name,
    })).sort((a, b) => a.name.localeCompare(b.name));

    const defaultProducts = initialValues.products?.map((p) => ({
        id: p.id,
        name: p.name,
    }));

    const [modalKey, setModalKey] = useState(Date.now());

    useEffect(() => {
        handleFetchProducts()
        handleFetchCostCentres()
    }, [dispatch]);

    const handleFetchProducts = () => {
        if (remoteRoutes.onboardingService) {
            const url = buildUrl(remoteRoutes.onboardingService, '/products');

            get(url,
                (response: IApiResponse) => {
                    const { data, paginationMetadata } = response;

                    dispatch({
                        type: PRODUCTS_CONSTANTS.FETCH_DATA,
                        payload: data
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: PRODUCTS_CONSTANTS.STOP_FETCH });
                });
        }
    }
    const handleFetchCostCentres = () => {
        if (remoteRoutes.onboardingService) {
            const hubId = currentUser?.hubId;
            let params: any = { hubId };

            const url = buildUrl(remoteRoutes.onboardingService, '/cost/centres', params);
            get(url,
                (response: IApiResponse) => {
                    const { data, paginationMetadata } = response;

                    dispatch({
                        type: SETTINGS_CONSTANTS.FETCH_COST_CENTRES,
                        payload: data
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                });
        }
    }

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, `/processes/${values.id}`)
        const data: IProcess = {
            id: values.id,
            hubId: values.hubId,
            products: values.products,
            name: values.name,
            costCentres: values.costCentres,
            description: values.description
        }

        put(url, data, (response) => {

            CloseModal("edit-process", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.UPDATE_PROCESS,
                payload: data
            })
            toast.success(toastMessages.default.success, overrideToastDefaults)
            setSelectedProducts([])
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={{
                ...initialValues,
                products: defaultProducts
            }}
            onSubmit={handleSubmit}
            validationSchema={ProcessSchema}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting, touched, errors, values, setFieldValue }) => {
                return <ModalWrapper
                    id="edit-process"
                    title="Edit Process"
                    submitting={isSubmitting}
                    size="md"
                    key={modalKey}
                    handleSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>

                        <div className="col-lg-12 mb-4">
                            <Field name="products">
                                {() => (
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option) => option.name}
                                        options={products}
                                        onChange={(event, value) => {
                                            setFieldValue('products', value.map(prod => ({
                                                id: prod.id,
                                                name: prod.name
                                            })));
                                        }}

                                        value={values.products}

                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select products"
                                                variant="standard"
                                                error={touched.products && Boolean(errors.products)}
                                            />
                                        )} />
                                )}
                            </Field>
                        </div>

                        <div className="col-lg-12">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Description"
                                name="description"
                            />
                        </div>

                        {/* <div className="col-lg-12 mb-4">
                            <Field name="costCentres">
                                {() => (
                                    <Autocomplete
                                        multiple
                                        getOptionLabel={(option: ICostCentre) => option.name}
                                        options={costCenters.data}
                                        onChange={(event, value) => {
                                            setFieldValue('costCentres', value.map(prod => ({
                                                id: prod.id,
                                                name: prod.name,
                                                description: prod.description
                                            })));
                                        }}
                                        value={values.costCentres}

                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.name}
                                            </li>
                                        )}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select cost centres"
                                                variant="standard"
                                                error={touched.costCentres && Boolean(errors.costCentres)}
                                            // helperText={touched.costCentres && errors.costCentres ? errors.costCentres : ''}
                                            />
                                        )} />
                                )}
                            </Field>
                        </div> */}

                    </div>

                </ModalWrapper>

            }}
        </Formik>
    );
}

export default EditProcessForm;