import * as Yup from "yup";

export interface IHubDto {
    name: string,
    email: string,
    phoneNumber: string,
    district?: string,
    parentHubId?: string,
    subCounty?: string,
    village?: string,
    postalAddress?: string,
    minimumAggregationUnit?: string,
    id?: string
}

const phoneValidationSchema = Yup.string()
    .matches(/^\d{9}$/, 'Phone number must be exactly 9 digits')
    .required('Phone number is required.');

export const HubSchema = Yup.object({
    name: Yup.string().nullable().required('Required'),
    district: Yup.object().nullable().required('Required'),
    phoneNumber: phoneValidationSchema,
    email: Yup.string().nullable().email('Invalid email')
})