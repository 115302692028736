import {Outlet, Route, Routes} from "react-router-dom";
import Clients from "./Clients";
import ClientDetailsPage from "./ClientDetailsPage";

const ClientsPage = () => {
    return (
        <Routes>
            <Route element={<Outlet/>}>
                <Route index element={<Clients/>}/>
                <Route path=':hubId'
                       element={
                           <>
                               <ClientDetailsPage/>
                           </>}/>
            </Route>
        </Routes>
    )
}

export default ClientsPage;