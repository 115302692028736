import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { Field, Formik } from "formik";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { get, put } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { IdName, IHubInput, InputSchema } from "../interfaces/IInput";
import { useAuth } from "../../../auth";
import { useDispatch, useSelector } from "react-redux";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import { IInputCategory, IUnit } from "../../interfaces/IHub";
import { IState } from "../../../../../data/types";
import { IApiResponse, IPaginationMetadata } from "../../../../interfaces/IApiResponse";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
    initialValues: IHubInput
}

const EditInputForm = ({ initialValues }: Props) => {

    const auth = useAuth();
    const currentUser = auth?.currentUser;
    const dispatch = useDispatch();

    const [modalKey, setModalKey] = useState(Date.now());

    const units = useSelector((state: IState) => state.settings.units.data);

    const unitOptions = units?.map(u => ({
        id: u.id ?? '',
        name: u.name
    }));
    const inputCategories = auth.categories?.inputCategories;
    const categories = inputCategories?.map((category: IInputCategory) => ({
        label: category.name,
        id: category.number
    }))

    const defaultCat = categories?.find(c => c.id.toString() == initialValues.category) ?? {
        label: 'Conventional',
        id: 1
    };

    const defaultUnits = initialValues.units?.map(u => ({
        id: u.id,
        name: u.name
    }));

    useEffect(() => {
        handleFetchData()
    }, [dispatch]);

    const handleFetchData = () => {
        if (remoteRoutes.onboardingService) {
            const url = buildUrl(remoteRoutes.onboardingService, '/units');

            get(url,
                (response: IApiResponse) => {
                    const { data, paginationMetadata } = response;

                    dispatch({
                        type: SETTINGS_CONSTANTS.FETCH_UNITS,
                        payload: data
                    });

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                });
        }
    }

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {

        const url = buildUrl(remoteRoutes.onboardingService, `/inputs/${values.id}`)
        const data: IHubInput = {
            id: values.id,
            name: values.name,
            hubId: values.hubId,
            category: values.category.id,
            units: values.units,
            otherInputCategory: values.otherInputCategory,
            description: values.description,
        }

        put(url, data, (response) => {

            CloseModal("edit-input", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.UPDATE_INPUT,
                payload: data
            })

            toast.success(toastMessages.default.success, overrideToastDefaults)
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <>
            <Formik
                initialValues={{ ...initialValues, category: defaultCat, units: defaultUnits }}
                enableReinitialize={true}
                validationSchema={InputSchema}
                onSubmit={handleSubmit}>

                {({ handleSubmit, isSubmitting, touched, errors, values, setFieldValue }) => {
                    return <ModalWrapper
                        id="edit-input"
                        title="Edit Input"
                        size="md"
                        key={modalKey}
                        submitting={isSubmitting}
                        handleSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Name"
                                    name="name"
                                />
                            </div>

                            <div className="col-lg-6">
                                <Field
                                    disabled={isSubmitting}
                                    component={XAutoComplete}
                                    label="Category"
                                    options={categories}
                                    value={values.category}
                                    name="category"
                                />
                            </div>

                            {values.category && (values.category as any).label === 'Others' && (
                                <div className="col-lg-12">
                                    <Field
                                        type={"string"}
                                        disabled={isSubmitting}
                                        as={XTextField}
                                        label="Other Input Category"
                                        name="otherInputCategory"
                                    />
                                </div>
                            )}

                            <div className="col-lg-12">
                                <Field
                                    type={"string"}
                                    disabled={isSubmitting}
                                    as={XTextField}
                                    label="Description"
                                    name="description"
                                />
                            </div>


                            <div className="col-lg-12">
                                <Field name="units">
                                    {() => (
                                        <Autocomplete
                                            multiple
                                            getOptionLabel={(option: IdName) => option.name}
                                            options={unitOptions || []}
                                            onChange={(event, value) => {
                                                setFieldValue('units', value.map(prod => ({
                                                    id: prod.id,
                                                    name: prod.name
                                                })));
                                            }}

                                            value={values.units}

                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select units"
                                                    variant="standard"
                                                    error={touched.units && Boolean(errors.units)}
                                                // helperText={touched.units && errors.units ? errors.units : ''}
                                                />
                                            )} />
                                    )}
                                </Field>
                            </div>


                        </div>

                    </ModalWrapper>
                }}

            </Formik>

        </>
    );
}

export default EditInputForm;