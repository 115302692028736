import { FC, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { AuthPage, Logout, useAuth } from '../modules/auth'
import App from '../App'
import Callback from "../modules/auth/Callback";
import { authService } from "../../data/oidc/AuthService";
import { IsAgent, IsGlobalAdmin, IsHubAdmin, IsBatchDataAdmin } from "../hooks/roleChecker";
import { CheckCompliance } from "../modules/compliance/CheckCompliance";
import CompliancePage from "../modules/compliance/CompliancePage";
import SignoutCallback from "../modules/auth/SignoutCallback";

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
    const { currentUser } = useAuth()
    const roles = authService.getRoles();
    const isGlobalAdmin = IsGlobalAdmin(roles);
    const isHubAdmin = IsHubAdmin(roles);
    const isAgent = IsAgent(roles);
    const isBatchDataAdmin = IsBatchDataAdmin(roles);


    const getLandingPage = () => {
        if (isGlobalAdmin) return '/clients';
        if (isBatchDataAdmin) return '/search';
        // if (isHubAdmin) return '/hubs';
        // if (isAgent) return '/farmers';
        return '/inventory/stock'; // default landing page if no role matches
    };


    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                    <Route path='compliance/*' element={<CompliancePage />} />
                    <Route path='error/*' element={<ErrorsPage />} />
                    <Route path='logout' element={<Logout />} />
                    <Route path="signout-callback" element={<SignoutCallback />} />
                    {currentUser ? (
                        <>
                            <Route path='/*' element={<PrivateRoutes />} />
                            {/*<Route index element={<Navigate to='/dashboard'/>}/>*/}
                            <Route index element={<Navigate to={getLandingPage()} />} />
                        </>
                    ) : (
                        <>
                            <Route path={'callback'} element={<Callback />} />
                            <Route path='auth/*' element={<AuthPage />} />
                            <Route path='*' element={<Navigate to='/auth' />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export { AppRoutes }
