import {useDispatch} from "react-redux";
import {Field, Formik} from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {ModalWrapper} from "../../../components/shared/ModalWrapper";
import {XTextField} from "../../../components/shared/forms/XTextField";
import {useEffect} from "react";
import {OpenModal} from "../../../../_theme/helpers/components/modalHelpers";

const validationSchema = Yup.object({
    securityCode: Yup.string().nullable().required('Required')
})

type IProps = {
    handleSubmit: (values: any) => void
    showError?: boolean
}

export const SecurityCodeModal = (props: IProps) => {

    const initialValues = {
        securityCode: '',
    }

    useEffect(() => {
        OpenModal('security-code')
    }, [])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={props.handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="security-code"
                    title={`Security Code`}
                    size="md"
                    position="centered"
                    dismissable={false}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-lg-12">
                            <p>Enter the the 6-digit security code that you received with the compliance certificate.</p>

                            <Field
                                type={"password"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Enter the security code"
                                helperText={""}
                                name="securityCode"
                            />
                        </div>

                        {props.showError && <div className="col-lg-12">
                            <div className="alert alert-danger">
                                Invalid security code. Access to the report has been denied.
                            </div>
                        </div>}

                    </div>

                </ModalWrapper>
            }}

        </Formik>
    );
}