import React from 'react';
import {Skeleton} from "@mui/material";

export const TableFilterLoader = () => {
    return (
        <>
            <div className="card-title">
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-25">
                        <Skeleton variant={"text"} width={"100%"} height={50} />
                    </div>

                    <div className="d-flex w-75 justify-content-end align-items-center">
                        <Skeleton className={"ms-2 rounded-2"} variant="rectangular" width={100} height={40} />
                        <Skeleton className={"ms-2 rounded-2"} variant="rectangular" width={40} height={40} />
                    </div>
                </div>
            </div>
        </>
    );
}