import {Field, Form, Formik} from "formik";
import React from "react";
import {XAutoComplete} from "../../../components/shared/forms/XAutoComplete";
import {districts} from "../../../../data/districts";
import {XTextField} from "../../../components/shared/forms/XTextField";
import {authService} from "../../../../data/oidc/AuthService";

type Props = {
    onApplyFilters: (filters: any) => void,
    setFilters: (filters: any) => void,
};

const FarmerProfilesFilterForm: React.FC<Props> = ({onApplyFilters, setFilters}) => {

    const roles = authService.getRoles();

    const initialValues = {
        hub: '',
        gender: { label: '', value: '' },
        district: {label: '', id: ''},
        subCounty: '',
        parish: '',
        village: '',
    };

    const handleSubmit = (values: any) => {
        const filters = {
            ...values,
            gender: values.gender.value,
            district: values.district.label,
        };
        onApplyFilters(filters);
    };

    const gendersCollection = [
        {label: "Female", value: 1},
        {label: "Male", value: 2},
        {label: "Unwilling", value: 3},
        {label: "Unknown", value: 4},
    ];

    const handleClearForm = (resetForm: any) => {
        resetForm()
        setFilters({})
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({resetForm, values, dirty}) => {
                    const isFormEmpty = Object.values(values).every(
                        value => value === '' || value === null || (typeof value === 'object' && value.label === '')
                    );

                    return (
                        <Form>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Field
                                        type="string"
                                        component={XAutoComplete}
                                        label="Gender"
                                        options={gendersCollection}
                                        name="gender"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <Field
                                        type="string"
                                        component={XAutoComplete}
                                        options={districts}
                                        label="District"
                                        name="district"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <Field
                                        type="string"
                                        as={XTextField}
                                        label="Sub-county"
                                        name="subCounty"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <Field
                                        type="string"
                                        as={XTextField}
                                        label="Parish"
                                        name="parish"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <Field
                                        type="string"
                                        as={XTextField}
                                        label="Village"
                                        name="village"
                                    />
                                </div>
                                <div
                                    className="border-top mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                                    <button type="submit"
                                            className="btn btn-sm btn-primary ms-2"
                                            disabled={!dirty || isFormEmpty}>
                                        Apply
                                    </button>
                                    <button type="button"
                                            title={"Clear Filters"}
                                            onClick={() => handleClearForm(resetForm)}
                                            disabled={!dirty || isFormEmpty}
                                            className="btn btn-sm btn-outline-secondary ms-2">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default FarmerProfilesFilterForm;
