import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import { IInputMovement } from "../interfaces/IInputMovement";
import {
  ICollectionCentre,
  IHub,
  IInput,
} from "../../settings/interfaces/IHub";
import { IdName } from "../../settings/inputs/interfaces/IInput";
import dayjs from "dayjs";
import { authService } from "../../../../data/oidc/AuthService";
import { XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import toast from "react-hot-toast";
import { get, put } from "../../../../utils/ajax";
import { remoteRoutes } from "../../../../data/constants";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import {
  toastMessages,
  overrideToastDefaults,
} from "../../../../data/toastDefaults";
import { buildUrl } from "../../../../utils/queryBuilder";
import { useDispatch } from "react-redux";
import { SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";

type Props = {
  selectedRow?: IInputMovement | null;
  selectedInputDetailsId: string | null;
};

const EditInputsMovementForm = ({
  selectedRow,
  selectedInputDetailsId,
}: Props) => {
  const dispatch = useDispatch();
  const [modalKey, setModalKey] = useState(Date.now());

  const [inputs, setInputs] = useState<any[] | undefined>(undefined);
  const [locations, setLocations] = useState<any[] | undefined>(undefined);
  const [maxQuantity, setMaxQuantity] = useState<number | null>(null);
  const [quantityFieldDisabled, setQuantityFieldDisabled] = useState(false);

  const [inputMovement, setInputMovement] = useState<IInputMovement>({
    ...selectedRow,
    id: selectedRow?.id,
    inputName: selectedRow?.inputName,
    inputId: selectedRow?.inputId,
    unitName: [] as IdName[],
  });

  useEffect(() => {
    handleFetchData();

    if (selectedRow) {
      setInputMovement({
        ...selectedRow,
      });
    }

    const hub: IHub | undefined = authService.getHub();

    if (hub) {
      const { collectionCentres } = hub;
      const sortedCollectionCentres = collectionCentres
        ?.map((center: ICollectionCentre) => ({
          label: center.name,
          id: center.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setLocations(sortedCollectionCentres);

      const { inputs } = hub;
      const sortedInputs = inputs
        ?.map((input: IInput) => ({
          id: input.id,
          label: input.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setInputs(sortedInputs);
    }
  }, [selectedRow]);

  const handleFetchData = () => {
    if (selectedRow?.id) {
      const url = buildUrl(
        remoteRoutes.inputsService,
        `/input/movements/${selectedRow.id}/editable/max`,
      );
      get(
        url,
        (response: number) => {
          if (response === 0) {
            setMaxQuantity(null);
          } else {
            setMaxQuantity(response);
          }
        },
        async (error) => {
          // toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
        },
      );
    }
  };

  const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
    const data: IInputMovement = {
      ...values,
      date: values.date.toISOString(),
      inputName: values.inputName.label,
      inputId: values.inputName.id,
      destinationCollectionCentreName:
        values.destinationCollectionCentreName.label,
      destinationCollectionCentreId: values.destinationCollectionCentreName.id,
      sourceCollectionCentreName: values.sourceCollectionCentreName.label,
      sourceCollectionCentreId: values.sourceCollectionCentreName.id,
      unitName: "Pieces",
      unitId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    };

    const url = buildUrl(
      remoteRoutes.inputsService,
      `/input/movements/${values.id}`,
    );

    put(
      url,
      data,
      (response) => {
        CloseModal("edit-input-movement", () => {
          setSubmitting(false);
          resetForm();
        });

        dispatch({
          type: INPUTS_DISTRIBUTION_CONSTANTS.UPDATE_DATA,
          payload: data,
        });
        setModalKey(Date.now());
        toast.success(toastMessages.default.success, overrideToastDefaults);
      },
      (error) => {
        setSubmitting(false);
        toast.error(toastMessages.default.fail, overrideToastDefaults);
      },
      () => {},
    );
  };

  return (
    <Formik
      initialValues={{
        ...inputMovement,
        date: dayjs(inputMovement?.date),
        inputName: inputMovement.inputName
          ? {
              id: inputMovement.inputId,
              label: inputMovement.inputName,
            }
          : null,
        unitName: inputMovement.unitName
          ? {
              id: inputMovement.unitId,
              label: inputMovement.unitName,
            }
          : null,
        sourceCollectionCentreName: inputMovement.sourceCollectionCentreName
          ? {
              id: inputMovement.sourceCollectionCentreId,
              label: inputMovement.sourceCollectionCentreName,
            }
          : null,
        destinationCollectionCentreName:
          inputMovement.destinationCollectionCentreName
            ? {
                id: inputMovement.destinationCollectionCentreId,
                label: inputMovement.destinationCollectionCentreName,
              }
            : null,
        quantity: inputMovement.quantity || 0,
      }}
      validate={(values) => {
        const errors: any = {};
        if (
          values.quantity &&
          maxQuantity !== null &&
          values.quantity > maxQuantity
        ) {
          errors.quantity = `Edited amount cannot go above ${maxQuantity}. This avoids negative stock`;
        }
        return errors;
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
        return (
          <ModalWrapper
            id="edit-input-movement"
            title="Edit Input Movement"
            size={"lg"}
            submitting={isSubmitting}
            key={modalKey}
            handleSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XDatePicker}
                  label="Date"
                  name="date"
                  maxDate={dayjs(new Date())}
                  minDate={dayjs(selectedRow?.date)}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label="Movement Number"
                  name="movementNumber"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={inputs || []}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  values={values.inputName}
                  label="Input Name"
                  name="inputName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"number"}
                  disabled={quantityFieldDisabled || isSubmitting}
                  as={XTextField}
                  label="Quantity"
                  name="quantity"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={locations || []}
                  value={values.sourceCollectionCentreName}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  label="Origin / Source"
                  name="sourceCollectionCentreName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  component={XAutoComplete}
                  options={locations || []}
                  value={values.destinationCollectionCentreName}
                  getOptionLabel={(option: any) => (option ? option.label : "")}
                  label="Destination"
                  name="destinationCollectionCentreName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label="Agent"
                  name="sourceAgentName"
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  disabled={isSubmitting}
                  as={XTextField}
                  label="Movers Number"
                  name="vehicleNumber"
                />
              </div>
            </div>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default EditInputsMovementForm;
