import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { INVENTORY_BATCHES_CONSTANTS } from "../../data/redux/inventory/batchesReducer";
import { overrideToastDefaults, toastMessages } from "../../data/toastDefaults";
import { IApiResponse, IPaginationMetadata } from "../interfaces/IApiResponse";
import { search } from "../../utils/ajax";
import { remoteRoutes } from "../../data/constants";
import { useAuth } from "../modules/auth";

const useFetchBatches = () => {
    const dispatch = useDispatch();

    const { currentUser } = useAuth()
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const fetchBatches = useCallback((page: number, pageSize: number, searchParams: any) => {
        dispatch({
            type: INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES,
            payload: true
        });

        const id = searchParams?.searchTerm;
        const hubId = searchParams?.hubId;

        const url = `${remoteRoutes.inventoryService}/batches/purchases`;
        const params: any = { page, pageSize, id, hubId };

        search(url, params,
            (response: IApiResponse) => {
                const { data, paginationMetadata } = response;

                if (!data || data.length === 0) {
                    toast.error('Batch not found. Please check the ID and try again.', overrideToastDefaults);

                    dispatch({
                        type: INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES,
                        payload: []
                    });

                    setPagination({
                        pageSize,
                        hasNextPage: false,
                        currentPage: page,
                        hasPreviousPage: false,
                        totalItems: 0,
                        totalPages: 0
                    });

                    dispatch({
                        type: INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES,
                        payload: false
                    });

                    return;
                }

                setPagination(paginationMetadata);

                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES,
                    payload: data
                });

                toast.success(toastMessages.default.success, overrideToastDefaults);

                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES,
                    payload: false
                });
            },
            (error) => {
                toast.error('Failed to fetch batches. Please try again later.', overrideToastDefaults);

                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES,
                    payload: []
                });

                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES,
                    payload: false
                });
            }
        );
    }, [currentUser, dispatch]);


    return {
        fetchBatches,
        pagination
    };
}

export default useFetchBatches;