import React, {useEffect} from 'react';
import {UserManager, WebStorageStateStore} from 'oidc-client';
import {useNavigate} from "react-router-dom";

const Callback = () => {

    const navigate = useNavigate()

    useEffect(() => {
        new UserManager({
            response_type: 'code',
            userStore: new WebStorageStateStore({store: window.localStorage}),
            loadUserInfo: true,
            filterProtocolClaims: true,
        }).signinRedirectCallback()
            .then(() => {
                const redirectUrl = '/';
                navigate(redirectUrl);
                alert("Logged in")
            })
            .catch(e => {
                console.error(e);
            });
    }, [navigate]);

    return (
        <div className="auth-container">
            <noscript>You need to enable JavaScript to run this app.</noscript>
            <main className="main-content">
                <div className="paper">
                    <div className="grid-container">
                        <div className="grid-item">
                            <div>
                                <p className="typography-body2">Validating login credentials.</p>
                                <div className="progress-bar">
                                    <div className="linear-progress-root" role="progressbar">
                                        <div className="linear-progress-bar1"></div>
                                        <div className="linear-progress-bar2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

const styles = {
    html: {
        margin: 0,
        border: 0,
        padding: 0,
        width: '100%',
        letterSpacing: '-0.04px',
        backgroundColor: '#000',
    },
    jss116: {
        width: '100%',
        margin: '0 auto',
    },
    MuiPaperRoot: {
        color: '#263238',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#000',
    },
    jss117: {
        width: '670px',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '200px',
        display: 'flex',
        padding: '48px',
        boxShadow: 'none',
        marginTop: 'calc(100vh - 70vh)',
        alignItems: 'center',
    },
    MuiGridContainer: {
        width: 'calc(100% + 24px)',
        textAlign: 'center',
    },
    MuiGridItem: {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
    },
    MuiTypographyBody2: {
        color: '#bdbdbd',
        fontSize: '12px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: '1px',
        letterSpacing: '-0.04px',
        marginBottom: '35px',
        marginTop: '-5px',
    },
    progressBar: {
        marginBottom: '30px',
    },
    MuiLinearProgressRoot: {
        height: '4px',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#2e2e2e',
    },
    MuiLinearProgressIndeterminate: {
        width: 'auto',
        animation: 'MuiLinearProgress-keyframes-indeterminate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite',
    },
    MuiLinearProgressBar: {
        top: 0,
        left: 0,
        width: '100%',
        bottom: 0,
        position: 'absolute',
        transition: 'transform 0.2s linear',
        transformOrigin: 'left',
    },
    MuiLinearProgressBar1Indeterminate: {
        animation: 'MuiLinearProgress-keyframes-indeterminate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite',
    },
    MuiLinearProgressBar2Indeterminate: {
        animation: 'MuiLinearProgress-keyframes-indeterminate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite',
    },
};

export default Callback;
