export const buildUrl = (baseUrl, endpoint, params) => {
    if (typeof params !== 'object' || params === null) {
        return baseUrl.replace(/\/$/, "") + endpoint
    }

    const queryString = Object.keys(params)
        .map(key => {
            const value = params[key];
            if (value === undefined) return '';
            if (Array.isArray(value)) {
                return value.map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .filter(Boolean) // remove empty strings
        .join('&');

    return baseUrl.replace(/\/$/, "") + endpoint + (queryString ? `?${queryString}` : '')
}