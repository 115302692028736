import {ITrainingFacilitator} from "../../../app/modules/training/interfaces/ITraining";

export const TRAINING_FACILITATORS_CONSTANTS = {
    STOP_FETCH: "STOP_FETCH",
    FETCH_DATA: "FETCH_DATA",
    POST_DATA: "POST_DATA",
    GET_DETAILS: "GET_DETAILS",
    LOADING_DATA: "LOADING_DATA",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    FILTER_DATA: "FILTER_DATA",
    RESET_FILTER: "RESET_FILTER",
    PAGINATION: "PAGINATION",
}

export interface ITrainingFacilitatorsState {
    loading: boolean
    data: ITrainingFacilitator[]
    filter: {
        show: boolean,
        data: ITrainingFacilitator[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: ITrainingFacilitatorsState = {
    loading: false,
    data: [],
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function trainingFacilitatorsReducer(state = initialState, action: any) {
    switch (action.type) {

        case TRAINING_FACILITATORS_CONSTANTS.FILTER_DATA:
            return {...state, ...action.payload, show: !state.filter.show}

        case TRAINING_FACILITATORS_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return {...initialState, reset: true}

        case TRAINING_FACILITATORS_CONSTANTS.SET_SORT_PARAMS:
            return {...state, ...action.payload}

        case TRAINING_FACILITATORS_CONSTANTS.PAGINATION:
            return {...state, ...action.payload}

        case TRAINING_FACILITATORS_CONSTANTS.FETCH_DATA: {
            return {...state, loading: false, data: action.payload, reset: false}
        }

        case TRAINING_FACILITATORS_CONSTANTS.POST_DATA: {
            const trainingFacilitator: ITrainingFacilitator = action.payload
            return {...state, data: [trainingFacilitator, ...state.data]}
        }

        case TRAINING_FACILITATORS_CONSTANTS.LOADING_DATA: {
            return {...state, loading: action.payload}
        }

        case TRAINING_FACILITATORS_CONSTANTS.GET_DETAILS: {
            return {...state, requestDetails: action.payload}
        }

        case TRAINING_FACILITATORS_CONSTANTS.STOP_FETCH: {
            return {...state, loading: false}
        }

        default: {
            return state
        }
    }
}