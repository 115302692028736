import {InputProps, TextField} from "@mui/material";
import {useField} from "formik";

type Props = {
    label: string
    placeholder?: string
    name: string
    defaultValue?: string
    disabled: boolean
    type: string
    inputProps?: InputProps
    multiline?: boolean
    helperText?: string
}

export const XTextField = ({label, helperText, type="text", disabled = false, inputProps, ...props}: Props) => {

    const [field, meta] = useField(props);
    return (
        <TextField
            {...field}
            {...props}
            sx={{
                '& label.Mui-focused': {
                    color: '#1C5838'
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                        borderColor: '#1C5838'
                    }
                }
            }}
            label={label}
            type={type}
            size={"medium"}
            disabled={disabled}
            className="form-control mb-4"
            variant={"standard"}
            InputProps={inputProps}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error || helperText}
        />
    );
}