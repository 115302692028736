import {
    faArrowRight,
    faBook,
    faShoppingCart,
    faTruck
} from "@fortawesome/free-solid-svg-icons";
import { IMenuItem } from "../../../utils/MainMenu";

interface MainMenuProps {
    activePage: string;
}

export const InputsInnerMenu = ({ activePage }: MainMenuProps): IMenuItem[] => [
    {
        label: "Purchases",
        path: '/inputs/purchases',
        isActive: activePage.toLowerCase() === '/inputs' || activePage.toLowerCase().split("/").includes('purchases'),
        icon: faShoppingCart
    },
    {
        label: "Stock",
        path: '/inputs/stock',
        isActive: activePage.toLowerCase().split("/").includes('stock'),
        icon: faBook
    },
    {
        label: "Sales",
        path: '/inputs/sales',
        isActive: activePage.toLowerCase().split("/").includes('sales'),
        icon: faArrowRight
    },
    {
        label: "Movements",
        path: '/inputs/movements',
        isActive: activePage.toLowerCase().split("/").includes('movements'),
        icon: faTruck
    }
]