import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Skeleton} from "@mui/material";


export const PageHeaderLoader = () => {
    return (
        <div className="d-flex w-100 py-3 justify-content-between align-items-center">

            <div className="d-flex justify-content-start align-items-center w-75">
                <FontAwesomeIcon className="me-3" icon={faArrowLeft} />
                <Skeleton width={200} className="me-3" height={30} variant={"text"}/>
                <Skeleton width={100} height={30} variant={"text"}/>
            </div>

            <div className="d-flex justify-content-end align-items-center w-50">
                <Skeleton width={100} height={50} className="ms-2" style={{borderRadius: 20}} variant={"text"}/>
                <Skeleton width={100} height={50} className="ms-2" style={{borderRadius: 20}} variant={"text"}/>
                <Skeleton width={100} height={50} className="ms-2" style={{borderRadius: 20}} variant={"text"}/>
                <Skeleton width={100} height={50} className="ms-2" style={{borderRadius: 20}} variant={"text"}/>
                <Skeleton width={100} height={50} className="ms-2" style={{borderRadius: 20}} variant={"text"}/>
            </div>
        </div>
    );
}