import { Formik } from "formik";
import { useState } from "react";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import * as XLSX from 'xlsx'

type Props = {
    purchases?: any
}

export const DownloadBatchReport = ({ purchases }: Props) => {

    const [modalKey, setModalKey] = useState(Date.now());

    const handleSubmit = async (resetForm: any, setSubmitting: any) => {
        try {
            const selectedDataForExcel = purchases.map((row: { id: any; productName: any; date: any; quantity: any; totalPrice: any; plotCode: any; unitPrice: any; unitName: any; currency: any; varietyName: any }) => ({
                'Date of Purchase': row.date,
                'Purchase ID': row.id,
                'Product Name': row.productName,
                'Variety': row.varietyName,
                'Plot Code': row.plotCode,
                Quantity: `${row.quantity} ${row.unitName}`,
                'Unit Price': `${row.currency} ${row.unitPrice}`,
                'Total Price': `${row.currency} ${row.totalPrice}`,
            }));

            const worksheet = XLSX.utils.json_to_sheet(selectedDataForExcel);

            const calculateColumnWidths = (data: any[]) => {
                const columnWidths = Object.keys(data[0]).map((key) => {
                    const maxLength = Math.max(
                        key.length,
                        ...data.map((row) => (row[key] ? row[key].toString().length : 0))
                    );
                    return { wch: maxLength + 2 };
                });
                return columnWidths;
            };

            worksheet['!cols'] = calculateColumnWidths(selectedDataForExcel);

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchases');

            const now = new Date();
            const timestamp = now.toISOString().replace(/[-T:.Z]/g, '').slice(0, 14); // Format: YYYYMMDDHHMMSS

            XLSX.writeFile(workbook, `symos-purchases-report-${timestamp}.xlsx`);

            CloseModal("download-batch-report", () => {
                resetForm();
            });

        } catch (error) {
            console.error('Error generating Excel file:', error);
        } finally {
            setSubmitting(false);
            setModalKey(Date.now());
        }
    };

    return (
        <Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting }) => (
                <ModalWrapper
                    id="download-batch-report"
                    title="Confirmation"
                    size="md"
                    position="top"
                    key={modalKey}
                    submitBtnLabel="Download"
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-lg-12">
                            An excel sheet will be generated and downloaded
                        </div>
                    </div>
                </ModalWrapper>
            )}
        </Formik>
    );
};