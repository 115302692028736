export const positions = {
    topLeft: 'top-left',
    bottomLeft: 'bottom-left',
    bottomRight: 'bottom-right',
    topCenter: 'top-center'
}

const defPosition = positions.bottomLeft

export const overrideToastDefaults: any = {
    duration: 4000,
    position: defPosition,

    style: {
        borderRadius: '50px',
        padding: '14px 20px'
    },
    className: '',
    escapeHtml: false,
    enableHtml: true
}

export const overrideToastDownload: any = {
    duration: 5000,
    position: defPosition,

    style: {
        borderRadius: '50px',
        padding: '14px 20px'
    },
    className: '',
}

export const toastMessages = {
    'default': {
        'success': 'Operation successful',
        'fail': 'Operation failed. Try reloading the page'
    },
    'Login': {
        'NetworkError': 'User authentication failed',
        'inValidAccount': 'Invalid account. Please contact the systems administrator',
    },
    'subscription': {
        'new': 'New subscription has been added',
        'activated': 'Subscription has been activated',
        'deactivate': 'Subscription has been deactivated'
    },
    'generateExport': {
        'fail': 'Failed to download export',
        'inProgress': 'Generating export, please wait...',
        'success': 'Export successfully downloaded'
    },
    'markAsReviewed': {
        'fail': 'Failed to update application. Please refresh and try again',
        'success': 'Successfully marked application as reviewed'
    },
    'documentsDownload': {
        'fail': 'Failed to download company document. Please refresh and try again',
        'loading': 'Loading document. Please wait...',
        'downloading': 'Document download initiated. Please wait...',
        'success': 'Successfully downloaded selected document',
        'noDownloadLink': 'Looks like the search is missing a Registration number. Make a new search',
    },
    'deleteSuccessful': {
        'fail': 'Failed to delete item',
        'success': 'Successfully deleted item',
    }
}