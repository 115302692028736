import {Field, Formik, useField} from "formik";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import {IContactPerson} from "../../interfaces/IHub";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {del, post} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";

interface Props {
    item: IContactPerson
}

const DeleteContactPersonForm = ({item}: Props) => {

    const handleSubmit = () => {

        const url = buildUrl(remoteRoutes.onboardingService, `/contact/persons/${item.id}`)

        del(url, (response) => {

            CloseModal("delete-contact-person")

            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {

            console.log(error)

            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={item}
            enableReinitialize={true}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="delete-contact-person"
                    title="Delete Contact Person"
                    size="md"
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <p>Are you sure you want to delete this contact person? This action cannot be undone.</p>
                </ModalWrapper>
            }}

        </Formik>
    );
}

export default DeleteContactPersonForm;