import {Formik} from "formik";
import toast from "react-hot-toast";
import {ICostCentre} from "../../interfaces/IHub";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {del} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";

interface Props {
    item: ICostCentre
}

const DeleteCostCentreForm = ({item}: Props) => {

    const handleSubmit = () => {

        const url = buildUrl(remoteRoutes.onboardingService, `/cost/centres/${item.id}`)

        del(url, (response) => {

            CloseModal("delete-cost-centre")

            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {

            console.log(error)

            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={item}
            enableReinitialize={true}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="delete-cost-centre"
                    title="Delete Cost Centre"
                    size="md"
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <p>Are you sure you want to delete this cost centre? This action cannot be undone.</p>
                </ModalWrapper>
            }}

        </Formik>
    );
}

export default DeleteCostCentreForm;