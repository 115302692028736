import Hubs from "../../hubs/Hubs";

type Props = {
    hubId?: string;
}
export const Mau = ({hubId}: Props) => {
    return (
        <>
            <Hubs hubId={hubId}/>
        </>
    );
}