import {FC} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const Error404: FC = () => {
    return (
        <div className="container">
            <div className="d-flex justify-content-between w-100">
                <h1 className='fw-bolder fs-4x'>
                    Oops! Page not found
                </h1>
                <FontAwesomeIcon icon={faExclamationTriangle} size={'3x'} style={{}}/>
            </div>

            <hr/>

            <div className='my-5'>
                <p>It looks like the page you’re trying to reach doesn’t exist. This might be because:</p>
                <ul>
                    <li>The page has been moved or deleted.</li>
                    <li>The URL was mistyped or is outdated.</li>
                    <li>Something unexpected happened.</li>
                </ul>
            </div>

            <hr/>
        </div>
    )
}

export {Error404}
