import * as Yup from "yup";

export const CreateBatchSchema = Yup.object({
    batchNumber: Yup.string().required("Batch number is required"),
    location: Yup.object().nullable().required('Required'),
    product: Yup.object().nullable().required('Required'),
})

export const MoveBatchSchema = Yup.object({
    typeOfMovement: Yup.string().required('Required'),
    destinationCollectionCentre: Yup.object().nullable().required('Required'),
})

export const ReceiveBatchSchema = Yup.object({
    quantity: Yup.number().required("Required")
})