import {IFarmerProfile} from "../../../app/modules/profiling/interfaces/IFarmerProfile";
import {IBatch} from "../../../app/modules/inventory/interfaces/IBatch";
import {ICompliance} from "../../../app/modules/compliance/interfaces/ICompliance";

export const COMPLIANCE_CONSTANTS = {
    STOP_FETCH: "STOP_FETCH",
    GET_REPORT: "GET_REPORT",
    START_FETCH: "START_FETCH"
}

export interface IComplianceReportState {
    loading: boolean
    data: ICompliance | undefined
}

const initialState: IComplianceReportState = {
    loading: false,
    data: undefined
}


export default function complianceReducer(state = initialState, action: any) {
    switch (action.type) {

        case COMPLIANCE_CONSTANTS.START_FETCH: {
            return {...state, loading: action.payload}
        }

        case COMPLIANCE_CONSTANTS.GET_REPORT: {
            return {...state, data: action.payload}
        }

        case COMPLIANCE_CONSTANTS.STOP_FETCH: {
            return {...state, loading: false}
        }

        default: {
            return state
        }
    }
}
