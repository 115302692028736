import {FC} from 'react'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer border-top mt-auto py-2 d-flex flex-lg-column' id='kt_footer'>
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-end`}
      >
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href="https://symos.io" target='_blank' className='text-gray-800 text-hover-primary'>
            Symos&trade;
          </a>
          <span>&nbsp;by&nbsp;</span>
          <a href="https://asigmagroup.com" target='_blank' className='text-gray-800 text-hover-primary text-primary'>
            ASIGMA
          </a>
        </div>
      </div>
    </div>
  )
}

export {Footer}
