import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {INVENTORY_PURCHASES_CONSTANTS} from "../../data/redux/inventory/purchasesReducer";
import {overrideToastDefaults, toastMessages} from "../../data/toastDefaults";
import {IApiResponse, IPaginationMetadata} from "../interfaces/IApiResponse";
import {get, search} from "../../utils/ajax";
import {remoteRoutes} from "../../data/constants";
import {useAuth} from "../modules/auth";
import {INVENTORY_BATCHES_CONSTANTS} from "../../data/redux/inventory/batchesReducer";

const useFetchBatch = () => {
    const dispatch = useDispatch();

    const {currentUser} = useAuth()

    const fetchBatch = useCallback((id: string) => {
        dispatch({
            type: INVENTORY_BATCHES_CONSTANTS.LOADING_BATCHES,
            payload: true
        });

        const url = `${remoteRoutes.inventoryService}/batches/${id}`;

        get(url,
            (response: any) => {
                dispatch({
                    type: INVENTORY_BATCHES_CONSTANTS.FETCH_BATCHES,
                    payload: [{...response}]
                });

                toast.success(toastMessages.default.success, overrideToastDefaults);
            },
            (error) => {
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => {
                // Optional: any cleanup or final steps
            }
        );
    }, [currentUser, dispatch]);

    return { fetchBatch };
}

export default useFetchBatch;