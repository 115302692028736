import { useDispatch } from "react-redux";
import { useAuth } from "../../../auth";
import { useEffect, useState } from "react";
import { buildUrl } from "../../../../../utils/queryBuilder";
import { remoteRoutes } from "../../../../../data/constants";
import { IContactPerson } from "../../interfaces/IHub";
import { post } from "../../../../../utils/ajax";
import { CloseModal } from "../../../../../_theme/helpers/components/modalHelpers";
import { SETTINGS_CONSTANTS } from "../../../../../data/redux/settings/settingsReducer";
import toast from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../../data/toastDefaults";
import { districts } from "../../../../../data/districts";
import { Field, Formik } from "formik";
import { CollectionCentreSchema } from "../../collectionCenters/interfaces/CollectionCentreSchema";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { XAutoComplete } from "../../../../components/shared/forms/XAutoComplete";
import { IPaginationMetadata } from "../../../../interfaces/IApiResponse";

type Props = {
    hubId?: string
    pagination: IPaginationMetadata
}

const AddContactPersonForm = ({ hubId, pagination }: Props) => {

    const dispatch = useDispatch();
    const { currentUser } = useAuth();

    const [modalKey, setModalKey] = useState(Date.now());

    const initialValues = {
        name: null,
        district: { label: null, id: null },
        hubId: hubId ?? currentUser?.hubId,
        subCounty: null,
        parish: null,
        village: null,
        email: null,
        phoneNumber: null
    };

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const data: IContactPerson = {
            ...values,
            district: values.district.label,
        };

        const url = buildUrl(remoteRoutes.onboardingService, '/contact/persons');
        post(url, data, (response) => {

            CloseModal("new-contact-person", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: SETTINGS_CONSTANTS.POST_CONTACT_PEOPLE,
                payload: {
                    data, pagination: {
                        ...pagination,
                        totalItems: pagination.totalItems + 1,
                    }
                }
            });

            toast.success(toastMessages.default.success, overrideToastDefaults);
            setModalKey(Date.now());
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults);
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={CollectionCentreSchema}
        >
            {({ handleSubmit, isSubmitting }) => (

                <ModalWrapper
                    id={"new-contact-person"}
                    title="Add a Contact Person"
                    size="md"
                    key={modalKey}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Name"
                                name="name"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Phone Number"
                                name="phoneNumber"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Email"
                                name="email"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                component={XAutoComplete}
                                options={districts}
                                label="District"
                                name="district"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Sub-county"
                                name="subCounty"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Parish"
                                name="parish"
                            />
                        </div>

                        <div className="col-lg-6">
                            <Field
                                type="string"
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Village"
                                name="village"
                            />
                        </div>

                    </div>

                </ModalWrapper>
            )}
        </Formik>
    );
}

export default AddContactPersonForm;