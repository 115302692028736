import {Log, User, UserManager, WebStorageStateStore} from 'oidc-client';
import {AUTH_HUB_KEY, AUTH_TOKEN_KEY, AUTH_USER_KEY, env, remoteRoutes} from "../constants";
import {IHub} from "../../app/modules/settings/interfaces/IHub";
import {IUserProfile} from "../../app/modules/auth";
import { json } from 'stream/consumers';

class AuthService {
    public userManager: UserManager;
    private clientRoot = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`

    constructor() {
        const clientRoot = this.clientRoot
        const settings = {
            client_id: env.clientId,
            redirect_uri: `${clientRoot}/signin-callback.html`,
            post_logout_redirect_uri: `${clientRoot}/auth`,
            front_channel_logoutUri: `${clientRoot}/signout-callback.html`,
            response_type: 'code',
            scope: env.envScope,
            authority: remoteRoutes.authServer,
            silent_redirect_uri: `${clientRoot}/silent_renew.html`,
            automaticSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: true,
            userStore: new WebStorageStateStore({store: window.localStorage})
        };
        this.userManager = new UserManager(settings);
        Log.logger = console;
        Log.level = Log.INFO;
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public async login(): Promise<void> {
        await this.userManager.signinRedirect();
    }

    public renewToken(): Promise<User> {
        return this.userManager.signinSilent();
    }

    public async logout(): Promise<void> {
        return await this.userManager.signoutRedirect()
            .then(() => {
                this.userManager.clearStaleState()
            });
    }

    public getHub(): any {

        const defaultHub: IHub = {
            inputCategories: [],
            products: []
        }
        const item = localStorage.getItem(AUTH_HUB_KEY);
        if (item) {
            try {
                return JSON.parse(item) as IHub;
            } catch (error) {

            }
        }
        return defaultHub;
        // this.getUser();
    }

    public updateHub(hub: IHub): any {

        localStorage.setItem(AUTH_HUB_KEY, JSON.stringify(hub));
        
    }

    public getRoles(): any {
        const user = localStorage.getItem(AUTH_USER_KEY)
        let userRoles = []

        if (user) {
            try {
                const userProfile = JSON.parse(user) as IUserProfile
                let roles = userProfile.role;

                if (typeof roles === 'string') {
                    userRoles.push(roles);
                } else {
                    userRoles = roles
                }

            } catch (error) {

            }
        }

        return userRoles;
    }

}

const authService = new AuthService();

export {authService}
