import React, {useState} from 'react';
import * as XLSX from 'xlsx';
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../data/types";
import {IMPORT_PURCHASES_CONSTANTS} from "../../../data/redux/inventory/importPurchasesReducer";
import XTable, {ITableColumn} from "./XTable";

const columns: ITableColumn[] = [
    {
        label: 'Date',
        id: 'date',
        link: undefined,
        isNumberFormat: false,
        isDate: true,
        textAlign: 'text-start'
    },
    {
        label: 'Farmer Plot Code',
        id: 'plot_code',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Quantity',
        id: 'quantity',
        link: undefined,
        isNumberFormat: true,
        isDate: false,
        textAlign: 'text-end'
    },
    {
        label: '',
        id: 'unit',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Batch Number',
        id: 'batch_number',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-end'
    }
]

const ExcelUploader = () => {

    const dispatch = useDispatch()

    const {data, loading}: any = useSelector((state: IState) => state.importPurchases)

    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, {type: 'binary'});

            // Assuming you want to read the first sheet
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convert the sheet to JSON
            const jsonData: any = XLSX.utils.sheet_to_json(worksheet);

            dispatch({
                type: IMPORT_PURCHASES_CONSTANTS.FETCH_PURCHASES,
                payload: jsonData
            })

        };

        reader.readAsBinaryString(file);
    };

    return (
        <>
            <div className="mb-3">
                {/*<label htmlFor="formFile" className="form-label">Choose a file to import</label>*/}
                <input
                    className="form-control form-control-lg"
                    type="file" id="formFile" accept=".xlsx, .xls"
                    onChange={handleFileUpload}/>
            </div>

            {data && data.length > 0 &&
                <XTable checked={false} data={data} columns={columns} loading={loading}/>
            }
        </>
    );
};

export default ExcelUploader;