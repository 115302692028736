import {XSidebarWrapper} from "../../../components/shared/XSidebarWrapper";
import React, {useEffect, useState} from "react";
import {IInput, IInputCategory} from "../interfaces/IHub";
import {IHeaderButtonProps} from "../../../components/shared/SettingsPageHeader";
import EditInputForm from "./modals/EditInputForm";
import {IHubInput} from "./interfaces/IInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DeleteInputForm from "./modals/DeleteInputForm";
import {useAuth} from "../../auth";
import {useSelector} from "react-redux";
import {IState} from "../../../../data/types";

type Props = {
    close: () => void,
    show: boolean,
    inputId: any
}

const HubInputDetails = ({close, show, inputId}: Props) => {

    const input = useSelector((state: IState) =>
        state.settings.inputs.data.find((inp) => inp.id === inputId)
    );

    const [inputDetails, setInputDetails] = useState(input);

    useEffect(() => {
        setInputDetails(input);
    }, [input]);

    const actionButtons: IHeaderButtonProps[] = [
        {
            label: "Edit",
            cssClass: "btn btn-primary btn-sm me-1",
            dataTarget: "#edit-input",
            dataToggle: "modal"
        },
        {
            cssClass: "btn btn-danger btn-sm",
            dataTarget: "#delete-input",
            dataToggle: "modal",
            deleteIcon: true
        },
    ];

    const auth = useAuth();
    const inputCategories = auth.categories?.inputCategories;

    return (
        <>
            <XSidebarWrapper title={"Input Details"} width={35} close={close} show={show}>
                <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
                    <div className="py-3 px-4 rounded-1 bg-primary">
                        <h2>{inputDetails?.name[0]}</h2>
                    </div>
                    <div className="py-3 w-100">
                        <h5>{inputDetails?.name}</h5>
                        <div className="d-flex justify-content-between w-100">
                            <div className="fs-6 small">
                                <span className="badge text-bg-danger">
                                    {inputCategories?.find(i => i.number.toString() == inputDetails?.category)?.name}
                                </span>
                            </div>

                            <div className="action-buttons">

                                {
                                    actionButtons.map((button, index) => {
                                        return (<button
                                            data-bs-toggle={button.dataToggle}
                                            data-bs-target={button.dataTarget}
                                            className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                            key={index}
                                            onClick={button.onClick}>
                                            {button.deleteIcon && <FontAwesomeIcon icon={faTrash}/>}
                                            {button.processing ? 'Please wait...' : button.label}
                                        </button>)
                                    })
                                }
                            </div>


                        </div>

                    </div>

                </div>
                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Description</strong><br/>
                            <span>{inputDetails?.description ?? '-'}</span>
                        </div>
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Units</strong><br/>
                            <span>{inputDetails?.units?.map(u => u.name)?.join(',')}</span>
                        </div>
                    </div>
                </div>

            </XSidebarWrapper>

            {inputDetails && (
                <>
                    <EditInputForm initialValues={inputDetails}/>
                    <DeleteInputForm item={inputDetails}/>
                </>
            )}
        </>
    );
}

export default HubInputDetails;