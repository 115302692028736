import TableWrapper from "../../../../components/shared/TableWrapper";
import {TableFilterLoader} from "../../../../components/shared/loaders/TableFilterLoader";
import {TableLoader} from "../../../../components/shared/loaders/TableLoader";
import React from "react";
import {HubCostCentersPage} from "../../../settings/costCenters/HubCostCentersPage";

interface IProps {
    hubId?: string;
}

const HubCostCentres = ({hubId}: IProps) => {
    return (
        <>
            <HubCostCentersPage hubId={hubId}/>
        </>
    );
}

export default HubCostCentres;