import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {INVENTORY_PURCHASES_CONSTANTS} from "../../data/redux/inventory/purchasesReducer";
import {overrideToastDefaults, toastMessages} from "../../data/toastDefaults";
import {IApiResponse, IPaginationMetadata} from "../interfaces/IApiResponse";
import {search} from "../../utils/ajax";
import {remoteRoutes} from "../../data/constants";
import {useAuth} from "../modules/auth";
import {buildUrl} from "../../utils/queryBuilder";
import {HUBS_CONSTANTS} from "../../data/redux/settings/hubsReducer";

const useFetchHubs = () => {
    const dispatch = useDispatch();

    const {currentUser} = useAuth()
    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const fetchHubs = useCallback((page: number, pageSize: number, searchParams: any) => {
        dispatch({
            type: HUBS_CONSTANTS.LOADING_DATA,
            payload: true
        });

        const params: any = {page, pageSize, ...searchParams};
        const url = buildUrl(remoteRoutes.onboardingService, '/hubs', params);

        search(url, params,
            (response: IApiResponse) => {
                const {data, paginationMetadata} = response;
                setPagination(paginationMetadata);

                dispatch({
                    type: HUBS_CONSTANTS.FETCH_DATA,
                    payload: data
                });

                toast.success(toastMessages.default.success, overrideToastDefaults);
            },
            (error) => {
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => {
                // Optional: any cleanup or final steps
            }
        );
    }, [currentUser, dispatch]);

    const fetchChildren = useCallback((parentId: string) => {
        dispatch({
            type: HUBS_CONSTANTS.LOADING_DATA,
            payload: true
        });

        const params: any = {};
        const url = buildUrl(remoteRoutes.onboardingService, `/hubs/${parentId}/child-ids`);

        search(url, params,
            (response: IApiResponse) => {
                const {data, paginationMetadata} = response;
                setPagination(paginationMetadata);

                dispatch({
                    type: HUBS_CONSTANTS.UPDATE_CHILD_IDS,
                    payload: {
                        id: parentId,
                        childIds: data
                    }
                });

                toast.success(toastMessages.default.success, overrideToastDefaults);
            },
            (error) => {
                toast.error(toastMessages.default.fail, overrideToastDefaults);
            },
            () => {
                // Optional: any cleanup or final steps
            }
        );
    }, [currentUser, dispatch]);

    return {
        fetchHubs,
        fetchChildren,
        pagination
    };
}

export default useFetchHubs;