import TableWrapper from "../../../../components/shared/TableWrapper";
import {TableFilterLoader} from "../../../../components/shared/loaders/TableFilterLoader";
import {TableLoader} from "../../../../components/shared/loaders/TableLoader";
import React from "react";
import HubInputsPage from "../../../settings/inputs/HubInputsPage";

type Props = {
    hubId?: string;
}

const HubInputs = ({hubId}: Props) => {
    return (
        <>
            <HubInputsPage hubId={hubId}/>
        </>
    );
}

export default HubInputs;