import {configureStore} from "@reduxjs/toolkit";
import Logger from "redux-logger";
import core from "./coreReducer";
import users from "./users/reducer";
import farmers from "./farmers/profilesReducer"
import inputs from "./inputs/inputsReducer"
import purchases from "./inventory/purchasesReducer";
import importPurchases from "./inventory/importPurchasesReducer";
import downloads from "./downloads/reducer";
import batches from './inventory/batchesReducer'
import batchMovements from './inventory/batchMovementsReducer'
import trainings from "./trainings/trainingsReducer";
import trainingAreas from "./trainings/trainingAreasReducer";
import trainingAttendees from "./trainings/trainingAttendeesReducer";
import trainingFeedback from "./trainings/trainingFeedbackReducer";
import trainingFacilitators from "./trainings/trainingFacilitatorsReducer";
import hubs from "./settings/hubsReducer";
import products from "./settings/productsReducer";
import settings from "./settings/settingsReducer"
import batchSales from "./inventory/batchSalesReducer";
import complianceReport from './compliance/complianceReducer'
import buyers from './inventory/buyersReducer'

export const store = configureStore({
    reducer: {
        core,
        users,
        farmers,
        inputs,
        purchases,
        importPurchases,
        downloads,
        batches, batchMovements, batchSales, buyers,
        trainings, trainingAreas, trainingAttendees, trainingFeedback, trainingFacilitators,
        complianceReport,
        hubs,
        products,
        settings
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    })
        .concat(Logger),
    //devTools: process.env.NODE_ENV !== 'production', // #Refactor: Use this to disable devtools in production. Pay attention to the env setup
});