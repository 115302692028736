import {Outlet, Route, Routes} from "react-router-dom";
import {Users} from "./Users";


const UserManagementPage = () => {
    return (
        <Routes>
            <Route element={<Outlet/>}>
                <Route index element={<Users includeMyHubUsers={true} title={"Users"}/>}/>
            </Route>
        </Routes>
    );
}

export default UserManagementPage;