import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { useState } from "react";
import { XTextField } from "../../../../components/shared/forms/XTextField";
import { orange } from "@mui/material/colors";

interface DummyData {
  [section: string]: string[];
}

const dummyData: DummyData = {
  "Account Management": [
    "Can add account",
    "Can delete account",
    "Can edit account",
    "Can edit account",
    "Can edit account",
    "Can edit account",
    "Can edit account",
    "Can edit account",
    "Can edit account",
    "Can edit account",
    "Can edit account",
    "Can edit account",
  ],
  "User Management": [
    "Can add user",
    "Can delete user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
  ],
  Management: [
    "Can add user",
    "Can delete user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
    "Can edit user",
  ],
};

const RolesAndPermissionForm = () => {
  const [checkedSections, setCheckedSections] = useState<
    Record<string, boolean>
  >(
    Object.keys(dummyData).reduce(
      (acc, section) => {
        acc[section] = false;
        return acc;
      },
      {} as Record<string, boolean>,
    ),
  );

  const [checkedPermissions, setCheckedPermissions] = useState<
    Record<string, Record<string, boolean>>
  >(
    Object.keys(dummyData).reduce(
      (acc, section) => {
        acc[section] = dummyData[section].reduce(
          (permAcc, permission) => {
            permAcc[permission] = false;
            return permAcc;
          },
          {} as Record<string, boolean>,
        );
        return acc;
      },
      {} as Record<string, Record<string, boolean>>,
    ),
  );

  const handleSectionChange = (section: string) => {
    setCheckedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handlePermissionChange = (section: string, permission: string) => {
    setCheckedPermissions((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [permission]: !prevState[section][permission],
      },
    }));
  };

  // Form submission logic
  const handleSubmit = (values: any) => {
    console.log("Form submitted with values:", values);
    console.log("Checked sections:", checkedSections);
    console.log("Checked permissions:", checkedPermissions);
  };

  const initialValues = {
    roleName: "",
    roleDescription: "",
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, handleSubmit, values }) => (
        <ModalWrapper
          id={"add-role"}
          title={"Create New Role"}
          submitting={isSubmitting}
          handleSubmit={handleSubmit}
        >
          <div className="row">
            <div
              className="col-lg-12"
              style={{ maxHeight: "60vh", overflowY: "auto" }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    type="text"
                    label="Role Name"
                    name="roleName"
                    values={values.roleName}
                    disabled={isSubmitting}
                    as={XTextField}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    type="text"
                    label="Description"
                    as={XTextField}
                    name="roleDescription"
                  />
                </div>
              </div>
              <div>
                <b>Permissions</b>
                {Object.keys(dummyData).map((section) => (
                  <div key={section}>
                    {/* Section Checkbox */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#DF901F",
                            },
                          }}
                          checked={checkedSections[section]}
                          onChange={() => handleSectionChange(section)}
                        />
                      }
                      label={section}
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 12,
                          fontWeight: "bold",
                        },
                      }}
                    />
                    {/* Sub-permissions checkboxes */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        ml: 4,
                        flexWrap: "wrap",
                        maxHeight: 200,
                      }}
                    >
                      {dummyData[section].map((permission) => (
                        <FormControlLabel
                          key={permission}
                          control={
                            <Checkbox
                              sx={{
                                "&.Mui-checked": {
                                  color: "#DF901F",
                                },
                              }}
                              checked={checkedPermissions[section][permission]}
                              onChange={() =>
                                handlePermissionChange(section, permission)
                              }
                            />
                          }
                          label={permission}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 12,
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ModalWrapper>
      )}
    </Formik>
  );
};

export default RolesAndPermissionForm;
