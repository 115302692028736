import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { authService } from "../../../../data/oidc/AuthService";
import {
  ICollectionCentre,
  IHub,
  IInput,
} from "../../settings/interfaces/IHub";
import { XAutoComplete } from "../../../components/shared/forms/XAutoComplete";
import utc from "dayjs/plugin/utc";
import { fromZonedTime, toZonedTime } from "date-fns-tz";

type Props = {
  onApplyFilters: (filters: any) => void;
  setFilters: (filters: any) => void;
};

const InputPurchasesFilterForm: React.FC<Props> = ({
  onApplyFilters,
  setFilters,
}) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [locations, setLocations] = useState<any[] | undefined>(undefined);
  const [inputs, setInputs] = useState<any[] | undefined>(undefined);

  const initialValues = {
    input: { label: "", id: "" },
    collectionCentre: { label: "", id: "" },
    from: null,
    to: null,
  };

  useEffect(() => {
    const hub: IHub | undefined = authService.getHub();

    if (hub) {
      const { collectionCentres: collectionCentres } = hub;
      const sortedCollectionCentres = collectionCentres
        ?.map((center: ICollectionCentre) => ({
          label: center.name,
          id: center.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setLocations(sortedCollectionCentres);

      const { inputs: inputs } = hub;
      const sortedInputs = inputs
        ?.map((input: IInput) => ({
          id: input.id,
          label: input.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setInputs(sortedInputs);
    }
  }, []);

  const handleSubmit = (values: any) => {
    dayjs.extend(utc);

    const filters = {
      inputId: values.input?.id ?? "",
      collectionCentreId: values.collectionCentre?.id ?? "",
      from: values?.from
        ? fromZonedTime(
            toZonedTime(new Date(values.from), userTimeZone),
            "UTC",
          ).toISOString()
        : dayjs(0).utc().toISOString(),
      to: values?.to
        ? fromZonedTime(
            toZonedTime(new Date(values.to), userTimeZone),
            "UTC",
          ).toISOString()
        : dayjs().endOf("day").utc().toISOString(),
    };

    onApplyFilters(filters);
  };

  const handleClearForm = (resetForm: any) => {
    resetForm();
    setFilters({});
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ resetForm, values, dirty }) => {
          return (
            <Form>
              <div className="row">
                <div className={"col-lg-12"}>
                  <Field
                    type="string"
                    component={XAutoComplete}
                    options={inputs || []}
                    getOptionLabel={(option: any) =>
                      option ? option.label : ""
                    }
                    value={values.input}
                    label={"Input Name"}
                    name={"input"}
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    type="string"
                    component={XAutoComplete}
                    options={locations || []}
                    value={values.collectionCentre}
                    label="Collection Center"
                    name="collectionCentre"
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    type="string"
                    label="From"
                    as={XDatePicker}
                    name="from"
                    value={values.from || null}
                    maxDate={dayjs(new Date())}
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    type="string"
                    label="To"
                    as={XDatePicker}
                    name="to"
                    value={values.to || null}
                    maxDate={dayjs(new Date())}
                  />
                </div>
                <div className="border-top mb-2 pt-3 pb-2 d-flex w-100 justify-content-between align-content-center">
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary ms-2"
                    disabled={!dirty}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    title={"Clear Filters"}
                    onClick={() => handleClearForm(resetForm)}
                    disabled={!dirty}
                    className="btn btn-sm btn-outline-secondary ms-2"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default InputPurchasesFilterForm;
