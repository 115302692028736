import {XSidebarWrapper} from "../../../components/shared/XSidebarWrapper";
import React, {useEffect, useState} from "react";
import {remoteRoutes} from "../../../../data/constants";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {IFarmerPlot} from "../interfaces/IFarmerPlot";
import {useAuth} from "../../auth";

type Props = {
    close: () => void,
    show: boolean,
    plot: IFarmerPlot | null
}

export function PlotDetails({close, show, plot}: Props) {

    return (
        <>
            <XSidebarWrapper title={"Plot Details"} width={25} close={close} show={show}>
                <div className="d-flex justify-content-between w-100 align-items-baseline border-bottom mb-3">
                    <div className="py-3">
                        <h5>{plot?.plotNumber?.toUpperCase()}</h5>
                        <div className="fs-6 small">
                    <span className="badge text-bg-info">
                        {plot?.productionType}
                    </span>
                        </div>
                    </div>
                    <div className={"w-75 text-end"}>

                    </div>
                </div>
                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Total area</strong><br/>
                            <span>{plot?.season}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Season</strong><br/>
                            <span>{plot?.season}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Year</strong><br/>
                            <span>{plot?.year}</span>
                        </div>
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Tenure</strong><br/>
                            <span>{plot?.landOwnership}</span>
                        </div>
                        {plot?.numberOfTrees && <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Number of Trees</strong><br/>
                            <span>{plot?.numberOfTrees}</span>
                        </div>}
                        {plot?.numberOfProductiveTrees && <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Number of Productive Trees</strong><br/>
                            <span>{plot?.numberOfProductiveTrees}</span>
                        </div>}
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Inter-cropped</strong><br/>
                            <span>{plot?.isInterCropped}</span>
                        </div>
                    </div>
                </div>
                {plot?.remarks && <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-4">
                            <strong className="small fw-semibold">Remarks</strong><br/>
                            <span>{plot?.remarks}</span>
                        </div>
                    </div>
                </div>}
            </XSidebarWrapper>
        </>
    );
}