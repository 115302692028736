import React, {MouseEventHandler} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";


export interface IHeaderButtonProps {
    label?: string
    cssClass: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    processing?: boolean
    dataTarget?: string
    dataToggle?: string
    onSubmit?: (values: any) => void,
    deleteIcon?: boolean
}

interface IHeaderProps {
    title?: string
    actionButtons?: IHeaderButtonProps[]
    children?: any
}

export const SettingsPageHeader = (props: IHeaderProps) => {
    return (
        <>
            <div
                className="card-body d-flex justify-content-between align-items-center bg-white w-100 p-3 rounded-2 mb-3">
                <div className="page-title">{props.title}</div>

                <div className="d-flex justify-content-between align-items-center gap-3">

                    {props.actionButtons?.length && props.actionButtons.map((button, index) => (
                        <button
                            data-bs-toggle={button.dataToggle}
                            data-bs-target={button.dataTarget}
                            className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                            key={index}
                            onClick={button.onClick}>
                            {button.processing ? 'Please wait...' : button.label}
                        </button>
                    ))}
                    {/*<FontAwesomeIcon icon={faEllipsisVertical} className="text-black"/>*/}
                </div>

            </div>
        </>
    );
}