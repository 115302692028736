import {IFarmerProfile} from "../../../app/modules/profiling/interfaces/IFarmerProfile";
import {IPaginationMetadata} from "../../../app/interfaces/IApiResponse";

export const FARMER_PROFILES_CONSTANTS = {
    STOP_FETCH: "STOP_FETCH",
    FETCH_PROFILES: "FETCH_PROFILES",
    POST_PROFILE: "POST_PROFILE",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    LOADING_PROFILES: "LOADING_PROFILES",
    GET_PROFILE: "GET_PROFILE",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    FILTER_DATA: "FILTER_DATA",
    RESET_FILTER: "RESET_FILTER",
    PAGINATION: "PAGINATION",
}

export interface IFarmerProfileState {
    loading: boolean
    data: IFarmerProfile[]
    pagination?: IPaginationMetadata
    filter: {
        show: boolean,
        data: IFarmerProfile[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IFarmerProfileState = {
    loading: false,
    data: [],
    pagination: {
        pageSize: 25,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    },
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function profilesReducer(state = initialState, action: any) {
    switch (action.type) {

        case FARMER_PROFILES_CONSTANTS.FILTER_DATA:
            return {...state, ...action.payload, show: !state.filter.show}

        case FARMER_PROFILES_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return {...initialState, reset: true}

        case FARMER_PROFILES_CONSTANTS.SET_SORT_PARAMS:
            return {...state, ...action.payload}

        case FARMER_PROFILES_CONSTANTS.PAGINATION:
            return {...state, pagination: action.payload}

        case FARMER_PROFILES_CONSTANTS.FETCH_PROFILES: {
            return {...state, loading: false, data: [...action.payload], reset: false}
        }

        case FARMER_PROFILES_CONSTANTS.POST_PROFILE: {
            const profile: IFarmerProfile = action.payload
            return {...state, data: [profile, ...state.data]}
        }

        case FARMER_PROFILES_CONSTANTS.UPDATE_PROFILE: {
            const updatedProfile: IFarmerProfile = action.payload

            return {
                ...state,
                data: state.data.map(profile => profile.id === updatedProfile.id ? updatedProfile : profile)
            }

        }

        case FARMER_PROFILES_CONSTANTS.LOADING_PROFILES: {
            return {...state, loading: action.payload}
        }

        case FARMER_PROFILES_CONSTANTS.GET_PROFILE: {
            return {...state, requestDetails: action.payload}
        }

        case FARMER_PROFILES_CONSTANTS.STOP_FETCH: {
            return {...state, loading: false}
        }

        default: {
            return state
        }
    }
}
