import {
  IHeaderButtonProps,
  SettingsPageHeader,
} from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import { IProcess } from "../interfaces/IHub";
import { ITableColumn, XTable } from "../../../components/shared/XTable";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import {
  ISettingsState,
  SETTINGS_CONSTANTS,
} from "../../../../data/redux/settings/settingsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import ProcessDetails from "./ProcessDetails";
import CreateProcess from "./modals/CreateProcess";
import debounce from "lodash.debounce";

interface IProps {
  hubId?: string;
  title?: string;
}

const columns: ITableColumn[] = [
  {
    label: "Process Name",
    id: "name",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "Products",
    id: "productStrings",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "Cost Centers",
    id: "costCentreStrings",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "Description",
    id: "description",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
];

const handleSearch = (searchTerm: string) => {};

export const HubProcessesPage = ({ title, hubId }: IProps) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredProcesses, setFilteredProcesses] = useState<IProcess[]>([]);

  // const [pagination, setPagination] = useState<IPaginationMetadata>({
  //     pageSize: 8,
  //     hasNextPage: false,
  //     currentPage: 1,
  //     hasPreviousPage: false,
  //     totalItems: 0,
  //     totalPages: 0
  // });

  const initialValues = {
    id: "",
    name: "",
    hubId: "",
    description: "",
    products: [],
    costCentres: [],
  };

  const { processes, loading }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );
  const { pagination, data } = processes;

  const [importing, setImporting] = useState<boolean>(false);
  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [selectedProcess, setSelectedProcess] =
    useState<IProcess>(initialValues);

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: SETTINGS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const filterProcesses = () => {
    const data: IProcess[] = processes.data.map((process: IProcess) => ({
      ...process,
      productStrings: process.products
        ? process.products.map((p) => p.name).join(", ")
        : "",
    }));
    if (searchTerm) {
      setFilteredProcesses(
        data.filter((process) =>
          process.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredProcesses(data);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  useEffect(() => {
    filterProcesses();
  }, [processes, searchTerm]);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [page]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.onboardingService) {
      let params: any = { hubId, page, pageSize };

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(
        remoteRoutes.onboardingService,
        "/processes",
        params,
      );
      console.log(url);
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          const processes: IProcess[] = data.map((process: IProcess) => ({
            ...process,
            costCentreStrings: process.costCentres
              ?.map((cc) => cc.name)
              .join(", "),
            productStrings: process.products?.map((p) => p.name).join(", "),
          }));

          dispatch({
            type: SETTINGS_CONSTANTS.FETCH_PROCESSES,
            payload: { data, pagination: paginationMetadata },
          });

          // toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: IProcess) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: IProcess) => {
    setDrawerVisible(!drawerVisible);
    setSelectedProcess(item);
  };

  const actionButtons: IHeaderButtonProps[] = [
    {
      label: "Add a Process",
      cssClass: "btn btn-primary btn-sm",
      dataTarget: "#add-process",
      dataToggle: "modal",
    },
  ];

  return (
    <>
      {title && <SettingsPageHeader title={title} />}
      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-auto">
            <input
              type="text"
              onKeyUp={(event) => handleSearch(event)}
              onChange={handleSearchChange}
              className="form-control"
              placeholder="Type to search..."
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            {/* <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                            <span className="ms-2">Filters</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">
                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>
                        </button> */}

            {showActionButtons &&
              actionButtons.map((button, index) => {
                return (
                  <button
                    data-bs-toggle={button.dataToggle}
                    data-bs-target={button.dataTarget}
                    className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                    key={index}
                    onClick={button.onClick}
                  >
                    {button.processing ? "Please wait..." : button.label}
                  </button>
                );
              })}
          </div>
        </div>

        <XTable
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={filteredProcesses}
          columns={columns}
          loading={loading}
        />

        <XPagination
          dataLength={processes.data.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <ProcessDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        processId={selectedProcess.id}
      />
      <CreateProcess hubId={hubId} pagination={pagination} />
    </>
  );
};
