/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    let rootEl = document.getElementById('root') || undefined;
    if(rootEl){
      rootEl.style.height = '100%';
    }
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <>
      <Outlet />
    </>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
