import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper"
import React, { useEffect, useState } from "react"
import { IBatch } from "./interfaces/IBatch"
import { remoteRoutes } from "../../../data/constants"
import { put } from "../../../utils/ajax"
import toast from "react-hot-toast"
import { overrideToastDefaults, toastMessages } from "../../../data/toastDefaults"
import { useSelector } from "react-redux"
import { useAuth } from "../auth"
import useFetchPurchases from "../../hooks/useFetchPurchases"
import { IState } from "../../../data/types"
import XTable from "../../components/shared/XTable"
import { PurchaseColumns } from "./Purchases"
import XPagination from "../../components/shared/XPagination"
import { XSearchFilter } from "../../components/shared/XSearchFilter"
import { printBatchStatus } from "../../../utils/enumHelpers"
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader"
import SellBatchForm from "./modals/SellBatchForm"
import { printNaturalDateShort } from "../../../utils/dateHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan } from "@fortawesome/free-solid-svg-icons"

type Props = {
    close: () => void,
    show: boolean,
    batch: IBatch | null
}

export function BatchDetails({ close, show, batch }: Props) {

    const { currentUser } = useAuth()
    const { fetchPurchases, pagination } = useFetchPurchases()
    const { data, loading }: any = useSelector((state: IState) => state.purchases)

    const [updatingStatus, setUpdatingStatus] = useState<boolean>(false)

    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (!batch?.id) return;

        let params: any = {
            hubId: currentUser?.hubId,
            id: batch.id
        };

        if (searchTerm) params = { ...params, searchTerm };

        fetchPurchases(page, 7, params);
    }, [batch, page, searchTerm]);

    const handleSearchPurchases = (event: any) => {

        const { value } = event.target
        if (!value) setSearchTerm(undefined)

        else {
            if (value && event.keyCode === 13) {
                setSearchTerm(value)
            }
        }
    }

    const handleUpdateStatus = (status: any) => {
        setUpdatingStatus(true)

        const dateString = batch?.date
        const dateObject = new Date(dateString)
        const isoStringDate = dateObject.toISOString()

        const updatedBatch = { ...batch, date: isoStringDate, currentStatus: status }

        const url = remoteRoutes.inventoryService + '/batches/' + batch?.id
        put(url, updatedBatch, (response) => {
            toast.success(toastMessages.default.success, overrideToastDefaults)
            setUpdatingStatus(false)
        }, error => {
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const actionButton: IHeaderButtonProps = {
        label: "Export",
        cssClass: "btn btn-primary btn-sm",
        dataTarget: "#sell-batch",
        dataToggle: "modal"
    };

    const status = printBatchStatus(batch?.currentStatus);

    const formattedData = data.map((row: { date: string }) => ({
        ...row,
        date: printNaturalDateShort(row.date)
    }))

    return (
        <>
            <XSidebarWrapper title={"Batch Details"} width={50} close={close} show={show}>
                <div className="d-flex justify-content-between w-100 align-items-baseline border-bottom mb-2">
                    <div className="py-2">
                        <h5>{batch?.batchNumber.toUpperCase()}</h5>
                        <div className="mb-2">
                            {batch?.productName} - {batch?.variety}
                        </div>

                        <div className="d-flex align-items-center justify-content-start my-auto gap-1">
                            <div>
                                <span className="badge text-bg-purple-light d-inline">
                                    {Number(batch?.quantity).toLocaleString()} {batch?.unitName}
                                </span>
                            </div>
                            <div>
                                <span>
                                    {status}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-around my-auto" style={{width: 180}}>
                        <div>
                            {batch?.currentStatus !== 6 && batch?.currentStatus !== 2 ? (
                                <div className="btn-group" role="group">
                                    <button
                                        id="btnGroupDrop1"
                                        type="button"
                                        className={`btn btn-outline-primary dropdown-toggle ${updatingStatus ? "disabled" : ""}`}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {updatingStatus ? "Processing..." : "Manage"}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                        {batch?.currentStatus !== 2 && (
                                            <>
                                                <li>
                                                    <a className="dropdown-item" data-bs-toggle="modal"
                                                       data-bs-target="#move-batch" href="#">
                                                        Move Batch
                                                    </a>
                                                </li>
                                                <li className="dropdown-divider"></li>
                                                <li>
                                                    <button
                                                        data-bs-toggle={actionButton.dataToggle}
                                                        data-bs-target={actionButton.dataTarget}
                                                        className="dropdown-item"
                                                        key={1}
                                                        onClick={actionButton.onClick}
                                                    >
                                                        {actionButton.processing ? "Please wait..." : actionButton.label}
                                                    </button>
                                                </li>
                                            </>
                                        )}
                                        {/* Removed Batch Processing */}
                                        {/* {batch && batch.currentStatus <= 4 && (
                                            <>
                                                <li>
                                                    {batch.currentStatus === 4 ? (
                                                        <a onClick={() => handleUpdateStatus(5)} className="dropdown-item" href="#">
                                                            Mark as Processed
                                                        </a>
                                                    ) : (
                                                        batch.currentStatus < 4 && (
                                                            <a onClick={() => handleUpdateStatus(4)} className="dropdown-item" href="#">
                                                                Send for Processing
                                                            </a>
                                                        )
                                                    )}
                                                </li>
                                                <li className="dropdown-divider"></li>
                                            </>
                                        )} */}

                                    </ul>
                                </div>
                            ) : (
                                <span>{status}</span>
                            )}
                        </div>
                        <div>
                            <button className="btn btn-primary">Edit</button>
                        </div>
                        <div>
                            <button className="btn btn-danger">
                                <FontAwesomeIcon icon={faTrashCan}/>
                            </button>
                        </div>
                    </div>

                </div>
                <div className="scrollable-sidebar">
                    <div className="p-3 bg-gray rounded-3 mb-2">
                        <div className="row">
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Batched on</strong><br />
                                <span>{batch?.date}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Batched at</strong><br />
                                <span>{batch?.batchingCollectionCentreName}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Current Location</strong><br />
                                <span>{batch?.currentCollectionCentreName}</span>
                            </div>
                            <div className="col-lg-6 col-12 mb-2">
                                <strong className="small fw-semibold">Batched by</strong><br />
                                <span>-</span>
                            </div>
                        </div>
                    </div>
                    {batch?.description && <div className="p-3 bg-gray rounded-3 mb-2">
                        <div className="row">
                            <div className="col-lg-12 col-12 mb-2">
                                <strong className="small fw-semibold">Description</strong><br />
                                <span>{batch?.description}</span>
                            </div>
                        </div>
                    </div>}

                    <div className="rounded-3 mt-3">

                        <h5>Purchases</h5>
                        <XSearchFilter onKeyUp={(event) => handleSearchPurchases(event)} />
                        <XTable checked={false} data={formattedData} columns={PurchaseColumns} loading={loading} />
                        <XPagination
                            dataLength={data.length}
                            pagination={pagination}
                            currentPage={page}
                            setPage={(page) => setPage(page)} />
                    </div>
                </div>
                
            </XSidebarWrapper>
            <SellBatchForm initialValues={batch} />
        </>
    )


}