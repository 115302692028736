import { XSidebarWrapper } from "../../../components/shared/XSidebarWrapper";
import { IProcess } from "../interfaces/IHub";
import { IHeaderButtonProps } from "../../../components/shared/SettingsPageHeader";
import EditProcessForm from "./modals/EditProcessForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteProcessForm from "./modals/DeleteProcessForm";
import {useSelector} from "react-redux";
import {IState} from "../../../../data/types";
import {useEffect, useState} from "react";

type ProcessDetailsProps = {
    close: () => void,
    show: boolean,
    processId: any
}

const ProcessDetails = ({close, show, processId}: ProcessDetailsProps) => {

    const process = useSelector((state: IState) =>
        state.settings.processes.data.find((proc) => proc.id === processId)
    );

    const [processDetails, setProcessDetails] = useState(process);

    useEffect(() => {
        setProcessDetails(process);
    }, [process]);

    const actionButtons: IHeaderButtonProps[] = [
        {
            label: "Edit",
            cssClass: "btn btn-primary btn-sm me-1",
            dataTarget: "#edit-process",
            dataToggle: "modal"
        },
        {
            cssClass: "btn btn-danger btn-sm",
            dataTarget: "#delete-process",
            dataToggle: "modal",
            deleteIcon: true
        },
    ];
    return (
        <>
            <XSidebarWrapper title={"Process Details"} width={35} close={close} show={show}>
                <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3 pb-2">
                    <div className="py-3 px-4 rounded-1 bg-primary">
                        <h2>{processDetails?.name.substring(0, 2).toUpperCase()}</h2>
                    </div>
                    <div className="py-3 w-100">
                        <h5>{processDetails?.name}</h5>
                        <div className="d-flex justify-content-end w-100">
                            <div className="action-buttons">
                                {
                                    actionButtons.map((button, index) => {
                                        return (<button
                                            data-bs-toggle={button.dataToggle}
                                            data-bs-target={button.dataTarget}
                                            className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                            key={index}
                                            onClick={button.onClick}>
                                            {button.deleteIcon && <FontAwesomeIcon icon={faTrash} />}
                                            {button.processing ? 'Please wait...' : button.label}
                                        </button>)
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Products</strong><br />
                            <div className="d-flex gap-1">
                                {
                                    processDetails?.products?.map((product, index) => {
                                        return (
                                            <span className="badge text-bg-danger" key={index}>
                                                {product.name}
                                            </span>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Cost Centres</strong><br />
                            <span>{processDetails?.costCentreStrings}</span>
                        </div>
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Description</strong><br />
                            <span>{processDetails?.description}</span>
                        </div>
                    </div>
                </div>

            </XSidebarWrapper>

            {processDetails && (
                <>
                    <EditProcessForm initialValues={processDetails}/>
                    <DeleteProcessForm item={processDetails}/>
                </>
            )}

        </>
    );
}

export default ProcessDetails;