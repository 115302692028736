import { Outlet, useLocation } from 'react-router-dom'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import Header from "./components/Header";
import { InnerMenu } from "../../app/components/shared/InnerMenu";
import { Footer } from "./components/Footer";
import { MainMenuDrawer } from "./components/MainMenuDrawer";
import { Box } from "@mui/material";

const MasterLayout = () => {
    const location = useLocation()
    const hideDrawer = location.pathname.includes('/search');

    return (
        <PageDataProvider>
            <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
                <Header />
                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    {!hideDrawer && <MainMenuDrawer />}
                    <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Content>
                            <div className="container-fluid flex-grow-1" style={{ paddingTop: 70 }}>
                                <Outlet />
                            </div>
                        </Content>
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </PageDataProvider>
    )
}

export { MasterLayout }