import {useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import {IButtonProps} from "./PageHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

interface IProps {
    backArrow?: boolean
}

export const BackButton = ({backArrow}: IProps) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1)
    }

    return (
        <button className="back-button" onClick={handleClick}>
            {backArrow && <FontAwesomeIcon className="me-3" icon={faArrowLeft}/>}
        </button>
    );
}