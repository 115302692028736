import { Outlet, Route, Routes } from "react-router-dom"
import SearchBactch from "./SearchBactch"

const BatchMonitoringPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<>
                    <SearchBactch />
                </>} />
                <Route path='search' element={<> <SearchBactch /></>} />
            </Route>
        </Routes>
    )

}

export default BatchMonitoringPage