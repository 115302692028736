import { Formik, Field } from "formik";
import { CloseModal } from "../../../../_theme/helpers/components/modalHelpers";
import { ModalWrapper } from "../../../components/shared/ModalWrapper";
import { XTextField } from "../../../components/shared/forms/XTextField";
import { XDatePicker } from "../../../components/shared/forms/XDatePicker";
import dayjs from "dayjs";
import { XSelect } from "../../../components/shared/forms/XSelect";
import { IInputMovement } from "../interfaces/IInputMovement";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IdName } from "../../settings/inputs/interfaces/IInput";
import toast from "react-hot-toast";
import { post } from "../../../../utils/ajax";
import { remoteRoutes } from "../../../../data/constants";
import { INPUTS_DISTRIBUTION_CONSTANTS } from "../../../../data/redux/inputs/inputsReducer";
import { toastMessages, overrideToastDefaults } from "../../../../data/toastDefaults";
import { buildUrl } from "../../../../utils/queryBuilder";
import moment from "moment";

type Props = {
    selectedRow?: IInputMovement | null
}

const ConfirmInputDeliveryForm = ({ selectedRow }: Props) => {

    const [inputMovement, setInputMovement] = useState<IInputMovement>({
        ...selectedRow,
        id: selectedRow?.id,
        confirmationDate: selectedRow?.confirmationDate ? new Date(selectedRow.confirmationDate as string).toISOString() : new Date().toISOString(),
    });

    const dispatch = useDispatch();
    const [modalKey, setModalKey] = useState(Date.now());

    useEffect(() => {
        if (selectedRow) {
            setInputMovement({
                ...selectedRow
            })
        }
    }, [selectedRow])

    useEffect(() => {

    }, [])

    const handleSubmit = (values: any, { resetForm, setSubmitting }: any) => {
        const data: IInputMovement = {
            confirmationDate: values.confirmationDate.toISOString(),
            confirmedQuantity: values.confirmedQuantity,
            confirmedUnitId: selectedRow?.unitId,
            confirmedUnitName: selectedRow?.unitName,
        }

        const url = buildUrl(remoteRoutes.inputsService, `/input/movements/confirm/${values.id}`)

        post(url, data, (response) => {
            CloseModal("confirm-input-delivery", () => {
                setSubmitting(false);
                resetForm();
            })

            dispatch({
                type: INPUTS_DISTRIBUTION_CONSTANTS.UPDATE_DATA,
                payload: data
            })

            setModalKey(Date.now());
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {
            setSubmitting(false);
            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    return (
        <Formik
            initialValues={{
                ...inputMovement,
                confirmationDate: dayjs(inputMovement?.confirmationDate),
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ handleSubmit, isSubmitting }) => {
                return <ModalWrapper
                    id="confirm-input-delivery"
                    title="Confirm Input Delivery"
                    size={"md"}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <Field
                                type={"string"}
                                disabled={isSubmitting}
                                as={XDatePicker}
                                label="Date"
                                name="confirmationDate"
                                maxDate={dayjs(new Date())}
                                minDate={dayjs(selectedRow?.date)}
                            />
                        </div>
                        <div className="col-lg-6">
                            <Field
                                type={"number"}
                                disabled={isSubmitting}
                                as={XTextField}
                                label="Quantity"
                                name="confirmedQuantity"
                            />
                        </div>
                    </div>
                </ModalWrapper>
            }}
        </Formik>
    )
}

export default ConfirmInputDeliveryForm;