import React, { FC, lazy, Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { MasterLayout } from '../../_theme/layout/MasterLayout'
import { Loading } from "../components/shared/Loading";
import InventoryPage from "../modules/inventory/InventoryPage";
import InputDistributionPage from "../modules/input-distribution/InputDistributionPage";
import SettingsPage from "../modules/settings/SettingsPage";
import HubsPage from "../modules/onboarding/hubs/HubsPage";
import { Users } from "../modules/onboarding/userManagement/Users";
import ClientsPage from "../modules/onboarding/clients/ClientsPage";
import { IsAgent, IsBatchDataAdmin, IsGlobalAdmin, IsHubAdmin } from "../hooks/roleChecker";
import { authService } from "../../data/oidc/AuthService";
import UserManagementPage from "../modules/onboarding/userManagement/UserManagementPage";
import BatchMonitoringPage from '../modules/batch-monitoring/BatchMonitoringPage';

const PrivateRoutes = () => {

    const FarmerProfilesPage = lazy(() => import('../modules/profiling/FarmerProfilesPage'))
    const DashboardPage = lazy(() => import('../modules/dashboard/DashboardPage'))
    const TrainingPage = lazy(() => import('../modules/training/TrainingPage'))

    const [roles, setRoles] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRoles = async () => {
            const roles = await authService.getRoles();
            setRoles(roles);
            setLoading(false);
        };
        fetchRoles();
    }, []);

    useEffect(() => {
        const hasRedirected = localStorage.getItem('hasRedirected');
        if (!loading && !hasRedirected) {
            localStorage.setItem('hasRedirected', 'true');
            navigate('/dashboard');
        }
    }, [loading, navigate]);

    if (loading) {
        return <Loading />;
    }

    const isGlobalAdmin = IsGlobalAdmin(roles);
    const isHubAdmin = IsHubAdmin(roles);
    const isAgent = IsAgent(roles);
    const isBatchDataAdmin = IsBatchDataAdmin(roles);


    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />

                {isBatchDataAdmin && (
                    <Route
                        path='search/*'
                        element={
                            <SuspensedView>
                                <BatchMonitoringPage />
                            </SuspensedView>
                        }
                    />
                )}

                {(isGlobalAdmin || isHubAdmin || isAgent) && (
                    <>
                        <Route
                            path='dashboard/*'
                            element={
                                <SuspensedView>
                                    <DashboardPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='farmers/*'
                            element={
                                <SuspensedView>
                                    <FarmerProfilesPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='inventory/*'
                            element={
                                <SuspensedView>
                                    <InventoryPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='inputs/*'
                            element={
                                <SuspensedView>
                                    <InputDistributionPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='trainings/*'
                            element={
                                <SuspensedView>
                                    <TrainingPage />
                                </SuspensedView>
                            }
                        />
                    </>
                )}

                {isGlobalAdmin && (
                    <Route
                        path='clients/*'
                        element={
                            <SuspensedView>
                                <ClientsPage />
                            </SuspensedView>
                        }
                    />
                )}

                {(isGlobalAdmin || isHubAdmin) && (
                    <>
                        <Route
                            path='hubs/*'
                            element={
                                <SuspensedView>
                                    <HubsPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='settings/*'
                            element={
                                <SuspensedView>
                                    <SettingsPage />
                                </SuspensedView>
                            }
                        />
                        <Route
                            path='users/*'
                            element={
                                <SuspensedView>
                                    <UserManagementPage />
                                </SuspensedView>
                            }
                        />
                    </>
                )}

                {isAgent && (
                    <Route
                        path='trainings/*'
                        element={
                            <SuspensedView>
                                <TrainingPage />
                            </SuspensedView>
                        }
                    />
                )}

                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<any> = ({ children }) => {
    return <Suspense fallback={<Loading />}>{children}</Suspense>
}

export { PrivateRoutes }
