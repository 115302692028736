import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";

type Props = {
    showFilter?: boolean
    onKeyUp: (event: any) => void
}

export const XSearchFilter = ({showFilter = false, onKeyUp} : Props) => {
    return <div className="d-flex w-100 align-items-center justify-content-between">
        <div className="input-group w-50">
            <input type="text" onKeyUp={onKeyUp}
                   className="form-control"
                   placeholder="Type to search..."/>
            <span className="input-group-text" id="addon-wrapping">
                <FontAwesomeIcon icon={faSearch}/>
            </span>
        </div>

        {showFilter && <div className="action-buttons">
            <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                <span className="ms-2">Filters</span>
            </button>
            <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>
            </button>
        </div>}
    </div>;
}