import TableWrapper from "../../../../components/shared/TableWrapper";
import {TableFilterLoader} from "../../../../components/shared/loaders/TableFilterLoader";
import {TableLoader} from "../../../../components/shared/loaders/TableLoader";
import React from "react";
import {HubCollectionCentersPage} from "../../../settings/collectionCenters/HubCollectionCentersPage";
import {useParams} from "react-router-dom";

interface IProps {
    hubId?: string;
}

const HubCollectionCentres = ({hubId}: IProps) => {

    return (
        <>
            <HubCollectionCentersPage hubId={hubId}/>
        </>
    );
}

export default HubCollectionCentres;