import {
  IHeaderButtonProps,
  SettingsPageHeader,
} from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import { IContactPerson } from "../interfaces/IHub";
import { ITableColumn, XTable } from "../../../components/shared/XTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import React, { useCallback, useEffect, useState } from "react";
import {
  IApiResponse,
  IPaginationMetadata,
} from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import {
  ISettingsState,
  SETTINGS_CONSTANTS,
} from "../../../../data/redux/settings/settingsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faFilter,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import ContactPersonDetails from "./ContactPersonDetails";
import AddContactPersonForm from "./modals/AddContactPersonForm";
import debounce from "lodash.debounce";

const columns: ITableColumn[] = [
  {
    label: "Name",
    id: "name",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "Email",
    id: "email",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "Phone Number",
    id: "phoneNumber",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "District",
    id: "district",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  // {
  //     label: 'SubCounty',
  //     id: 'subCounty',
  //     link: undefined,
  //     isNumberFormat: false,
  //     isDate: false,
  //     textAlign: 'text-start'
  // },
];

export const HubContactPeoplePage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [filteredContactPersons, setFilteredContactPersons] = useState<
    IContactPerson[]
  >([]);

  // const [pagination, setPagination] = useState<IPaginationMetadata>({
  //     pageSize: 8,
  //     hasNextPage: false,
  //     currentPage: 1,
  //     hasPreviousPage: false,
  //     totalItems: 0,
  //     totalPages: 0
  // });

  const initialValues = {
    name: "",
    district: "",
    hubId: currentUser?.hubId,
    subCounty: "",
    parish: "",
    village: "",
    email: "",
    phoneNumber: "",
  };

  const { contactPeople, loading }: ISettingsState = useSelector(
    (state: IState) => state.settings,
  );
  const { pagination, data } = contactPeople;

  const [importing, setImporting] = useState<boolean>(false);
  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
  const [selectedContactPerson, setSelectedContactPerson] =
    useState<IContactPerson>(initialValues);

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: SETTINGS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const filterContactPeople = () => {
    if (searchTerm) {
      setFilteredContactPersons(
        data.filter((contactPerson) =>
          contactPerson.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredContactPersons(data);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  useEffect(() => {
    filterContactPeople();
  }, [searchTerm, contactPeople]);

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination.pageSize, searchTerm);
  }, [page]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };
  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.onboardingService) {
      const hubId = currentUser?.hubId;
      let params: any = { hubId, page, pageSize };

      const url = buildUrl(
        remoteRoutes.onboardingService,
        "/contact/persons",
        params,
      );
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          dispatch({
            type: SETTINGS_CONSTANTS.FETCH_CONTACT_PEOPLE,
            payload: { data, pagination: paginationMetadata },
          });
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: IContactPerson) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: IContactPerson) => {
    setDrawerVisible(!drawerVisible);
    setSelectedContactPerson(item);
  };

  const actionButtons: IHeaderButtonProps[] = [
    {
      label: "Add a Contact Person",
      cssClass: "btn btn-primary btn-sm",
      dataTarget: "#new-contact-person",
      dataToggle: "modal",
    },
  ];

  return (
    <>
      <SettingsPageHeader title={"Settings > Contact People"} />
      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-auto">
            <input
              type="text"
              // onKeyUp={(event) => handleSearch(event)}
              onChange={handleSearchChange}
              className="form-control"
              placeholder="Type to search..."
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            {/* <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                            <span className="ms-2">Filters</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">
                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>
                        </button> */}

            {showActionButtons &&
              actionButtons.map((button, index) => {
                return (
                  <button
                    data-bs-toggle={button.dataToggle}
                    data-bs-target={button.dataTarget}
                    className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                    key={index}
                    onClick={button.onClick}
                  >
                    {button.processing ? "Please wait..." : button.label}
                  </button>
                );
              })}
          </div>
        </div>

        <XTable
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={filteredContactPersons}
          columns={columns}
          loading={loading}
        />

        <XPagination
          dataLength={contactPeople.data.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <ContactPersonDetails
        close={() => setDrawerVisible(false)}
        show={drawerVisible}
        contactPersonId={selectedContactPerson?.id}
      />

      <AddContactPersonForm pagination={pagination} />
    </>
  );
};
