import {TextField} from "@mui/material";

type Props = {
    disabled?: boolean
    field: any
    form: any
    label: string
    rows?: number
    name: string
    value: string
}

export const XTextArea = ({ field, form, value, label, ...props }: Props) => {
    return (
        <TextField
            {...field}
            {...props}
            sx={{
                '& label.Mui-focused': {
                    color: '#1C5838'
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                        borderColor: '#1C5838'
                    }
                }
            }}
            className="form-control mb-3"
            label={label}
            value={value}
            variant="standard"
            error={form.touched[field.name] && Boolean(form.errors[field.name])}
            helperText={form.touched[field.name] && form.errors[field.name] ? form.errors[field.name] : ''}
            // multiline
            rows={props.rows || 4}
        />
    );
}