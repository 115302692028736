import * as Yup from "yup";

export interface IUser {
    firstName: string
    lastName: string
    userName?: string
    phoneNumber: string
    email: string
    password: string
    confirmPassword?: string
    additionalClaims?: IClaim[]
}

export interface IClaim {
    key: string
    value: string
}

const phoneRegExp = /^(\+?\d{1,4}|\d{1,4})?\s?\d{3}\s?\d{3}\s?\d{3}$/

export const UserSchema = Yup.object({
    firstName: Yup.string().nullable().required('Required'),
    lastName: Yup.string().nullable().required('Required'),
    phoneNumber: Yup.string().nullable().required('Required').matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().nullable().required('Required').email('Invalid email'),
    password: Yup.string().nullable().required('Required'),
    confirmPassword: Yup.string().nullable().required('Required').oneOf([Yup.ref('password')], 'Passwords must match'),
    additionalClaims: Yup.array().of(
        Yup.object({
            key: Yup.string().required('Required'),
            value: Yup.string().required('Required')
        })
    ).max(3, 'You can add up to 3 claims')
});