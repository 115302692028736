import {useNavigate} from "react-router-dom";
import {buildUrl} from "../../../../../utils/queryBuilder";
import {remoteRoutes} from "../../../../../data/constants";
import {del} from "../../../../../utils/ajax";
import {CloseModal} from "../../../../../_theme/helpers/components/modalHelpers";
import toast from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../../data/toastDefaults";
import {Formik} from "formik";
import {ModalWrapper} from "../../../../components/shared/ModalWrapper";
import {IUnit} from "../../interfaces/IHub";

type Props = {
    id: string
}

export const DeleteUnit = ({id}: Props) => {
    const navigate = useNavigate();
    const handleSubmit = () => {

        const url = buildUrl(remoteRoutes.onboardingService, `/units/${id}`)

        del(url, (response) => {

            CloseModal("delete-unit")
            navigate('/settings')
            toast.success(toastMessages.default.success, overrideToastDefaults)
        }, (error) => {

            console.log(error)

            toast.error(toastMessages.default.fail, overrideToastDefaults)
        }, () => {

        })
    }

    const initialValues: IUnit = {
        id: '',
        name: '',
        displayName: '',
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}>

            {({handleSubmit, isSubmitting}) => {
                return <ModalWrapper
                    id="delete-unit"
                    title="Delete Unit of Measurement"
                    size="md"
                    isDeleteAction={true}
                    submitting={isSubmitting}
                    handleSubmit={handleSubmit}>
                    <p>Are you sure you want to delete this unit of measurement? This action cannot be undone.</p>
                </ModalWrapper>
            }}

        </Formik>
    );
}