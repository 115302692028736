import {
  IButtonProps,
  PageHeader,
} from "../../../components/shared/PageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import XTable, { ITableColumn } from "../../../components/shared/XTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import React, { useCallback, useEffect, useState } from "react";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import {
  HUBS_CONSTANTS,
  IHubState,
} from "../../../../data/redux/settings/hubsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import {
  overrideToastDefaults,
  toastMessages,
} from "../../../../data/toastDefaults";
import { IHub } from "../../settings/interfaces/IHub";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import CreateClientForm from "./modals/CreateClientForm";
import { IBreadcrumb } from "../../../components/shared/Breadcrumbs";
import debounce from "lodash.debounce";

const columns: ITableColumn[] = [
  {
    label: "Client Name",
    id: "name",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "MAU",
    id: "minimumAggregationUnit",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "District",
    id: "district",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "Email",
    id: "email",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  {
    label: "Phone Number",
    id: "phoneNumber",
    link: undefined,
    isNumberFormat: false,
    isDate: false,
    textAlign: "text-start",
  },
  // {
  //     label: 'No. of Farmers',
  //     id: 'numberOfFarmers',
  //     link: undefined,
  //     isNumberFormat: true,
  //     isDate: false,
  //     textAlign: 'text-end'
  // },
  // {
  //     label: 'Village',
  //     id: 'village',
  //     link: undefined,
  //     isNumberFormat: false,
  //     isDate: false,
  //     textAlign: 'text-start'
  // }
];

const Clients = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);

  const { data, loading, pagination }: IHubState = useSelector(
    (state: IState) => state.hubs,
  );
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);

  const [importing, setImporting] = useState<boolean>(false);
  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);

  const [clients, setClients] = useState<IHub[]>([]);

  const handleSearch = (event: any) => {
    if (event.target.value === "") {
      setSearchTerm(undefined);
    }

    if (event.keyCode === 13) {
      setSearchTerm(event.target.value);
    }
  };

  const setLoading = (state: boolean) => {
    dispatch({
      type: HUBS_CONSTANTS.LOADING_DATA,
      payload: state,
    });
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setPage(1);
    }, 800),
    [],
  );

  useEffect(() => {
    setLoading(true);
    handleFetchData(page, pagination?.pageSize, searchTerm);
  }, [page, searchTerm]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setClients(data.filter((hub) => hub.parentHubId === null));
    } else {
      setClients(data);
    }
  }, [data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleFetchData = (
    page: number,
    pageSize: number,
    searchTerm?: string,
  ) => {
    if (remoteRoutes.onboardingService) {
      const hubId = currentUser?.hubId;
      let params: any = { hubId, page, pageSize };

      if (searchTerm) {
        params.searchTerm = searchTerm;
      }

      const url = buildUrl(remoteRoutes.onboardingService, "/hubs", params);
      get(
        url,
        (response: IApiResponse) => {
          const { data, paginationMetadata } = response;

          dispatch({
            type: HUBS_CONSTANTS.FETCH_DATA,
            payload: { data, pagination: paginationMetadata },
          });

          // toast.success(toastMessages.default.success, overrideToastDefaults);
        },
        async (error) => {
          toast.error(toastMessages.default.fail, overrideToastDefaults);
        },
        () => {
          dispatch({ type: HUBS_CONSTANTS.STOP_FETCH });
          setLoading(false);
        },
      );
    }
  };

  const handleSelectAll = (rows: any[]) => {
    setSelected((prevSelected) => {
      if (prevSelected.length === rows.length) {
        return [];
      } else {
        return rows.map((row) => row.id);
      }
    });
  };

  const handleCheckRow = (item: IHub) => {
    setSelected((prevSelected) => {
      const newSelected = prevSelected ? [...prevSelected] : [];

      const index = newSelected.indexOf(item.id);
      if (index > -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(item.id);
      }

      return newSelected;
    });
  };

  const handleClickRow = (item: IHub) => {
    navigate(`/clients/${item.id}`);
  };

  const actionButtons: IButtonProps[] = [
    {
      label: "Add New Client",
      cssClass: "btn btn-primary btn-sm",
      dataTarget: "#new-client",
      dataToggle: "modal",
    },
  ];

  useEffect(() => {
    setBreadcrumbs([{ label: "Clients", url: undefined }]);
  }, []);

  return (
    <>
      <PageHeader breadcrumbs={breadcrumbs} />
      <TableWrapper>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="input-group w-25">
            <input
              type="text"
              // onKeyUp={(event) => handleSearch(event)}
              onChange={handleSearchChange}
              className="form-control"
              placeholder="Type to search..."
            />
            <span className="input-group-text" id="addon-wrapping">
              <FontAwesomeIcon icon={faSearch} />
            </span>
          </div>

          <div className="action-buttons">
            {/* <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                            <span className="ms-2">Filters</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">
                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>
                        </button> */}

            {showActionButtons &&
              actionButtons.map((button, index) => {
                return (
                  <button
                    data-bs-toggle={button.dataToggle}
                    data-bs-target={button.dataTarget}
                    className={`${button.cssClass} ${button.processing ? "disabled" : ""}`}
                    key={index}
                    onClick={button.onClick}
                  >
                    {button.processing ? "Please wait..." : button.label}
                  </button>
                );
              })}
          </div>
        </div>

        <XTable
          selected={selected}
          onSelectAll={(rows) => handleSelectAll(rows)}
          onRowClick={(row) => handleClickRow(row)}
          onCheckRow={(row) => handleCheckRow(row)}
          data={data}
          columns={columns}
          loading={loading}
        />

        <XPagination
          dataLength={data?.length}
          pagination={pagination}
          currentPage={page}
          setPage={(page) => setPage(page)}
        />
      </TableWrapper>

      <CreateClientForm pagination={pagination} />
    </>
  );
};

export default Clients;
