import {AUTH_HUB_KEY, remoteRoutes} from "../../data/constants";
import {get} from "../../utils/ajax";
import toast from "react-hot-toast";
import {overrideToastDefaults} from "../../data/toastDefaults";
import {IHub} from "../modules/settings/interfaces/IHub";
import {Dispatch, SetStateAction} from "react";

const fetchHubDetails = async (hubId: string, setUserHub: Dispatch<SetStateAction<IHub | undefined>>) => {
    try {
        get(`${remoteRoutes.onboardingService}/hubs/${hubId}`, (response) => {
            localStorage.setItem(AUTH_HUB_KEY, JSON.stringify(response))
            setUserHub(response)
        })
    } catch (error) {
        toast.error("Unable to fetch hub details", overrideToastDefaults);
    }
};

export default fetchHubDetails;