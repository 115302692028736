import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIdleTimer} from 'react-idle-timer';
import {handleLogout} from '../../../data/redux/coreActions';
import {authService} from "../../../data/oidc/AuthService";
import {IState} from '../../../data/types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

const IdleTimerWrapper = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector((state: IState) => state.core.user);
    const openModal = useRef<HTMLAnchorElement>(null);

    const initialTimeToLogout = 20; // time in seconds
    const [timeRemaining, setTimeRemaining] = useState(initialTimeToLogout);

    const idleTimeout = 1000 * 60 * 60; // 60 minutes
    const [isIdle, setIdle] = useState(false);

    const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const setTimeRef = useRef<NodeJS.Timeout | null>(null);
    const setToggleRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            if (sessionTimeoutRef.current) clearTimeout(sessionTimeoutRef.current);
            if (setTimeRef.current) clearInterval(setTimeRef.current);
            if (setToggleRef.current) clearInterval(setToggleRef.current);
        };
    }, []);

    const handleContinue = () => {
        if (setToggleRef.current) clearTimeout(setToggleRef.current);
        document.title = 'Symos';
        if (sessionTimeoutRef.current) clearTimeout(sessionTimeoutRef.current);
        setTimeRemaining(initialTimeToLogout);
        if (setTimeRef.current) clearInterval(setTimeRef.current);
    };

    const doLogout = async () => {
        dispatch(handleLogout());
        await authService.logout();
        if (setTimeRef.current) clearInterval(setTimeRef.current);
    };

    const countDown = () => {
        let timeToCountDown = initialTimeToLogout;

        setToggleRef.current = setInterval(() => {
            document.title = document.title === 'Symos' ? 'You are about to be logged out' : 'Symos';
        }, 2000);

        setTimeRef.current = setInterval(async () => {
            timeToCountDown -= 1;
            setTimeRemaining(timeToCountDown);
            if (timeToCountDown === 0) {
                setTimeRemaining(initialTimeToLogout);
                await doLogout();
            }
        }, 1000);
    };

    const onIdle = () => {
        if (userProfile) {
            openModal.current?.click();
            countDown();
        }
    };

    const { reset } = useIdleTimer({
        timeout: idleTimeout,
        onIdle,
        debounce: 500
    });

    return (
        <>
            <a
                ref={openModal}
                href="#"
                className="visually-hidden"
                data-bs-toggle="modal"
                data-bs-target="#idle_logout_modal"
                id="idle_logout_modal_button"
            >
                ..
            </a>
            <div className="modal fade" id="idle_logout_modal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">You are about to be logged out!</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body py-lg-5 px-lg-10 mb-10">
                            <div className="d-flex flex-column flex-lg-row">
                                <div className="flex-lg-row-fluid px-10">
                                    <p>For security reasons, your connection times out after you've been inactive for a while.</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary mt-5" data-bs-dismiss="modal" onClick={handleContinue}>
                                Stay logged in <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IdleTimerWrapper;
