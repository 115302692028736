import TableWrapper from "../../../../components/shared/TableWrapper";
import {TableLoader} from "../../../../components/shared/loaders/TableLoader";
import {Users} from "../../userManagement/Users";

interface IProps {
    hubId?: string;
}

const HubUsers = ({hubId}: IProps) => {
    return (
        <>
            <Users hubId={hubId}/>
        </>
    );
}

export default HubUsers;