/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link, Outlet, Route, Routes} from 'react-router-dom'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'

const ErrorsLayout = () => {
    return (
        <div className='mt-5'>
            <div className="col-12 col-lg-6 col-md-6 offset-3">
                <div className='card border-0 shadow-sm'>
                    <div className='card-body'>
                        <Outlet/>
                        <div className='text-center'>
                            <Link to='/' className='btn btn-lg btn-outline-secondary fw-bolder'>
                                Go to homepage
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ErrorsPage = () => (
    <Routes>
        <Route element={<ErrorsLayout/>}>
            <Route path='404' element={<Error404/>}/>
            <Route path='500' element={<Error500/>}/>
            <Route index element={<Error404/>}/>
        </Route>
    </Routes>
)

export {ErrorsPage}
