import {IFarmerProfile} from "../../../app/modules/profiling/interfaces/IFarmerProfile";
import {IPurchase} from "../../../app/modules/inventory/interfaces/IPurchase";
import {INVENTORY_PURCHASES_CONSTANTS} from "./purchasesReducer";

export const IMPORT_PURCHASES_CONSTANTS = {
    FETCH_PURCHASES: "FETCH_PURCHASES",
    DELETE_PURCHASES: "DELETE_PURCHASES"
}

export interface IImportPurchaseState {
    loading: boolean
    data: IPurchase[]
}

const initialState: IImportPurchaseState = {
    loading: false,
    data: []
}


export default function importPurchasesReducer(state = initialState, action: any) {
    switch (action.type) {

        case IMPORT_PURCHASES_CONSTANTS.FETCH_PURCHASES: {
            return {...state, loading: false, data: action.payload, reset: false}
        }

        case IMPORT_PURCHASES_CONSTANTS.DELETE_PURCHASES: {
            return {...state, data: []}
        }

        default: {
            return state
        }
    }
}
