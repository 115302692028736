import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";

type Props = {
  selectedRoleDetails: any;
};

const RolesAndPermissionsDetails = ({ selectedRoleDetails }: Props) => {
  const handleSubmit = () => {};
  const initialValues = {
    roleName: selectedRoleDetails?.roleName,
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, handleSubmit }) => {
        return (
          <ModalWrapper
            id={"view-role-details"}
            title={"Edit Role"}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  label="Role Name"
                  name={"roleName"}
                  disabled={isSubmitting}
                  as={XTextField}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  label={"Description"}
                  as={XTextField}
                  name={"description"}
                />
              </div>
              <div>
                <h6>Permissions</h6>
              </div>
            </div>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default RolesAndPermissionsDetails;
