import { IFarmerProfile } from "../../../app/modules/profiling/interfaces/IFarmerProfile";
import { IPurchase } from "../../../app/modules/inventory/interfaces/IPurchase";

export const INVENTORY_PURCHASES_CONSTANTS = {
    STOP_FETCH: "STOP_FETCH",
    FETCH_PURCHASES: "FETCH_PURCHASES",
    POST_PURCHASE: "POST_PURCHASE",
    LOADING_PURCHASES: "LOADING_PURCHASES",
    GET_PURCHASE: "GET_PURCHASE",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    FILTER_DATA: "FILTER_DATA",
    RESET_FILTER: "RESET_FILTER",
    PAGINATION: "PAGINATION",
    DELETE_PURCHASE: "DELETE_PURCHASE",
}

export interface IPurchaseState {
    loading: boolean
    data: IPurchase[]
    filter: {
        show: boolean,
        data: IPurchase[],
        range: {
            startDate: string,
            endDate: string
        }
        key: string,
        pagination: {
            itemsPerPage: number,
            activePage: number,
            isLastPage: boolean
        }
    }
    sorting: {
        order: string
        column: string
    }
    reset: boolean
}

const initialState: IPurchaseState = {
    loading: false,
    data: [],
    filter: {
        key: '',
        show: false,
        data: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function purchasesReducer(state = initialState, action: any) {
    switch (action.type) {

        case INVENTORY_PURCHASES_CONSTANTS.FILTER_DATA:
            return { ...state, ...action.payload, show: !state.filter.show }

        case INVENTORY_PURCHASES_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return { ...initialState, reset: true }

        case INVENTORY_PURCHASES_CONSTANTS.SET_SORT_PARAMS:
            return { ...state, ...action.payload }

        case INVENTORY_PURCHASES_CONSTANTS.PAGINATION:
            return { ...state, ...action.payload }

        case INVENTORY_PURCHASES_CONSTANTS.FETCH_PURCHASES: {
            return { ...state, loading: false, data: action.payload, reset: false }
        }

        case INVENTORY_PURCHASES_CONSTANTS.POST_PURCHASE: {
            const profile: IFarmerProfile = action.payload
            return { ...state, data: [profile, ...state.data] }
        }

        case INVENTORY_PURCHASES_CONSTANTS.LOADING_PURCHASES: {
            return { ...state, loading: action.payload }
        }

        case INVENTORY_PURCHASES_CONSTANTS.GET_PURCHASE: {
            return { ...state, requestDetails: action.payload }
        }

        case INVENTORY_PURCHASES_CONSTANTS.STOP_FETCH: {
            return { ...state, loading: false }
        }

        case INVENTORY_PURCHASES_CONSTANTS.DELETE_PURCHASE: {
            return {
                ...state,
                data: state.data.filter(purchase => !action.payload.purchaseIds.includes(purchase.id))
            };
        }

        default: {
            return state
        }
    }
}
