import { XSidebarWrapper } from "../../../components/shared/XSidebarWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { IHubProduct, IProduct } from "../interfaces/IHub";
import { IHeaderButtonProps } from "../../../components/shared/SettingsPageHeader";
import { getRandomColor } from "../../../hooks/colorRandomizer";

type Props = {
    close: () => void,
    show: boolean,
    product: IProduct
}

const HubProductDetailsPage = ({ close, show, product }: Props) => {

    return (
        <>
            <XSidebarWrapper title={"Product Details"} width={35} close={close} show={show}>
                <div className="d-flex gap-3 w-100 align-items-center border-bottom mb-3">
                    <div className="py-3 px-4 rounded-1 bg-primary">
                        <h2>{product?.name.substring(0, 2).toUpperCase()}</h2>
                    </div>
                    <div className="py-3 w-100">
                        <h5>{product?.name}</h5>
                        <div className="d-flex justify-content-between w-100">
                            <div className="fs-6 small">
                                <span className="badge text-bg-danger">
                                    {product?.category}
                                </span>
                            </div>

                        </div>

                    </div>

                </div>
                <div className="p-3 bg-gray rounded-3 mb-3">
                    <div className="row">
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Varieties</strong><br />
                            {product?.varieties && product.varieties?.length > 0 ? (
                                product?.varieties.map((v: { id: string; name: string }, index: number) => (
                                    <span key={v.id} className={`badge ${getRandomColor()} me-2`}>
                                        {v.name}
                                    </span>
                                ))
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                        <div className="col-lg-12 col-12 mb-4">
                            <strong className="small fw-semibold">Units</strong><br />
                            <span>{(product.units?.length ? product.units.map(p => p.name).join(', ') : '-')}</span>
                        </div>
                    </div>
                </div>

            </XSidebarWrapper>
        </>
    );
}

export default HubProductDetailsPage;