import {PageHeaderLoader} from "./PageHeaderLoader";
import {Skeleton} from "@mui/material";
import React from "react";

const HubDetailsLoader = () => {
    return (
        <>
            <PageHeaderLoader/>
            <div className="card shadow-sm border-0 mb-3">
                <div className="pt-3 px-3">
                    <div className="d-flex justify-content-between w-100 align-items-center mb-3">
                        <div className="d-flex justify-content-start w-50 align-items-center">
                            <Skeleton width={60} height={60} className="rounded-2" variant={"rectangular"}/>
                            <div className="px-3">
                                <Skeleton width={200} height={30} variant={"text"}/>
                                <Skeleton width={150} height={15} variant={"text"}/>
                                <div className="d-flex justify-content-end w-50 align-items-center">
                                    <Skeleton width={170} height={20} variant={"text"}/>
                                    <Skeleton width={170} height={20} variant={"text"}/>
                                </div>
                            </div>
                        </div>
                        {/*<div className="small me-5">*/}
                        {/*    <Skeleton width={100} height={20} variant={"text"}/>*/}
                        {/*    <Skeleton width={120} height={20} variant={"text"}/>*/}
                        {/*</div>*/}
                    </div>
                    <div className="d-flex justify-content-start w-100">
                        {
                            Array.from({length: 6}).map((_, index) => {
                                return <Skeleton key={index} className="me-4" width={100} height={20} variant={"text"}/>
                            })
                        }
                    </div>
                    <div></div>
                </div>

            </div>

            <div className="card shadow-sm border-0 py-3 px-3">
                <div className="row">
                    {
                        Array.from({length: 7}).map((_, index) => {
                            return (
                                <div className="col-lg-4 mb-4" key={index}>
                                    <Skeleton width={"50%"} height={15} variant={"text"}/>
                                    <Skeleton width={"65%"} height={20} variant={"text"}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default HubDetailsLoader;