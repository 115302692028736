import React from "react";
import {IContactPerson, IHub, IProduct} from "../../../settings/interfaces/IHub";
import {ContactPeople} from "./_contactPeople";

type Props = {
    hub?: IHub
}

const HubDetails = ({hub}: Props) => {

    const contactPersons: IContactPerson[] = hub?.contactPersons || [];
    const products: IProduct[] = hub?.products || [];

    const contactPerson = contactPersons?.length
        ? contactPersons[0] : null;

    return (
        <>
            <div className="card shadow-sm border-0 py-3 px-3">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title">General</div>
                        <hr/>
                    </div>

                    <div className="col-lg-4 mb-4">
                        <strong className="small">Phone Number</strong><br/>
                        <span>
                                {hub?.phoneNumber
                                    ? (hub.phoneNumber.startsWith('+256')
                                        ? hub.phoneNumber
                                        : `+256 ${hub.phoneNumber}`)
                                    : '-'
                                }
                            </span>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <strong className="small">Email</strong><br/>
                        <span>{hub?.email || '-'}</span>
                    </div>

                    <div className="col-lg-4 mb-4">
                        <strong className="small">MAU</strong><br/>
                        <span>{hub?.minimumAggregationUnit || '-'}</span>
                    </div>

                    <div className="col-lg-4 mb-4">
                        <strong className="small">District</strong><br/>
                        <span>{hub?.district || '-'}</span>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <strong className="small">Sub-county</strong><br/>
                        <span>{hub?.subCounty || '-'}</span>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <strong className="small">Village</strong><br/>
                        <span>{hub?.village || '-'}</span>
                    </div>
                </div>

                {contactPersons && contactPersons.length > 0 &&
                    <div className="row">
                        <div className="col-12">
                            <ContactPeople contactPersons={contactPersons}/>
                        </div>
                    </div>
                }

            </div>

        </>
    );
}

export default HubDetails;