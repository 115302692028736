import { Outlet, Route, Routes } from "react-router-dom";
import { Settings } from "./Settings";
import RolesAndPermissionsDetails from "./permission/modals/RolesAndPermissionsDetails";

const SettingsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<Settings />} />
      </Route>
    </Routes>
  );
};

export default SettingsPage;
