import TableWrapper from "../../../../components/shared/TableWrapper";
import {TableFilterLoader} from "../../../../components/shared/loaders/TableFilterLoader";
import {TableLoader} from "../../../../components/shared/loaders/TableLoader";
import React from "react";
import {HubProcessesPage} from "../../../settings/processes/HubProcessesPage";

interface IProps {
    hubId?: string;
}

const HubProcesses = ({hubId}: IProps) => {
    return (
        <>
            <HubProcessesPage hubId={hubId}/>
        </>
    );
}

export default HubProcesses;