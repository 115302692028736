import * as React from 'react';
import {
    Box,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Toolbar,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faSignOut} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState, useMemo} from "react";
import {IMenuItem, MenuItems} from "../../../utils/MainMenu";
import {useAuth} from "../../../app/modules/auth";
import {IsAgent, IsGlobalAdmin, IsHubAdmin} from '../../../app/hooks/roleChecker';
import {authService} from "../../../data/oidc/AuthService";
import {IHub} from "../../../app/modules/settings/interfaces/IHub";
import {MainMenuLoader} from "../../../app/components/shared/loaders/MainMenuLoader";

export const drawerWidth = 200;

export const MainMenuDrawer: React.FC = () => {

    const {logout, userRoles} = useAuth();
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate();
    const location = useLocation();
    const activePage = location.pathname.toLowerCase();
    const hub: IHub | any = authService.getHub();
    const mau: string = hub.minimumAggregationUnit;

    const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const {refreshHubDetails} = useAuth();

    const prepareMenu = () => {
        let globalAdminMenuItems = ['Dashboard', 'Clients', 'Settings'];
        let hubAdminMenuItems = ['Dashboard', 'Hubs', 'Farmers', 'Inputs', 'Inventory', 'Trainings', 'Orders', 'Cost Tracking', 'Settings', 'Users'];
        let agentMenuItems = ['Farmers', 'Inputs', 'Inventory'];

        const isGlobalAdmin = IsGlobalAdmin(userRoles);
        const isHubAdmin = IsHubAdmin(userRoles);
        const isAgent = IsAgent(userRoles);

        if (mau) {
            hubAdminMenuItems = hubAdminMenuItems.map(item => item === 'Hubs' ? mau : item)
        }

        const menu = MenuItems({activePage, mau})

        const filteredItems = menu.filter((item) => {
            if (isGlobalAdmin && globalAdminMenuItems.includes(item.label)) {
                return true;
            }
            if (isHubAdmin && hubAdminMenuItems.includes(item.label)) {
                return true;
            }
            if (isAgent && agentMenuItems.includes(item.label)) {
                return true;
            }
            return !isGlobalAdmin && !isHubAdmin && !isAgent && item.label === 'Dashboard';
        });

        setMenuItems(filteredItems);
        setLoading(false)
    }

    useEffect(() => {
        prepareMenu()
    }, [activePage]);

    useEffect(() => {
        refreshHubDetails();
    }, [mau]);


    const handleItemClick = (item: IMenuItem) => {
        if (item.subMenu) setOpenSubMenu(!openSubMenu);
        else if (item.path) navigate(item.path);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <div className="bg-black">
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            backgroundColor: '#1C5838',
                            color: '#FFFFFF',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <Toolbar className="justify-content-center">
                        <img src="/symos_logo_transparent.png" className="text-center mt-4" alt="Logo" height={80}/>
                    </Toolbar>

                    {loading && <MainMenuLoader/>}
                    {!loading && <>
                        <List className="mt-3" disablePadding>
                            {menuItems.map((item, index) => (
                                <ListItem
                                    onClick={() => handleItemClick(item)}
                                    className={item.isActive ? 'nav-item-active' : ''}
                                    key={index}
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemIcon
                                            sx={{
                                                minWidth: '35px',
                                            }}
                                            className="text-white main-menu"
                                        >
                                            {item.icon && <FontAwesomeIcon icon={item.icon}/>}
                                        </ListItemIcon>
                                        <ListItemText primary={item.label}/>
                                        {item.subMenu && (
                                            <ListItemSecondaryAction>
                                                <FontAwesomeIcon icon={faChevronRight}/>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>

                        <Divider className="mt-auto"/>
                        <List>
                            <ListItem onClick={logout} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '35px',
                                            color: '#FFFFFF',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faSignOut}/>
                                    </ListItemIcon>
                                    <ListItemText primary={"Sign out"}/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </>}
                </Drawer>
            </div>
        </Box>
    );
};
